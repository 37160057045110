<template>
    <div>
        <div class=''>
            <h4>{{this.content.text}}</h4>
        </div>
        <div class='row container-fluid mt-1 p-0 g-3'>
            <Input :label="$t('components.service.modal.Rename.1')" id='name' :dataValue='this.content.name' @updateParentState="this.updateState" hint=''/>
        </div>
        <div class='d-flex mx-auto justify-content-center pt-3'>
            <Button :name="$t('components.service.modal.Rename.2')" class='mr-1' type='btn-outline-warning' v-on:click='this.close'/>
            <Button :name="$t('components.service.modal.Rename.3')" type='btn-warning' v-on:click='this.submit'/>
        </div>
        
    </div>
</template>

<script>
import Button from '@/components/service/Button';
import Input from '@/components/service/Input';

export default {
    components: {
        Button,
        Input
    },
    props: {
        close: [Function],
    },
    data() {
        return {
            content: this.$store.state.modal.content,
            data: {
                name: this.$store.state.modal.content.name,
            }
        }
    },
    methods: {
        updateState(state) {
            this.data[state.id] = state.value;
        },
        submit() {
            let callback = this.content.callback;
            this.$store.commit('MODAL', {
                        action: false,
                        type: false,
                        content: false
                    })
            this.$store.commit('DARK_WRAPPER', false);
            callback(this.content.keyt, {name: this.data.name});
        }
    },
}
</script>