import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "@/includes/_icons.scss";
import "primevue/resources/themes/aura-light-green/theme.css";
import { createApp } from "vue";
import App from "./App.vue";
import router from "@/router";
import store from "@/store";
import axios from "axios";
import VueAxios from "vue-axios";
import VueCookies from "vue3-cookies";
import lib from "@/includes/lib.js";
import i18n from "./i18n";
import { create, all } from "mathjs";
import PrimeVue from "primevue/config";


axios.interceptors.response.use(
  function (res) {
    lib.res(res, store, router);
    return res;
  },
  function (error) {
    lib.err(error, store, router);
    // return Promise.reject(error);
  }
);

axios.interceptors.request.use(
  function (req) {
    lib.req(req, store, router);
    return req;
  },
  function (error) {
    return Promise.reject(error);
  }
);

router.beforeEach((to, from, next) => {
  if (to.href == "/logout") {
    store.dispatch("logout", router);
  } else {
    next();
  }
});

const math = create(all, {});

const Vue = createApp(App)
  .use(i18n)
  .use(store)
  .use(router)
  .use(VueAxios, axios)
  .use(VueCookies)
  .use(PrimeVue)

Vue.config.globalProperties.$math = math;
Vue.config.globalProperties.$lib = lib;
// console.log(Vue);
Vue.mount("#app");

import "bootstrap/dist/js/bootstrap.js";
import "./registerServiceWorker";
