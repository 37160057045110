<template>
    <div>
        <div class="d-flex align-items-center justify-content-between">
            <h4 class='m-0'>{{this.content.title}}</h4>
            <i class='big href item-i x_red' v-on:click='this.close'></i>
        </div>
        <div class="row container-fluid mt-1 p-0 g-3 align-items-center input">
            <div class="col-sm-3 input-wrapper p-0">
                <InputSimple :id="'member'" :invalid='this.warn' :invalidHint="$t('components.service.modal.MembersModal.1')" :placeholder="$t('components.service.modal.MembersModal.4')" @updateParentState="this.searchString"/>
            </div>
            <div class="col-sm-7 input-wrapper p-0">
                <span class='member-tags ml-2' v-for="(tag, index) in this.content.hashtags" v-on:click='(e) => {this.selectTag(e.target, tag)}' :key='index'>{{tag}}</span>
            </div>
        </div>
        <div class='row container-fluid mt-1 p-0 g-3 table-responsive'>
            <table class="table table-borderless neue-roman">
                <tbody class=''>
                    <tr v-for="row in this.members" :key="row.keyt">
                        <td class="align-middle member_avatar" scope="col">
                            <img v-if='row.avatar' :src="'data:;base64,' + row.avatar" />
                            <img v-else src="@/assets/user.svg"/>
                        </td>
                        <td class="align-middle neue" scope="col">{{row.name}}</td>
                        <td class="align-middle" scope="col">{{row.hashtags}}</td>
                        <td class="align-middle neue" scope="col">{{row.uid}}</td>
                        <td class="align-middle" scope="col">
                            <Button :name="$t('components.service.modal.MembersModal.2')" type='btn-outline-warning btn-sm' v-on:click='this.select(row)'/>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="row container-fluid mt-1 p-0 g-3 align-items-center justify-content-center">
            <div class="col-sm-2 p-0">
                <Button :name="$t('components.service.modal.MembersModal.3')" type='btn-outline-warning' v-on:click='this.close' />
            </div>
        </div>
    </div>
</template>

<script>
import Button from '@/components/service/Button';
import InputSimple from '@/components/service/InputSimple';
import Lib from '@/includes/lib.js';

export default {
    components: {
        Button,
        InputSimple,
    },
    props: {
        close: [Function],
    },
    data () {
        return {
            content: this.$store.state.modal.content,
            members: this.$store.state.modal.content.members,
            member: false,
            warn: false,
            tags: [],
            tags_s: []
        }
    },
    methods: {
        parseUser(val) {
	        let text = val.replace(/\s+/g, "");
	
	        if ( /^[-._a-z0-9]+@(?:[a-z0-9][-a-z0-9]+\.)+[a-z]{2,6}$/i.test(text) ) {
		        return "email";
	        } else if ( /^\+?\d{10,14}$/.test(text) ) { // с плюсом однозначно номер телефона
		        return "mobilenumber";
	        } else if ( /^\d{20}$/.test(text) ) {
		        return "keyt";
	        } else if ( /^\d{13,15}$/.test(text) ) {
		        return "id";
	        } else if ( /^\d{16}$/.test(text) ) {
		        return "card_number";
	        } else {
		        return "";
	        }
        },
        selectTag(target, tag) {
            target.classList.toggle('selected');
            if (Lib.inArray(tag, this.tags_s)) {
                this.tags_s.splice(this.tags_s.indexOf(tag), 1);
            } else {
                this.tags_s.push(tag);
            }

            if (this.tags_s.length < 1) {
                this.members = this.$store.state.modal.content.members
            } else {
                console.log(this.members)
                this.members = this.$store.state.modal.content.members.filter(val => {
                    let r = true;
                    this.tags_s.forEach(e => {
                        if (!val.hashtags.includes(e)) {
                            r = false;
                        }
                    });
                    return r;
                })
            }
        },
        searchString(arg) {
            this.member = arg.value;
            if (this.parseUser(arg.value) != '') {
                this.searchMember(arg, 1000, false)
            } else {
                this.warn = false;
            }
        },
        searchMember(arg, t, modal) {
            if (this.timeout) {
                window.clearTimeout(this.timeout);
            }
            this.warn  = false;
            this.timeout = setTimeout(() => {
                // this.member = false;
                this.loader = true;
                this.axios.post('SearchMember', {no_loader: true, data: {info: arg.value}}).then(res => {
                    this.loader = false;
                    if (res.data.body.result) {
                        this.warn = true;
                        // this.select(false);
                        return;
                    } else {
                        this.warn = false;
                    }
                    if (modal) {
                        this.toModal(res.data.body);
                    } else {
                        this.select(res.data.body[1][0], this.member);
                    }
                });
            }, t)
        },
        fromContacts() {
            this.axios.post('GetlistFromContacts').then(res => {
                this.members = res.data.body;
            })
        },
        select(row) {
            this.content.select(row, this.member);
            this.close();
        }
    },
}
</script>

<style lang="scss">
.member_avatar img {
    width: 60px;
    border-radius: 50%;
}
.member-tags {
    box-shadow: 1px 2px 8px 2px rgba(204, 204, 204, 0.5);
    border-radius: 4px;
    padding: 0.2rem 0.4rem;
    cursor: pointer;
    color: #247BA0;
}
.selected {
    color: white;
    background: #247BA0;        
}
.active-tags {
    background-color: #247BA0;
    color: #fff;
}
</style>