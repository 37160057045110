<template>
  <div class='main-title container-xl container-fluid pb-5'>
    <div class='d-flex p-0 g-3 align-items-top justify-content-between flex-column flex-lg-row'>
      <div class="wh-37 col-md-12">
        <div class='d-flex p-0 g-3 align-items-end mb1r'>
          <h4 class='mb-0 neue-roman'>{{ $t('views.Home.1') }}</h4>
          <span v-if="!this.crypto" class='href pl-2 fs_09'>{{ $t('views.Home.2') }}</span>
        </div>
        <div class='height_fix content p-3 d-flex flex-column justify-content-between' v-if="this.$store.state.userData.passport_serial">
          <div v-if="!this.crypto" class='d-flex p-0 g-3 align-items-center justify-content-between'>
            <span class='col-5 light fs_08'>{{ $t('views.Home.1') }}</span> <span class='fs_09 neue-roman href d-flex flex-row align-items-center pl-2 col-7 text-start'>{{this.$store.state.userData.passport_serial[0]}}<i class='item-i h20 ext-link'></i></span>  
          </div>
          <div class='d-flex p-0 g-3 align-items-center justify-content-between'>
            <span class='col-5 light fs_08'>{{ $t('views.Home.3') }}</span> <span class='fs_09 neue d-flex flex-row align-items-center pl-2 col-7 text-start pointer cli_2' data-clipboard-target="#cli_h1">
              {{this.$store.state.userData.id}}
              <i class='item-i h20 copy'></i>
              <input type='hidden' id='cli_h1' :value="this.$store.state.userData.id"/>
            </span>  
          </div>
          <div class='d-flex p-0 g-3 align-items-center justify-content-between'>
            <span class='col-5 light fs_08'>{{ $t('views.Home.4') }}</span> <span class='fs_09 neue-roman pl-2 col-7 text-start'>{{this.$store.state.userData.pass.date_reg}}</span>  
          </div>
          <div v-if="this.$store.state.userData.last_au.last_autorization_date" class='d-flex p-0 g-3 align-items-center justify-content-between'>
            <span class='col-5 light fs_08'>{{ $t('views.Home.5') }} </span> 
              <span class='fs_09 neue-roman pl-2 col-7 text-start'>{{this.$store.state.userData.last_au.last_autorization_date}} <br>
                <div class="d-flex flex-row align-items-start">
                  <span class="mr-1">
                    {{ $t('views.Home.6') }}
                  </span>
                  <span class="break-all">
                    {{this.$store.state.userData.last_au.last_autorization_ip}}
                  </span>
                </div>
              </span>
          </div>
        </div>
      </div>
      <div v-if="this.keyts" class="wh-60 col-md-12">
        <div class='d-flex p-0 g-3 align-items-center justify-content-between'>
          <div class='d-flex p-0 g-3 align-items-end flex-row justify-content-between mb1r'>
            <h4 class='mb-0 neue-roman' v-if='this.k_name'>{{ $t('views.Home.7') }}</h4>
            <h4 class='mb-0 neue-roman' v-else>{{ $t('views.Home.8') }}</h4>
            <router-link to='/keyts/list'><span class='href pl-2 fs_09'>{{ $t('views.Home.9') }} <span v-if="this.keyts_length">({{this.keyts_length}})</span></span></router-link>
          </div>
          <router-link to='/keyts/new'><span class='href pl-2 fs_09 d-flex flex-row align-items-center'>{{ $t('views.Home.23') }}<i class='item-i plus-blue'></i></span></router-link>
        </div>
        <div class='content pf-05 p-3 table-responsive'>
          <table v-if='this.keyts.length > 0' class="table m-0 neue-roman">
            <tbody class='k_table'>
                <tr v-for="row in this.keyts" :key="row.keyt">
                    <td class="align-middle" scope="col">
                      <div v-if="row.image" class="item-i contain" :style="`background-image: url('${row.image}');`">
                      </div>
                      <div v-else class="simp-bg">
                        <span>
                          {{row.symbol}}
                        </span>
                      </div> 
                    </td>
                    <td class="align-middle" scope="col">
                      <div class='href neue-roman d-flex flex-row'>
                        <span v-if='this.k_name && row.alias' v-on:click='this.$router.push({name: "Transfer_id", query: {keyt: row.keyt}})' :title='row.alias'>{{this.$lib.getTokenString(row.alias, 7)}}</span>
                        <span v-else v-on:click='this.$router.push({name: "Transfer_id",  query: {keyt: row.keyt}})'>{{row.keyt}}</span>
                        <i class='item-i copy c18 cli_2 ml-2' :data-clipboard-target="'#_' + row.keyt"></i>
                        <a v-if="row.explorer_link" :href="row.explorer_link" target="_blank"><i class='item-i c18 ml-2 ext-link'></i></a>
                        <input type='hidden' :id='"_" + row.keyt' :value="this.k_name && row.alias ? row.alias : row.keyt"/>
                      </div>
                      <span class='grey light fs_09' :title='row.name'>{{this.sbstring(row.name)}}</span></td>
                    <td class="align-middle" scope="col"><span class='neue-roman fs_13' v-html='this.sumMask(row.summ)'></span> <span class='grey fs_08'>{{row.curr}}</span></td>
                    <td class='align-middle' scope="col">
                      <div class='dep_3'>
                        <Button :name="$t('views.Home.10')" v-on:click='this.PayIn(row.keyt)' :class="'fs_08'" type='btn-warning'/> 
                        <Button :name="$t('views.Home.11')" v-on:click='this.$router.push({name: "Pay"})' :class='"ml-3 fs_08"' type='btn-outline-warning'/>
                      </div>
                    </td>
                </tr>
            </tbody>
          </table> 
          <span v-else>
            <!-- <span v-if="this.k_name">{{ $t('views.Home.22') }}</span>
            <span v-else>{{ $t('views.Home.12') }}</span> -->
            <div class='col-12 d-flex justify-content-start'>
              <Button :name="this.k_name ? $t('views.Keyts.List.10') : $t('views.Keyts.List.11')" v-on:click='this.$router.push("/keyts/new")' type='btn-warning'/>
            </div>
          </span>
        </div>
      </div>
    </div>
    <div class='individual_dep1' v-if="this.ready">
      <div :class="{'sub_dep_1': this.news.length > 0}">
        <div class="col-md-12" v-if="this.$store.state.userData.last_au && this.$store.state.userData.last_au.paypassword_need != 0">
          <div class='d-flex p-0 g-3 align-items-end mb1r'>
            <h4 class='mb-0 neue-roman'>{{ $t('views.Home.13') }}</h4>
          </div>
          <div class='content p-4 d-flex flex-column align-items-start justify-content-start' >
            <span class="neue color-warning">{{ $t('views.Home.14') }}</span>
            <Button :name="this.$t('views.Home.24')" class="mt-2" v-on:click='this.$router.push("/safety/pay_pass")' type='btn-warning'/> 
          </div>
        </div>
        <div class="col-md-12" v-if="this.templates.length > 0">
          <div class='d-flex p-0 g-3 align-items-end mb1r'>
            <h4 class='mb-0 neue-roman'>{{ $t('views.Home.15') }}</h4>
            <span class='pl-2 fs_09'><router-link class='href' to='/templates?stage=2'>{{ $t('views.Home.16') }}</router-link> <span v-if="this.templates_length > this.templates.length">({{this.templates_length}})</span></span>
          </div>
          <div class='d-flex flex-wrap'>
            <div v-for="row in this.templates" :key="row.num" class='content pointer p-4 w30 d-flex flex-row align-items-center justify-content-start'
            v-on:click="this.payTemplate(row)" >
              <i v-if="this.crypto" class='item-i contain c24 mr-1 bookmarkCrypto'></i>
              <i v-else class='item-i contain c24 mr-1 bookmark'></i>
              <div class='d-flex flex-column align-items-start justify-content-between'>
                <span class="neue">{{row.template_name}}</span>
                <span class='grey'>{{ $t('views.Home.17') }} {{row.sum}} {{row.curr_text}}</span>
              </div>
            </div>
          </div>
        </div>
        <div v-if='this.pays.length > 0' class="col-md-12">
          <div class='d-flex p-0 g-3 flex-wrap align-items-end mb1r'>
            <h4 class='mb-0 neue-roman'>{{ $t('views.Home.18') }}</h4>
            <span class='pl-2 fs_09'><router-link to='/pay' class='href'>{{ $t('views.Home.16') }}</router-link></span>
          </div>
          <div class='d-flex flex-wrap content p-4'>
            <div v-for="row in this.pays" :key="row.code" class='pointer w30 pl-0 br-0 d-flex p-2 flex-row align-items-center justify-content-start'
            v-on:click="this.payOperator(row)" >
              <img class='mr-1' :src="require(`@/assets/operator_groups/small/${Number(row.grp) >= 1 && Number(row.grp) <= 21 ? row.grp : 'unknown'}.svg`)">
              <span class="neue-roman">{{row.name}}</span>
            </div>
          </div>
        </div>
      </div>
      <div v-if="this.news.length > 0" class='sub_dep_2'>
        <div class='mb1r'>
          <h4 class='mb-0 neue-roman'>{{ $t('views.Home.19') }}</h4>
        </div>
        <div class='d-flex p-0 g-3 flex-column align-items-center justify-content-between'>
          <div v-for="(row, index) in this.news" :key="index" class='content p-4 col-12'>
            <div class='d-flex p-0 g-3 flex-row align-items-center justify-content-between'>
              <h5 class='neue' :class="{'color-newsg': row.priority == '1', 'color-warning': row.priority == '2'}">{{row.title}}</h5>
              <h5 class='light fs_08'>{{row.date_create}}</h5>
            </div>
            <p v-html="'<p>'+row.msg+'</p>'"></p>
            <p v-if='row.show_m' v-html="'<p>'+row.msg_more+'</p>'"></p>
            <p v-if='row.msg_more' v-on:click='this.show_m(index)' class='color-acent pointer mb-0'>
              <span v-if='row.show_m'>{{ $t('views.Home.20') }}</span>
              <span v-else>{{ $t('views.Home.21') }}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Title from '@/components/service/Title';
import Button from '../components/service/Button.vue';
import ClipboardJS from 'clipboard';
import Icon from '@/components/icons/Icon.vue';




export default {
  components: {
    Title,
    Button,
    Icon
  },
  data() {
    return {
      str: 'test',
      str2: 'arrow',
      str3: 'key',
      crypto: false,
      templates: [],
      pays: [],
      news: [],
      ready: false,
      keyts: false,
      keyts_length: false,
      templates_length: false,
      k_name: this.$store.state.config["KEYT_ALTERNAME_TOKEN"],
    }
  },
  created() {
    this.$store.commit('TITLE', 'HomePage');

    console.log('store this.$store.state home page', this.$store.state);
    console.log('this.$store.atmIncassRest home page', this.$store.state.atmIncassRest);
  },
  mounted() {
    // this.keyts = this.getKeyts();
    // console.log(this.keyts)
    this.crypto = process.env.VUE_APP_INDIVIDUAL == 'Crypto' ? true : false
    this.getTemplates();
    this.axios.post('Init').then(res => {
      this.pays = res.data.body[0] || [];
      this.news = res.data.body[1] || [];
      this.ready = true;
      new ClipboardJS('.clip_h');
      new ClipboardJS('.cli_2');
    });
  },
  computed: {
        t () {
            let k = this.$store.getters.get_sort_keyts;
            if (k.length > 0) {
                this.getKeyts(k);
            }
            return k;
        }
  },
  watch: {
        t: {
            handler: function(newData, oldData) {
                // console.log(newData)
                this.getKeyts(newData);
            },
            deep: true,
        }
  },
  methods: {
    loki() {
      this.axios.post('dev_loki');
    },
    payTemplate(arg) {
      if (arg.type == 'pay') {
        this.$router.push({name: 'Pay', query: {
                  template: arg.num
                }})
      }
      if (arg.type == 'tran') {
        this.$router.push({name: 'Transfer_id', query: {
                  template: arg.num,
                }})
      }
      if (arg.type == 'payin') {
        this.$router.push({name: 'Payin', query: {
                  template: arg.num,
                }})
      }
    },
    PayIn(arg) {
      let params = {
        keyt: arg,
      };
      this.$router.push({name: 'Payin', query: params})
    },
    payOperator(arg) {
      this.$router.push({name: 'Pay', query: {
                   operator: arg.code,
                   name: arg.name
                }})
    },
    getKeyts(k) {
        let arr = k;
        if (arr.length > 2) {
          this.keyts_length = arr.length;
          arr.splice(2, arr.length);
        }
        this.keyts = arr;
    },
    getTemplates() {
      if (this.$store.state.userData.templates) {
        let cat = this.$store.state.userData.templates.catalog.slice(0);
        cat.forEach(e => {
          e.type = 'pay';
        });
        let tran = this.$store.state.userData.templates.transfer.slice(0);
        tran.forEach(e => {
          e.type = 'tran';
        });
        let payin = this.$store.state.userData.templates.payin.slice(0);
        payin.forEach(e => {
          e.type = 'payin';
        });
        this.templates_length = cat.length + tran.length + payin.length;
        if (cat.length > 2) {
          cat.splice(2, cat.length)
        }
        if (tran.length > 2) {
          tran.splice(2, tran.length)
        }
        if (payin.length > 2) {
          payin.splice(2, payin.length)
        }
        this.templates = cat.concat(tran, payin);
        // console.log(this.templates);
      } else {
        setTimeout(() => {
          this.getTemplates();
        }, 2000);
      }
    },
    sbstring(str) {
      if (str.length > 21) {
        return str.substring(0, 21) + '...';
      } else {
        return str;
      }
    },
    sumMask(sum) {
      let s = sum.replace(/(\d)(?=(\d\d\d)+\b)/ig, '$1  ');
      let arr = s.split('.');
      if (arr[1]) {
        return `<span>${arr[0]}</span><span class='light fs_09'>.${arr[1]}</span>`
      } else {
        return `<span>${arr[0]}</span>`
      }
    },
    show_m(index) {
      this.news[index].show_m = !this.news[index].show_m;
    }
  },
}
</script>
<style lang="scss">
.w30 {
    margin-right: 5%;
    @media (min-width: 661px) {
        width: 30% !important;
    }
    @media (max-width: 660px) {
        margin-right: 10%;
        width: 45% !important;
    }
    @media (max-width: 480px) {
        width: 100% !important;
    }
}
.individual_dep1 {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
}
.pf-05 {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}
.mb1r {
  margin-bottom: 1rem;
}
.h20 {
  height: 27px !important;
}
.k_table {
  .dep_3 {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-end;
  }
  tr{ 
    td {
      padding: 0.9rem 0.5rem;
    }
    td:nth-child(1) {
      padding-left: 0 !important;
      div {
        padding: 0.2rem;
        border-radius: 50%;
        color: white;
        font-size: 1.3rem;
        height: 39px;
        width: 39px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        span {
          height: 30px;
        }
      }
      .simp-bg {
        background-color: $color-href;
        border: 1px solid $color-href;
      }
    }
    td:nth-child(2) {
      line-height: 1.1rem;
      padding-top: 1rem !important;
      padding-bottom: 0.8rem !important;
    }
    td:nth-child(3) {
      text-align: right;
      white-space: nowrap;
    }
    td:last-child {
      padding-right: 0 !important;
    }
  }
  tr:last-child {
    border: none;
    td {
      border: none;
    }
  }
}
.height_fix {
  min-height: 165.78px;
}
  .individual_dep1 {
    @media (min-width: 992px) {
        flex-direction: row-reverse;
        justify-content: space-between;
    }
    .sub_dep_1 {
      @media (min-width: 992px) {
        width: 27%;
        .w30 {
          margin-right: 0 !important;
          width: 100% !important;
        }
      }
    }
    .sub_dep_2 {
      @media (min-width: 992px) {
        width: 70%;
      }
    }
  }  

@media (min-width: 661px) {
    .w30:nth-child(3n) {
        margin-right: 0 !important;
    }
}
@media (max-width: 660px) {
    .w30:nth-child(2n) {
        margin-right: 0 !important;
    }
}
@media (max-width: 480px) {
    .w30 {
        margin-right: 0 !important;
    }
}
.wh-37 {
  @media (min-width: 992px) {
      width: 32%;
  }
}
.wh-60 {
  @media (min-width: 992px) {
      width: 65%;
  }
}
</style>