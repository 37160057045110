<template>
    <div v-if="this.buttons" class="btn-group col-12 col-md-6 mb-3" role="group" aria-label="">
                <Button v-for="s in stages" :key="s.stage" 
                :name="s.name" 
                 v-on:click="this.updateStage(s.stage)"
                :type='s.stage == this.stage ? "btn-success" : "btn-outline-success"'/>
                
    </div>
    <div v-else class='stage pt-3' :class="{'w-padding': this.w == true}">
        <div class='stage-wrapper'>
            <div v-for="s in stages" :key="s.stage" class="stage-wrapper-single" v-on:click='() => {this.updateStage(s.stage)}' v-bind:class="{'action': s.stage == this.stage, 'acted': s.ready}">
                <div class="stage-wrapper-single-left">
                    <div v-if="s.img" class="stage-wrapper-single-circle">
                        <i :class="s.img"></i>
                    </div>
                    <div v-else class="stage-wrapper-single-circle">
                        {{1 + s.stage}}
                    </div>
                    <p class='fs_10' v-if="this.w">{{s.name}}</p>
                </div>
                <span v-if="1 + s.stage < stages.length" class='stage-wrapper-single-line' v-bind:class="{'up': (1 + s.stage) % 2 != 0, 'down': (1 + s.stage) % 2 == 0 }"></span>
            </div>
        </div>
    </div>    
</template>

<script>
import Button from '@/components/service/Button';

export default {
    components: {
        Button
    },
    props: {
        stages: Array,
        stage: [Number, String],
        buttons: [Boolean]
    },
    data() {
        return {
            act: false,
            w: true,
        }
    },
    created() {
        window.addEventListener('resize', this.checkSize);
    },
    computed: {
        stageColor: function (i) {
            return {
                action: i == this.stage,
                acted: i < this.stage
            }
        }
    },
    methods: {
        updateStage(i) {
            this.$emit('updateParentState', i)
        },
        checkSize() {
            this.w = this.$store.state.resize.w > 850 ? true : false;
        }
        
    },
}
</script>

<style lang="scss" scoped>
    .w-padding {
        padding-bottom: 70px;
    }
    .stage {
        position: relative;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        height: max-content;
        &-wrapper {
            margin: 0 auto;
            width: 90%;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: center;
            .action {
                .stage-wrapper-single-circle{
                    // outline: 7px solid $color-outline;
                    box-shadow: 0 0 0 5pt $color-outline;
                    outline-offset: -1px;
                    border-color: $color-outline !important;
                }
                div {
                    color: $color-green !important;
                    border-color: $color-green;
                }
                .up {
                    background-image: url('~@/assets/av/upc.svg') !important;
                }
                .down {
                    background-image: url('~@/assets/av/downc.svg') !important;
                }
                p {
                    color: $color-green !important;
                }
            }
            .acted {
                .stage-wrapper-single-circle{
                    color: #FFF !important;
                    background-color: $color-green;
                }
                div {
                    border-color: $color-green;
                }
                .up {
                    background-image: url('~@/assets/av/upc.svg') !important;
                }
                .down {
                    background-image: url('~@/assets/av/downc.svg') !important;
                }
                p {
                    color: $color-green !important;
                }
            }
            &-single:not(:last-child) {
                flex: 1;
            }
            &-single {
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                font-family: 'HelveticaNeue light';
                font-size: 18px;
                max-width: 160px;
                &-left {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    text-align: center;
                    cursor: pointer;
                    p {
                        // width: 100%;
                        position: absolute;
                        max-width: 150px;
                        top: 70px;
                        font-size: 0.90rem;
                        line-height: 1.15rem;
                        font-weight: normal;
                        font-family: 'HelveticaNeue roman';
                    }
                }
                &-circle { 
                    width: 1.7em;
                    height: 1.7em;
                    border: solid $color-grey 0.113rem;
                    color: $color-grey;
                    border-radius: 100%;
                    font-size: 1.3rem;
                    margin-bottom: 0.5rem;
                    text-align: center;
                    display: flex;
                    align-items: center;
                    justify-content: space-around;
                }
                &-line {
                    display: block;
                    position: relative;
                    height: 2em;
                    width: 100%;
                    max-width: 125px;
                    background-position: center;
                    background-repeat: no-repeat;
                    color: $color-grey;
                }
                .up {
                    background-image: url('~@/assets/av/up.svg');
                    top: -0.35rem;
                }
                .down {
                    background-image: url('~@/assets/av/down.svg');
                    top: 0.35rem;
                }
                p {
                    color: $color-grey;
                }
            }
        }
    }
</style>