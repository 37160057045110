<template>
    <div class='container-fluid p-0 d-flex auth-bg justify-content-center align-items-center min-vh-100 w-100 position-relative'>
        
    </div>
</template>

<script>
import InputSimple from '@/components/service/InputSimple';
import Button from '@/components/service/Button';
import Registration from '@/components/service/Registration';
import { gsap } from "gsap";
import LangSwitcher from '@/components/service/LangSwitcher';

export default {
    components: {
        InputSimple,
        Button,
        Registration,
        LangSwitcher
    },
    data() {
        return {
            data: {
                cabinet_login: '',
                pass: '',
                info: '',
                surname: '',
                firstname: '',
                patronymic: '',
                birth_date: '',
            },
            stage: 'auth',
            timeOut: false,
            continue: false,
            ask: false,
            user: false,
        }
    },
    mounted() {
        if (this.$store.state.isLogged) {
            this.$store.dispatch('logout', this.$router);
        }
        let b = document.querySelector('body');
        let w  =document.querySelector('.wrapper');
        b.classList.add('before-bg');
        w.classList.add('beforew-bg');
        b.classList.remove('after-bg');
        w.classList.remove('afterw-bg');
    },
    beforeUnmount() {
        let b = document.querySelector('body');
        let w  =document.querySelector('.wrapper');
        b.classList.remove('before-bg');
        b.classList.add('after-bg');
        w.classList.remove('beforew-bg');
        w.classList.add('afterw-bg');
    },
    methods: {
        login() {
            this.axios.post('login', {data: {cabinet_login: this.data.cabinet_login, password: this.data.pass}}).then(
                (res) => {
                    this.$store.commit('USER_DATA', res.data.body)
                    this.$store.dispatch('informer');
                }
            );
        },
        updateState(state) {
            this.data[state.id] = state.value;
        },
        next(e) {
            if (e.keyCode === 13) {
                this.$refs.pass.focusInput();
            }
        },
        submit(e) {
            if (e.keyCode === 13) {
                this.login();
            }
        },
        find_recover() {
            this.axios.post('checkUser', {
                data: {info: this.data.info}
            }).then(res => {
                if (res.data.body.tables[0].colvalues.length > 0) {
                    this.user = res.data.body.tables[0].colvalues[0];
                    this.stage = 'finded';
                } else {
                    this.stage = 'ask';
                    this.user = false;
                }
            })
        },
        hard_recover() {
            this.axios.post('findUser', {
                data: {
                    last_name: this.data.surname,
                    first_name: this.data.firstname,
                    midle_name: this.data.patronymic,
                    birth_date: this.data.birth_date,
                }
            }).then(res => {
                if (res.data.body.tables[0].colvalues.length > 0) {
                    this.user = res.data.body.tables[0].colvalues[0];
                    this.stage = 'finded';
                } else {
                    this.stage = 'ask';
                    this.user = false;
                }
            })
        },
        recover() {
            this.axios.post('recoverPass', {
                    data: {info: this.data.info, user: this.user}
                }).then(res => {
                    if (res.data.body.result == '0') {
                        this.stage = 'auth';
                    }
                })
        },
        backStage() {
            this.data = {
                cabinet_login: '',
                pass: '',
                info: '',
                surname: '',
                firstname: '',
                patronymic: '',
                birth_date: '',
            };
            this.stage = 'auth';
            this.timeOut = false;
            this.continue = false;
            this.response = false;
            this.user = false;
        },
        enter(el) {
            gsap.fromTo(el, {opacity: 0.5, x: '-80%'}, {opacity: 1, x: '0', duration: 0.7});
        },
        leave(el, done) {
            gsap.fromTo(el, {opacity: 1, x: '0'}, {opacity: 0.1, x: '-80%', duration: 0.7, position: 'absolute', onComplete: done});
        },
        enterA(el) {
            gsap.fromTo(el, {opacity: 0.5, x: this.$store.state.resize.w > 767 ? '+5%': '+10%'}, {opacity: 1, x: '0', duration: 0.7});
        },
        leaveA(el, done) {
            gsap.fromTo(el, {opacity: 1, x: '0'}, {opacity: 0.1, x: '+200%', duration: 0.7, position: 'absolute', onComplete: done});
        },
    }
}
</script>

<style lang="scss">
.auth-logo {
    max-width: min-content;
}
.auth-bg {
    // background-image: url($bg-image);
    // background-repeat: no-repeat;
    // background-size: cover;
    // background-clip: padding-box;
    overflow-x: hidden;
    text-overflow: ellipsis;
    position: relative;
}
.auth-container {
    .content {
        padding: 1rem 1.3rem;
    }
    .alt-mb {
        margin-bottom: 0.6rem;
    }
}
.auth-width {
    max-width: 465px;
}
.auth-ls {
    position: fixed;
    right: 0;
    top: 45%;
    background-color: $color-acent;
    border-radius: 12px 0 0 12px;
}
</style>