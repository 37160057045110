<template>
  <div ref='btntooltip' class='s-tooltip' :data-bs-placement="this.placement" data-bs-toggle="tooltip" :title="this.content" :data-bs-original-title="this.content">
      <slot></slot>
  </div>
</template>

<script>
  import { Tooltip } from 'bootstrap';
  export default {
    components: {
    },
    props: {
        content: {
            type: [String, Boolean],
            default: '',
        },
        hover: {
            type: Boolean,
            default: true,
        },
        placement: {
            type: String,
            default: 'top',
        }
    },
    data() {
      return {
        tool: false,
      }
    },
    mounted() {
      this.bp();
    },
    
    updated() {
      if (this.tool._element) {
        this.tool.dispose();
      }
      this.bp();
    },
  
    beforeUnmount() {
      this.tool.dispose();
    },
    methods: {
      bp() {
        this.tool = new Tooltip(this.$refs.btntooltip);
      }
    },
  };
</script>

<style lang="scss">
    .s-tooltip {
        cursor: pointer;
    }
</style>