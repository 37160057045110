<template>
    <div>
        <div class='d-flex justify-content-between neue mb-2'>
            <h4>{{ this.$t('views.ChoiseColumns.1') }}</h4>
            <i class='big item-i x_red pointer' v-on:click='this.close'></i>
        </div>
        <div>
            <div v-for="(item, index) in this.columns" :key="index" class="d-flex align-items-center">
                <SwitchSimple 
                    class='p-0 col-12 pointer'
                    :class="!this.columnsChoised[index].value ? 'neue-roman' : ''"
                    :id='"check_column_" + this.columnsChoised[index].id'
                    :label="this.columnsChoised[index].text_name ? this.columnsChoised[index].text_name : this.columnsChoised[index].name"
                    label_style="font-size: 1rem; cursor: pointer;"
                    :isSmall="true"
                    :disabled="this.isItemDisabled(this.columnsChoised[index].key_name)"
                    type='form-check-mark'
                    :dataValue="this.columnsChoised[index].value"
                    @updateParentState="this.updateState"
                />
            </div>
        </div>
        <div class="col-12 col-md-3 mt-4 m-auto d-flex flex-column flex-md-row align-items-center justify-content-between">
            <Button
                :name="this.$t('views.ATM.11')"
                class="col-12 col-sm-6"
                :class='{"mr-1" : this.$store.state.resize.w >= 768, "mb-2" : this.$store.state.resize.w < 768}'
                type='btn-outline-warning'
                @click="this.close"
                :style='"min-width: 108px;"'
            />
            <Button
                :name="this.$t('views.ATM.12')"
                class="col-12 col-sm-6"
                type='btn-warning'
                @click="this.setColumns()"
                :style='"min-width: 108px;"'
            />
        </div>
    </div>
</template>

<script>
import Button from '@/components/service/Button';
import SwitchSimple from '@/components/service/SwitchSimple';

export default {
    components: {
        Button,
        SwitchSimple
    },
    props: {
        close: [Function],
    },
    data() {
        return {
            columns: this.$store.state.modal.content.columns,
            columnsChoised: [],
            disabledColumns: this.$store.state.modal.content.disabledColumns,
        }
    },
    methods: {
        getColumnNumber(input) {
            const regex = /check_column_(\d+)/;
            const match = input.match(regex);

            if (match) {
                return match[1];
            } else {
                return null;
            }
        },
        updateState(arg) {
            const id = this.getColumnNumber(arg.id);
            const column = this.columnsChoised.find(item => item.id == id);
            column.value = arg.value;
        },
        setColumns() {
            this.$store.state.modal.content.updateColumns(this.columnsChoised);
            this.close();
        },
        isItemDisabled(key_name) {
            return this.disabledColumns.includes(key_name);
        }
    },
    created() {
        this.columnsChoised = JSON.parse(JSON.stringify(this.columns));
    }
}
</script>