<template>
  <div
    class="d-flex flex-column mt-3 p-0 mr-0 g-3 align-items-center input"
    :class="{ 'mt-0': this.first, 'flex-sm-row': !this.is_request }"
  >
    <label
      :for="id"
      class="col-form-label mt-0 pl-0 pt-0 col-12 pb-0 pr-1 input-label"
      :class="!this.is_request ? 'col-sm-3' : 'mb-1'"
    >
      {{ this.label }}
      <span class="require" v-if="this.require"> *</span>
    </label>
    <div class="col-12 input-wrapper mt-0 p-0" :class="!this.is_request ? 'col-sm-9' : ''">
      <InputSimple
        :id="id"
        :invalid="this.invalid"
        :invalidHint="this.invalidHint"
        :autocomplete="this.autocomplete"
        :type="this.type"
        :disabled="this.disabled"
        :placeholder="this.placeholder"
        :success="this.success"
        @blurInput="this.blure"
        @onEnter="this.onEnter"
        :regCheck="this.regCheck"
        :dataValue="this.dataValue"
        @updateParentState="this.setValue"
        :autofocus="this.autofocus"
      />
      <Popper v-if="this.hint" class="bs-tooltip" :content="this.hint" />
      <div v-else-if="this.unvisible" class="mw34"></div>
      <Popper v-if="this.verification" :custom="'item-i check'" :content="$t('views.InfoReg.3')" />
      <div v-else-if="this.unvisible || this.vMark" class="mw34"></div>
      <div v-if="['jur_apartment', 'fact_apartment', 'work_apartment'].includes(this.id)" class="mw54"></div>
      <SlotPopper v-if="this.unvisible  && !['jur_apartment', 'fact_apartment', 'work_apartment'].includes(this.id)" :content="$t('views.InfoReg.4')">
        <SwitchSimple
          :id="id"
          :dataValue="this.unvisibleValue"
          :disabled="this.is_public == '2' ? true : false"
          :type="'sm'"
          class="ml-1"
          @updateParentState="this.setUnvisibleValue"
        />
      </SlotPopper>
    </div>
  </div>
</template>

<script>
// import { Tooltip } from 'bootstrap';
import InputSimple from "@/components/service/InputSimple";
import SwitchSimple from "@/components/service/SwitchSimple";
import Popper from "@/components/service/Popper";
import SlotPopper from "@/components/service/SlotPopper";

export default {
  components: {
    Popper,
    InputSimple,
    SwitchSimple,
    SlotPopper,
  },
  data() {
    return {
      value: this.dataValue ? this.dataValue : "",
      unvisibleValue: this.unvisibleDataValue ? this.unvisibleDataValue : "",
      sizeW: this.$store.state.resize.w,
    };
  },
  props: {
    id: [String, Boolean],
    first: {
      type: Boolean,
      default: false,
    },
    label: [String],
    type: [String],
    hint: [String, Boolean],
    dataValue: [String, Boolean],
    require: [Boolean],
    disabled: [String, Boolean],
    regCheck: [Object, Boolean],
    invalid: [Boolean, String],
    invalidHint: [String, Boolean],
    verification: [String, Boolean],
    placeholder: [String, Boolean],
    autofocus: [Boolean],
    success: [Boolean],
    autocomplete: {
      type: [String, Boolean],
      default: "new-password",
    },
    unvisible: [Boolean],
    unvisibleDataValue: [String, Boolean],
    vMark: [Boolean],
    is_public: [Boolean, String],
    is_request: [Boolean],
  },
  mounted() {
    // if (this.unvisible) {
    // this.bp();
    // }
  },
  methods: {
    setValue(arg) {
      this.$emit("updateParentState", {
        id: arg.id,
        value: arg.value,
      });
    },
    // bp() {
    //     new Tooltip(this.$refs.btntooltip2)
    // },
    setUnvisibleValue(arg) {
      this.$emit("updateParentState", {
        id: arg.id,
        unvisible: true,
        value: arg.value,
      });
    },
    blure(arg) {
      this.$emit("blurInput", {
        id: arg.id,
        value: arg.value,
      });
    },
    onEnter() {
      this.$emit("onEnter");
    },
    bp() {
      new Tooltip(this.$refs.btntooltip2);
    },
  },
};
</script>

<style lang="scss" scoped>
.input {
  font-family: "HelveticaNeue roman";
  &-wrapper {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
  }
}
.mt-1 {
  margin: 0 !important;
}
.col-form-label {
  line-height: 1.2 !important;
}

.require {
  color: red;
}
</style>
