<template>
    <div>
        <div class='d-flex justify-content-between'>
            <h4 v-if='this.content.result == "852"'>{{ $t('components.service.modlas.Repeat.1') }}</h4>
            <h4 v-else-if='this.content.result == "856"'>{{ $t('components.service.modlas.Repeat.2') }}</h4>
            <h4 v-else-if='this.content.result == "857"'>{{ $t('components.service.modlas.Repeat.3') }}</h4>
            <h4 v-else-if='this.content.result == "880"'>{{ $t('components.service.modlas.Repeat.4') }}</h4>
            <h4 v-else>{{this.content.result_text}}</h4>
            <i class='big item-i x_red' v-on:click='this.close'></i>
        </div>
        <div v-if='this.warn' class='row container-fluid mt-1 p-0 pb-1 g-3 neue-roman'>
            <p class='p-0 m-0 color-warning'>{{this.warn}}</p>
        </div>
        <div v-if='this.content.result == "857"' class='row container-fluid mt-1 p-0 g-3 neue-roman'>
            <p class='p-0 m-0' v-html="this.content.advanced.notify_text"></p>
            <Input :label="$t('components.service.modal.Repeat.1')" id='sms_confirm_value' autofocus @onEnter="this.submit" @updateParentState="this.updateState" hint=''/>
        </div>
        <div v-if='this.content.result == "856"' class='row container-fluid mt-1 p-0 g-3 neue-roman'>
            <p class='p-0 m-0' v-html="this.content.advanced.confirm_text"></p>
            <Input :label="$t('components.service.modal.Repeat.1')" id='sms_confirm_value' autofocus @onEnter="this.submit" @updateParentState="this.updateState" hint=''/>
        </div>
        <div v-if='this.content.result == "880"' class='row container-fluid mt-1 p-0 g-3 neue-roman'>
            <p class='p-0 m-0' v-html="this.content.advanced.confirm_text"></p>
            <Input :label="$t('components.service.modal.Repeat.1')" id='sms_confirm_value' autofocus @onEnter="this.submit" @updateParentState="this.updateState" hint=''/>
        </div>
        <div v-if='this.content.result == "852"' class='row container-fluid mt-1 p-0 g-3 neue-roman'>
            <p class='p-0 m-0' v-html="this.content.advanced.confirm_text"></p>
            <InputSimple class="p-0 col-10 col-md-8 col-lg-6 col-xl-5" id='sms_confirm_value' autofocus @onEnter="this.submit" @updateParentState="this.updateState" hint=''/>
        </div>
        <div class='d-flex mx-auto justify-content-center pt-3'>
            <Button :name="$t('components.service.modal.Repeat.2')" :disabled='this.dis' type='btn-warning' v-on:click='this.submit'/>
        </div>
         
    </div>
</template>

<script>
import Button from '@/components/service/Button';
import Input from '@/components/service/Input';
import InputSimple from '@/components/service/InputSimple';

export default {
    components: {
        Button,
        Input,
        InputSimple
    },
    props: {
        close: [Function],
    },
    data () {
        return {
            content: this.$store.state.modal.content,
            sms_confirm_value: false,
            dis: true,
            warn: '',
            callbacks: {
                login:(res) => {
                    this.$store.commit('USER_DATA', res.data.body);
                    this.$store.commit('DARK_WRAPPER', false);
                }
            } 
        }
    },
    created() {
        if (this.content.result == "857") {
            let rx = new RegExp(/\[(.*)\]/g);
            let arr = rx.exec(this.content.advanced.notify_text);
            this.content.advanced.notify_text = this.content.advanced.notify_text.replace(arr[0], `<b>${arr[1]}</b>`);
        }
    },
    methods: {
        updateState(state) {
            this.sms_confirm_value = state.value;
            if (this.sms_confirm_value) {
                this.dis = false;
            } else {
                this.dis = true;
            }
        },
        getCallback(res) {
            if (this.content.repeat_url == 'login') {
                this.callbacks.login(res)
            } else {
                return;
            }
        },
        submit() {
            if (!this.sms_confirm_value) {
                return;
            }
            let req = this.content.repeat;
            req.data.repeat = true;
            if ( this.content.result == "857" ) {
                req.data.magicword = this.sms_confirm_value;
            }
            if ( this.content.result == "856" ) {
                req.data.onekey = this.sms_confirm_value;
            }
            if ( this.content.result == "880" ) {
                req.data.regconfirm = this.sms_confirm_value;
            }
            if ( this.content.result == "852" ) {
                req.data.sms_confirm_value = this.sms_confirm_value;
            }
            this.axios.post(this.content.repeat_url, req).then(res => {
                if (this.content.result == '852') {
                    if (res.data.body.result) {
                        this.warn = res.data.body.result_text;
                        return;
                    }
                } 
                    this.$store.commit('MODAL', {
                        action: false,
                        type: false,
                        content: false
                    }) 
                    this.getCallback(res);
                
            });
        }
    },
}
</script> 

<style lang="scss" scoped>
.big {
    background-size: contain;
    cursor: pointer;
}
</style>