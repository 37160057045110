<template>
    <div>
        <div class='d-flex justify-content-between neue mb-2'>
            <h4>{{ this.content.title }}</h4>
            <i class='big item-i x_red pointer' v-on:click='this.close'></i>
        </div>
        <div class="col-12 col-md-3 mt-4 m-auto d-flex flex-column flex-md-row align-items-center justify-content-between">
            <Button
                :name="this.$t('components.service.modal.Warning.1')"
                class="col-12 col-sm-6"
                :class='{"mr-1" : this.$store.state.resize.w >= 768, "mb-2" : this.$store.state.resize.w < 768}'
                type='btn-outline-warning'
                @click="this.close"
                :style='"min-width: 108px;"'
            />
            <Button
                :name="this.content.agreeText"
                class="col-12 col-sm-6"
                type='btn-red'
                @click="agree()"
                :style='"min-width: 108px;"'
            />
        </div>
    </div>
</template>

<script>
import Button from '@/components/service/Button';

export default {
    components: {
        Button,
    },
    props: {
        close: [Function],
    },
    data() {
        return {
            content: this.$store.state.modal.content,
        }
    },
    methods: {
        agree() {
            this.close();
            this.content.warningAgree();
        }
    }
}
</script>