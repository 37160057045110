<template>
    <div class="row container-fluid mr-0 ml-0 mt-3 p-0 g-3 align-items-center input neue-roman" :class="{'mt-0': this.first}">
        <div class="p-0 mt-0 col-sm-3">
            <label :for="id" class="col-form-label input-label pt-o pb-0">{{this.label}}<span class='require' v-if="this.require"> *</span></label>
        </div>

        <div class="col-sm-9 p-0 m-0 d-flex align-items-center">
            <div ref='s_cont' :id="id + '_s'" :class="{'disable': this.disable, 'invalid': this.invalid}" class='input-container form-control' tabindex='-1' v-on:click="this.showOpt()">
                <div class="input-container-select" :id="id">
                    <span v-if="this.isSearch" v-show="this.showOptions" class="input-search-wrapper">
                        <input class="input-search" ref='input_search' type="search" @input="inputSearchHandler" tabindex="-1"/>
                    </span>
                    <span class="input-container-select-value" v-show="!this.showOptions" v-html="this.name ? this.sizedName(this.name) : this.defaultValue"></span>
                    <div class="c-arrow" @click="this.clickArrowSelect()">
                        <i :class="{'bi bi-chevron-down': !showOptions, 'bi bi-chevron-up': showOptions}"></i>
                    </div>
                </div> 

                <transition @enter="enter" @leave="leave">
                    <div>
                        <div 
                            ref='o_cont' 
                            class="input-container-select-options" 
                            :class="this.transformUp ? 'open-up' : ''"
                            :style='!this.transformUp ? "max-height: " + this.listHeight + "px; top: 2.17rem;" : "top: 0;"' 
                            v-if="showOptions && !disable && isSearch"
                        >
                            <div class="d-flex align-items-center input-container-select justify-content-between" @click="this.checkAll()">
                                <label class="fs_12">{{ this.isAllCheck ? $t('views.MultiSelect.1') : $t('views.MultiSelect.2') }}</label>
                                <SwitchSimple 
                                    class='p-0' 
                                    :dataValue="this.isAllCheck" 
                                    type='form-check-mark' 
                                    id='activeAll'
                                    @updateParentState="this.checkAll()" 
                                    :isCheckMark="true"
                                />
                            </div>
                            <div class="input-container-select-options-li d-flex justify-content-between" v-for="option in this.optionsList" :key="option.code" :class="{'acted': this.isValueRepeat(option.code)}" v-on:click="() => {this.selectOption(option.code)}">
                                <label v-html="option.value || option.name"></label>
                                <SwitchSimple 
                                    class='p-0' 
                                    :dataValue="this.isValueRepeat(option.code)" 
                                    type='form-check-mark' 
                                    id='active'
                                    :isCheckMark="true"
                                />
                            </div>
                        </div>
                        <div ref='o_cont' class="input-container-select-options" :style='"max-height: " + this.listHeight + "px; top: 2.17rem;"' v-else-if="showOptions && !disable">
                            <div class="d-flex align-items-center input-container-select justify-content-between" @click="this.checkAll()">
                                <label>{{ this.isAllCheck ? $t('views.MultiSelect.1') : $t('views.MultiSelect.2') }}</label>
                                <SwitchSimple 
                                    class='p-0' 
                                    :dataValue="this.isAllCheck" 
                                    type='form-check-mark' 
                                    id='activeAll'
                                    @updateParentState="this.checkAll()"
                                    :isCheckMark="true"
                                />
                            </div>
                            <div class="input-container-select-options-li d-flex align-items-center input-container-select justify-content-between" v-for="keyword in this.keywords" :key="keyword.code" :class="{'acted': this.isValueRepeat(keyword.code)}" v-on:click="() => {this.selectOption(keyword.code)}">
                                <label v-html="keyword.value || keyword.name"></label>
                                <SwitchSimple 
                                    class='p-0'
                                    :dataValue="this.isValueRepeat(keyword.code)" 
                                    type='form-check-mark' 
                                    id='active'
                                    :isCheckMark="true"
                                />
                            </div>
                        </div>
                    </div>
                </transition>
            </div>
            <Popper v-if="this.hint" class="bs-tooltip" :content='this.hint'/>
            <div v-else-if="this.doublebus" class="mw34" style="margin: 0 5px;"></div>
            <div v-else-if="this.unvisible" class="mw34"></div>
            <Popper v-if="this.verification" :custom="'item-i check'" :content="$t('views.InfoReg.3')"/>
            <div v-else-if="this.unvisible || this.vMark" class="mw34"></div>
            <SlotPopper v-if="this.unvisible"  :content="$t('views.InfoReg.4')">
                <SwitchSimple :id="id" :dataValue='this.unvisibleValue' :disabled="this.is_public == '2' ? true : false" :type="'sm'" class="ml-1" @updateParentState="this.setUnvisibleValue"/>
            </SlotPopper>
        </div>
    </div>
</template>

<script>
import { gsap } from "gsap";
import InputSimple from '@/components/service/InputSimple';
import Button from '@/components/service/Button';
import Popper from '@/components/service/Popper';
import SlotPopper from '@/components/service/SlotPopper';
import SwitchSimple from '@/components/service/SwitchSimple';

export default {
    components: {
        InputSimple,
        Button,
        Popper,
        SwitchSimple,
        SlotPopper
    },
    data() {
        return {
            value: this.dataValue ? this.dataValue : '',
            showOptions: false,
            name: this.dataValue ? this.getOptions(this.dataValue) : '',
            listHeight: '200',
            elemWidth: false,
            k_name: this.$store.state.config["KEYT_ALTERNAME_TOKEN"],
            optionsList: [],
            strFullCodes: '',
            isAllCheck: false,
            arrowClick: false,
            transformUp: false,
        }
    },
    props: {
        id: [String, Boolean],
        keywords: [Array, String, Boolean],
        dataValue: [String, Boolean],
        hint: [String, Boolean],
        require: [Boolean],
        label: [String],
        invalid: [Boolean],
        doublebus: [Boolean],
        verification: [String, Boolean],
        first: {
            type: Boolean,
            default: false,
        },
        separator: {
            type: [String],
            default: [',']
        },
        hotUpdate: {
            default: false,
            type: [Boolean],
        },
        disable: {
            default: false,
            type: [Boolean]
        },
        isSearch: {
            default: false,
            type: [Boolean]
        },
        defaultValue: {
            default: '',
            type: [String],
        },
        unvisible: [Boolean],
        unvisibleDataValue: [String, Boolean], 
        vMark: [Boolean],
        is_public: [Boolean, String]
    },
    mounted() {
        this.elemWidth = this.$refs.s_cont.offsetWidth;

        this.optionsList = [...this.keywords];
    },
    beforeUnmount() {
        document.querySelector('body').removeEventListener('click', this.clickClose);
    },
    watch: {
        dataValue(newV, oldV) {
            if (this.hotUpdate && (!this.value.includes(newV)|| newV == '')) {
                this.selectOption(newV, true);
            }
        }
    },
    methods: {
        clickArrowSelect() {
            console.log('click');
            if (this.showOptions) {
                this.closeSelect();
                this.arrowClick = true;
            }
        },
        setUnvisibleValue(arg) {
            this.$emit('updateParentState', {
                id: arg.id,
                unvisible: true,
                value: arg.value
            })
        },
        getCodesFull() {
            let str = '';
            let codesArray = [];
            this.optionsList.forEach(option => 
                codesArray = [...codesArray, Number(option.code)]);

            codesArray = codesArray.sort(function(a, b) {
                return a - b;
            });
            str = codesArray.join(this.separator);
            
            return str;
        },
        searchInList(arg) {
            if (!this.keywords) {
                return;
            }

        },
        sizedName(str) {
            if (!str) {
                return '';
            }
            if (this.elemWidth < 300 && str.length > 25) {
                return str.substring(0, 15) + '...';
            }
            if (this.elemWidth < 400 && str.length > 35) {
                return str.substring(0, 30) + '...';
            }
            if (this.elemWidth < 500 && str.length > 50) {
                return str.substring(0, 45) + '...';
            }
            if (this.elemWidth < 600 && str.length > 60) {
                return str.substring(0, 55) + '...';
            }
            if (this.elemWidth < 700 && str.length > 70) {
                return str.substring(0, 65) + '...';
            }
            if (this.elemWidth < 800 && str.length > 80) {
                return str.substring(0, 75) + '...';
            }
            if (this.elemWidth < 900 && str.length > 100) {
                return str.substring(0, 90) + '...';
            }
            if (this.elemWidth < 1000 && str.length > 120) {
                return str.substring(0, 100) + '...';
            }
            if (str.length > 130) {
                return str.substring(0, 110) + '...';
            }
            return str;
        },
        setValue(repeat = false) {
            this.$emit('updateParentState', {
                id: this.id,
                value: this.value,
                repeat: repeat,
            });
        },
        blur() {
            this.$emit('blurInput', {
                id: this.id,
                value: this.value
            })
        },
        sortArr() {
            let arr = this.keywords;
            arr.sort((r1, r2) => {
                if (r1.code != this.value && r2.code != this.value ) {
                    return 0;
                }
                if (r1.code == this.value && r2.code != this.value) {

                    return -1;
                }
                if (r1.code != this.value && r2.code == this.value) {
                    return 1;
                }
            });
        },
        showOpt() {
            if (this.arrowClick) {
                this.arrowClick = false;
                return;
            }
            
            if (this.disable) {
                this.showOptions = false;
                document.querySelector('body').removeEventListener('click', this.clickClose);
                return;
            }

            if (this.isSearch) {
                this.optionsList = [...this.keywords];
            }
            
            this.listHeight = this.getHeight();

            this.transformUp = Number(this.listHeight) < 70

            if (this.transformUp) {
                this.listHeight = '200'
            }

            this.showOptions = !this.isSearch ? !this.showOptions : true;
            !this.isSearch && this.showOptions ? this.$refs.s_cont.focus() : this.$refs.s_cont.blur();
            document.querySelector('body').addEventListener('click', this.clickClose);

            this.isSearch ? document.querySelector('body').blur() : '';

            if (this.isSearch && this.showOptions && this.$refs.input_search) {
                this.$refs.input_search.focus();
            }
        },
        getHeight() {
            let list = this.$refs.s_cont.getBoundingClientRect();
            let box = {bottom: window.innerHeight};
            return String((box.bottom + window.pageYOffset) - (list.bottom + window.pageYOffset) - 10);
        },
        clearSearchInput() {
            if (this.$refs.input_search) {
                this.$refs.input_search.value = '';
            }
        },
        closeSelect() {
            this.showOptions = false;
            document.querySelector('body').removeEventListener('click', this.clickClose);

            // метод содержит проверку наличия селекта
            this.clearSearchInput();
        },
        clickClose(e) {
            if (!this.$el.contains(e.target)) {
                this.closeSelect();
            }
        },
        enter(el) {
            gsap.fromTo(el, {opacity: 0.6, height: '50%', duration: 0.2}, {opacity: 1, height: 'auto', duration: 0.2});
        },
        leave(el, done) {
            gsap.fromTo(el, {opacity: 1}, {opacity: 0.6, height: 0, duration: 0.1, onComplete: done});
        },
        isValueRepeat(val) {
            const codesArray = this.value.split(this.separator);
            return codesArray.includes(val);
        },
        getOption(code) {
            let val = {};
            this.keywords.forEach(e => {
                if (e.code == code) {
                    if (e.place) {
                        val.g = true;
                    } else {
                        val.g = false;
                    }
                    val.n = e.value || e.name;
                }
            });
            return val;
        },
        getOptions(codeStr) {
            let valStr = '';
            const codeArray = codeStr.split(this.separator);

            const namesArray = [];
            codeArray.forEach(item => {
                const option = this.getOption(item);
                if (option && option.n){
                    namesArray.push(option.n);
                }
            })

            valStr = namesArray.join(', ');
            return valStr;
        },
        addValue(value, repeat) {
            // при первом выборе значения записываем только значения, для последующих +разделитель
            this.name = this.name ? this.name + ', ' + this.getOption(value).n : this.getOption(value).n;
            this.value = this.value ? this.value + this.separator + String(value) : String(value);

            if (!repeat) {
                this.setValue(repeat);
            }
        },
        removeValue(value, repeat) {
            const valuesArray = this.value.split(this.separator);
            const index = valuesArray.indexOf(value);

            // если такой элемент найден - удалить
            if (index != -1) {
                valuesArray.splice(index, 1);
            }

            this.value = valuesArray.join(this.separator);

            this.name = this.getOptions(this.value);

            if (!repeat) {
                this.setValue(repeat);
            }
        },
        selectOption(value, repeat = false) {
            if (value == '') {
                this.value = '';
                this.name = '';
                return;
            }

            if (value.includes(this.separator)) {
                const valuesArray = value.split(this.separator);
                valuesArray.forEach(value => {
                    !this.isValueRepeat(value) || this.value == '' ? this.addValue(value) : '';
                });
                return;
            }

            if (!this.isValueRepeat(value) || this.value == '') {
                this.addValue(value, repeat);
                return;
            }

            if (this.isValueRepeat(value)) {
                this.removeValue(value, repeat);
                return;
            }
        },
        inputSearchHandler(event) {
            const searchValue = event.target.value;

            const searchResult = this.keywords.filter(keyword => 
                keyword.value.toLowerCase().includes(searchValue.toLowerCase()));

            this.optionsList = [...searchResult];
        },
        areNotChecked() {
            this.strFullCodes = this.getCodesFull();
            let codesValuesArray = this.value.split(this.separator).map(Number);

            codesValuesArray = codesValuesArray.sort(function(a, b) {
                return a - b;
            });
            const valuesStr = codesValuesArray.join(this.separator);

            return this.strFullCodes.toString() != valuesStr.toString();
        },
        checkAll() {
            const notChecked = this.areNotChecked();
            if (notChecked && this.isAllCheck) {
                this.isAllCheck = !this.isAllCheck;
                return;
            }

            this.value = '';
            this.name = '';  

            if (!this.isAllCheck) {
                this.optionsList.forEach(option => this.addValue(option.code, false));
            }

            this.isAllCheck = !this.isAllCheck;
        }
    },
}
</script>

<style lang="scss" scoped>
.invalid {
    border-color: #dc3545;
    padding-right: calc(1.5em + 0.75rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 1.6rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
    box-shadow: none;
}
.mh-ks {
    min-height: 55px;
    height: 56px;
    border-bottom: 1px solid var(--bs-border-color);
}
.select-thin {
    max-height: 38px;
}
.simp-bg {
    background-color: $color-href;
    border: 1px solid $color-href;
    color: white;
    width: 24px;
    height: 24px;
    border-radius: 50%;
}
.input-container:focus:not(.disable) {
    border-color: #86b7fe;
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.input-container.disable:focus,
.input-container.disable:focus-visible {
    border: 1px solid var(--bs-border-color);
    outline: none;
    box-shadow: none;
}

.input-search-wrapper {
    display: flex;
    padding-left: 30px;
    width: 100%;
    background-image: url('~@/assets/icons/search.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: left center;
}
.input-search {
    width: calc(100% - 20px);
    border: none;
}
.input-search:focus,
.input-search:focus-visible {
    border: none;
    outline: none;
}
.disable {
    background-color: $color-lightgrey;
    cursor: default !important;
}
.acted {
    background-color: $color-light;
    font-family: HelveticaNeue bold;
}
.alter-col {
    padding-top: 0.4rem !important;
    padding-bottom: 0.4rem !important;
}
.k-symbol {
    display: flex;
    justify-content: center;
    align-items: center;
    span {
        font-size: 1rem;
        display: flex;
        align-items: center;
    }
}
.k_tr {
    padding: 0.375rem 0.75rem !important; 
}
.input-container {
        padding: 0;
        position: relative;
        cursor: pointer;
        &-select {
            width: 100%;
            z-index: 2000;
            outline:none;
            border: none;
            padding: 0.375rem 0.75rem;
            background: transparent;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            font-family: 'HelveticaNeue roman';
            .c-arrow, .c-arrow {
                height: 24px;
            }
            &-value {
                height: min-content;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            &-options {
                padding: 0;
                border-bottom: 1px solid #ced4da;
                border-left: 1px solid #ced4da;
                border-right: 1px solid #ced4da;
                left: -0.06rem;
                right: -0.06rem;
                background: #fff;
                position: absolute;
                overflow: auto;
                max-height: 280px;
                z-index: 3000;
                margin: 0;
                list-style-type: none;
                font-family: 'HelveticaNeue roman';
                border-radius: 0 0 0.25rem 0.25rem;
                &-li {
                    padding: 0.375rem 0.75rem;
                    label {
                        font-weight: regular;
                        cursor: pointer;
                    }
                    border-top: 1px solid $color-light;
                }
                &-li:hover {
                    background-color: $color-lightgrey;
                }
            }
        }
}

.open-up {
  transform: translateY(-100%);
  border-radius: 0.25rem;
  border-top: 1px solid #ced4da;
}

</style>