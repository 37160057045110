<template>
  <div v-if="this.custom" ref='btntooltip' class="mw34" :data-bs-placement="this.placement" data-bs-toggle="tooltip" :title="this.content">
    <i :class="this.custom"></i>
  </div>
  <div v-else ref='btntooltip' class='bs-tooltip mw34' :class="this.small_icon ? 'bs-tooltip--16' : ''" :data-bs-placement="this.placement" data-bs-toggle="tooltip" :title="this.content">
  
  </div>
</template>

<script>
  import { Tooltip } from 'bootstrap';
  export default {
    components: {
    },
    props: {
        content: {
            type: [String, Boolean],
            default: 'Some hint',
        },
        hover: {
            type: Boolean,
            default: true,
        },
        placement: {
            type: String,
            default: 'top',
        },
        custom: [String, Boolean],
        small_icon: {
          default: false,
          type: [Boolean],
        }
    },
    data() {
      return {
        tool: false,
      }
    },
    mounted() {
      this.bp();
      console.log('mounted this.content', this.content);
    },
    updated() {
      if (this.tool._element) {
        this.tool.dispose();
      }
      this.bp();
    },
  
    beforeUnmount() {
      //this.tool.dispose();
    },
    methods: {
      bp() {
        this.tool = new Tooltip(this.$refs.btntooltip);
      }
    },
  };
</script>

<style lang="scss">
.bs-tooltip {
  width: 26px;
  height: 26px;
  background-image: url('~@/assets/bi_question-circle.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 23px;
  cursor: pointer;
  margin: 0 5px;

  &--16 {
    width: 16px;
    height: 16px;
    min-width: auto;
    background-size: contain;
  }

  &--16.mw34 {
    min-width: auto;
  }
}
.mw34.mv-none {
  min-width: auto;
}
</style>