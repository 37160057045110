<template>
    <div>
        <div class='d-flex justify-content-between neue mb-2'>
            <h4>{{ this.$t('views.ChoiseKeyt.1') }}</h4>
            <i class='big item-i x_red pointer' v-on:click='this.close'></i>
        </div>
        <div class="keyts-container">
            <div class="keyts-wrapper">
                <InputsRadioGroups
                    name='choise_keyt' 
                    id='choise_keyt'
                    :values="this.radioNames"
                    :dataValue="this.keyt"
                    :defaultValue="this.defaultKeyt"
                    @updateParentState="this.setValue"
                />
            </div>
        </div>
        <div class="col-12 col-md-3 mt-4 m-auto d-flex flex-column flex-md-row align-items-center justify-content-between">
            <Button
                :name="this.$t('views.ATM.11')"
                class="col-12 col-sm-6"
                :class='{"mr-1" : this.$store.state.resize.w >= 768, "mb-2" : this.$store.state.resize.w < 768}'
                type='btn-outline-warning'
                @click="this.close"
                :style='"min-width: 108px;"'
            />
            <Button
                :name="this.$t('views.ATM.12')"
                class="col-12 col-sm-6"
                type='btn-warning'
                @click="this.setKeyt()"
                :style='"min-width: 108px;"'
            />
        </div>
    </div>
</template>

<script>
import Button from '@/components/service/Button';
import InputsRadioGroups from '@/components/service/InputsRadioGroups';

export default {
    components: {
        Button,
        InputsRadioGroups
    },
    props: {
        close: [Function],
    },
    data () {
        return {
            content: this.$store.state.modal.content,
            radioNames: [],
            keyt: false,
            defaultKeyt: false,
        }
    },
    methods: {
        setValue(arg) {
            this.keyt = arg.value;
        },
        setKeyt() {
            this.content.updateKeyt(this.keyt);
            this.close();
        }
    },
    created() {
        if (this.content.keyts.length > 0) {
            this.content.keyts.forEach(item => this.radioNames.push({value: item.keyt, text: item.keyt}));
            this.defaultKeyt = this.content.keyts[0].keyt;
        }
    }
}
</script>

<style lang="scss">
    .keyts-container {
        max-height: 60vh;

        @media (max-width: 767px) {
            max-height: 60vh;
        }
    }
    .keyts-wrapper {
        max-height: 45vh;
        overflow-y: auto;

        &::-webkit-scrollbar {
            width: 6px;
            border-radius: 20px;
        }

        &::-webkit-scrollbar-track {
            background: white;
            border-radius: 20px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: $color-menu;
            border-radius: 20px;
            border: 1px solid white;
        }

        @media (max-width: 767px) {
            max-height: 32vh;
        }
    }
</style>