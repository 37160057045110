<template>
    <div class="light">
        <div class='d-flex justify-content-between neue'>
            <h4>{{ $t('components.service.modal.Address.1') }}</h4>
            <i class='big item-i x_red pointer' v-on:click='this.close'></i>
        </div>
        <div class='container-fluid mt-1 p-0 g-3 position-relative'>
            <div class="row container-fluid mr-0 ml-0 mt-3 p-0 g-3 align-items-center input">
                <div class="col-sm-3 p-0 mt-0">
                    <label class="col-form-label neue input-label pt-0 pb-0">{{ this.$t('components.service.modal.Address.6') }}</label>
                </div>
                <div class="col-sm-9 p-0 input-wrapper mt-0">
                    <SelectSimple 
                    :id="this.content.field.alias + '_' + 'country'" 
                    :keywords.sync='this.copyContentAddr["address_" + this.content.field.alias +"_" + "country"]' 
                    :hotUpdate='true' 
                    :dataValue='this.copyContentAddr["address_" + this.content.field.alias + "_" + "country" + "_checked"]'
                    :isSearch="true"
                    searchType = "address"
                    @updateParentState="(val) => {this.selectAddressField(val, 'country')}"/>
                </div>
            </div>
            <div v-if="this.country != '643' && this.country != false">
                <div class="row container-fluid mr-0 ml-0 mt-3 p-0 g-3 align-items-center input">
                    <div class="col-sm-3 p-0 mt-0">
                        <label class="col-form-label neue input-label pt-0 pb-0"> 
                            {{ this.$t('components.service.modal.Address.12') }}
                        </label>
                    </div>
                    <div class="col-sm-9 p-0 input-wrapper mt-0">
                        <InputSimple
                            :id="this.content.field.alias + '_' + 'locality'"
                            :dataValue='this.findAddressInArray(
                                this.copyContentAddr["address_" + this.content.field.alias + "_" + "locality"],
                                this.copyContentAddr["address_" + this.content.field.alias + "_" + "locality" + "_checked"])'
                            @updateParentState="(val) => {this.selectAddressField(val, 'locality')}"
                        />
                    </div>
                </div>
                <div class="row container-fluid mr-0 ml-0 mt-3 p-0 g-3 align-items-center input">
                    <div class="col-sm-3 p-0 mt-0">
                        <label class="col-form-label neue input-label pt-0 pb-0">
                            {{ this.$t('components.service.modal.Address.18') }}
                        </label>
                    </div>
                    <div class="col-sm-9 p-0 input-wrapper mt-0">
                        <InputSimple
                            :id="this.content.field.alias + '_' + 'street'"
                            :dataValue='this.findAddressInArray(
                                this.copyContentAddr["address_" + this.content.field.alias + "_" + "street"], 
                                this.copyContentAddr["address_" + this.content.field.alias + "_" + "street" + "_checked"])'
                            @updateParentState="(val) => {this.selectAddressField(val, 'street')}"
                        />
                    </div>
                </div>
                <div class="row container-fluid mr-0 ml-0 mt-3 p-0 g-3 align-items-center input">
                    <div class="col-sm-3 p-0 mt-0">
                        <label class="col-form-label neue input-label pt-0 pb-0">
                            {{ this.$t('components.service.modal.Address.17') }}
                        </label>
                    </div>
                    <div class="col-sm-9 p-0 input-wrapper mt-0">
                        <InputSimple
                            :id="this.content.field.alias + '_' + 'postcode'"
                            :dataValue='this.findAddressInArray(
                                this.copyContentAddr["address_" + this.content.field.alias + "_" + "postcode"], 
                                this.copyContentAddr["address_" + this.content.field.alias + "_" + "postcode" + "_checked"])'
                            @updateParentState="(val) => {this.selectAddressField(val, 'postcode')}"
                        />
                    </div>
                </div>
            </div>
            <div v-else>
                <div class='' v-for="(row, index) in this.control_13" :key="row.code">
                    <!-- <h5 v-if="row.code == 'region'" class="neue-roman text-center pt-3 mb-0">{{ $t('components.service.modal.Address.2') }}</h5>
                    <h5 v-if="row.code == 'street'" class="neue-roman text-center pt-3 mb-0">{{ $t('components.service.modal.Address.3') }}</h5> -->
                    <div 
                        v-if='this.copyContentAddr["address_" + this.content.field.alias +"_" + row.code]' 
                        class="row container-fluid mr-0 ml-0 mt-3 p-0 g-3 align-items-center input"
                    >
                        <div class="col-sm-3 p-0 mt-0">
                            <label class="col-form-label neue input-label pt-0 pb-0">{{row.name}}</label>
                        </div>
                        <div v-if="row.code=='housing' || row.code=='building'" class="col-sm-9 p-0 input-wrapper mt-0">
                            <SelectSimple 
                                :id='this.content.field.alias + "_" + row.code'
                                :keywords.sync='this.copyContentAddr["address_" + this.content.field.alias + "_" + row.code]'
                                :hotUpdate='true'
                                :dataValue='this.copyContentAddr["address_" + this.content.field.alias + "_" + row.code + "_checked"]'
                                @updateParentState="(val) => {this.getAddress(val, row.code, index)}"
                                :isSearch="true"
                                searchType = "address"
                            />
                        </div>
                        <div class="col-sm-9 p-0 input-wrapper mt-0">
                            <SelectSimple 
                                :id="this.content.field.alias + '_' + row.code" 
                                :keywords.sync='this.copyContentAddr["address_" + this.content.field.alias +"_" + row.code]' 
                                :hotUpdate='true' 
                                :disable='this.content.field.verification == 1
                                    || (row.code != "country" && this.copyContentAddr["address_" + this.content.field.alias + "_" + row.code] && this.copyContentAddr["address_" + this.content.field.alias + "_" + row.code].length == 0)
                                    || row.code == "postcode" ? true : false'
                                :dataValue='this.copyContentAddr["address_" + this.content.field.alias + "_" + row.code + "_checked"]'
                                @updateParentState="(val) => {this.getAddress(val, row.code, index)}"
                                :isSearch="true"
                                searchType = "address"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div v-if='this.invalidHint' class="container-fluid mt-2 pl-0">
                <p class="color-warning">{{this.invalidHint}}</p>
            </div>
            <div class="container-fluid mt-2 d-flex  align-items-center justify-content-center">
                <Button :name="$t('components.service.modal.Address.4')" type='btn-outline-warning' v-on:click="this.close"/>
                <Button :name="$t('components.service.modal.Address.5')" class="ml-2" type='btn-outline-warning' :disabled="this.disable" v-on:click="this.save"/>
            </div>
        </div>
    </div>
</template>

<script>
import Button from '@/components/service/Button';
import InputSimple from '@/components/service/InputSimple';
import SelectSimple from '@/components/service/SelectSimple';


export default {
    components: {
        Button,
        SelectSimple,
        InputSimple
    },
    props: {
        close: [Function],
    },
    data () {
        return {
            content: this.$store.state.modal.content,
            control_13: {
                // 0: {name: this.$t('components.service.modal.Address.6'), code: 'country'},//
	            1: {name: this.$t('components.service.modal.Address.7'), code: 'region'},//
	            2: {name: this.$t('components.service.modal.Address.8'), code: 'district'},//
	            3: {name: this.$t('components.service.modal.Address.9'), code: 'area'},//
	            4: {name: this.$t('components.service.modal.Address.10'), code: 'city'},//
	            5: {name: this.$t('components.service.modal.Address.11'), code: 'intercity'},//
	            6: {name: this.$t('components.service.modal.Address.12'), code: 'locality'},//
	            7: {name: this.$t('components.service.modal.Address.13'), code: 'street'},//
	            8: {name: this.$t('components.service.modal.Address.14'), code: 'additional'},//
	            9: {name: this.$t('components.service.modal.Address.15'), code: 'additional_slave'},//
	            10: {name: this.$t('components.service.modal.Address.16'), code: 'house'},
	            // 11: {name: 'Корпус', code: 'housing'},
	            // 12: {name: 'Строение', code: 'building'},
	            13: {name: this.$t('components.service.modal.Address.17'), code: 'postcode'}
            },
            data : {},
            country: false,
            countriesList: [],
            disable: true, 
            copyContentAddr: {},
            invalidHint: false
        }
    },
    created() {
        // console.log('created modal content', this.content);
        // console.log('created start old data', this.content.addr);
        // console.log('created start new data', this.copyContentAddr);
        this.copyContentAddr = JSON.parse(JSON.stringify(this.content.addr));
        // console.log('created after copy old data', this.content.addr);
        // console.log('created after copy new data', this.copyContentAddr);
        this.countriesList = this.getAllCountries();
        this.country = this.getCountryValue() ? this.getCountryValue() : false;
        // console.log('created country', this.country);
    },
    mounted() {
        
    },
    beforeUnmount() {

    },
    methods: {
        selectAddressField(val, name) {
            // console.log('this.content.field', this.content.field);
            console.log('select_value', val)
            if (val.repeat) {
                return;
            }

            if (name == 'country') {
                this.copyContentAddr["address_" + this.content.field.alias + "_" + 'locality' + "_checked"] = '';
                this.copyContentAddr["address_" + this.content.field.alias + "_" + 'street' + "_checked"] = '';
                this.copyContentAddr["address_" + this.content.field.alias + "_" + 'postcode' + "_checked"] = '';
            }

            // console.log('this.copyContentAddr', this.copyContentAddr);

            if (name == 'country' && val.value != '643' && val.value != false){
                // console.log('country en');
                this.copyContentAddr["address_" + this.content.field.alias + "_" + 'locality'] = [];
                this.copyContentAddr["address_" + this.content.field.alias + "_" + 'street'] = [];
                this.copyContentAddr["address_" + this.content.field.alias + "_" + 'postcode'] = [];
                this.country = val.value;
                this.copyContentAddr["address_" + this.content.field.alias + "_" + 'country' + "_checked"] = val.value; 
                return;
            }

            if (name == 'country' && val.value != false) {
                // console.log('country ru');
                delete this.copyContentAddr["address_" + this.content.field.alias + "_" + 'locality'];
                delete this.copyContentAddr["address_" + this.content.field.alias + "_" + 'street'];
                delete this.copyContentAddr["address_" + this.content.field.alias + "_" + 'postcode'];
                this.country = val.value;
                this.getAddress(val, 'country', 0);
                return;
            }
            
            this.copyContentAddr["address_" + this.content.field.alias + "_" + name + "_checked"] = val.value;
            // console.log('selectAddressField this.copyContentAddr', this.copyContentAddr);
            this.disable = false; 

        },
        getAllCountries() {
            return this.copyContentAddr["address_" + this.content.field.alias + "_country"];
        },
        addAddressPart(key) {
            console.log('AddAddressPart key', key)
            console.log('copyContentAddr', this.copyContentAddr)
            return this.findAddressInArray(this.copyContentAddr["address_" + this.content.field.alias + key], this.copyContentAddr["address_" + this.content.field.alias + key + "_checked"], true)
            // return this.copyContentAddr["address_" + this.content.field.alias + key + "_checked"];
        },
        findAddressInArray(arr, key, inCopyContent = false) {
            console.log('findAddressInArray arr', arr);
            let name = inCopyContent ? key : '';
            arr.forEach(e => {
                if (e.code == key) {
                    name = e.name;
                }
            })
            return name;
        },
        getAddressString() {
            // собираем текстовое значение адреса
            let textValue = '';

            // индекс
            textValue = this.addAddressPart('_postcode') ? 
                textValue + this.addAddressPart('_postcode') : 
                textValue;

            // страна в адресе
            const country = this.countriesList.find(item => item.code == this.country);

            if (country) {
                textValue += ', ' + country.name;
            }

            // остальные части адреса
            textValue = this.addAddressPart('_locality') ? 
                textValue + ', ' + this.addAddressPart('_locality') : 
                textValue;

            textValue = this.addAddressPart('_street') ? 
                textValue + ', ' + this.addAddressPart('_street') : 
                textValue;

            // console.log('getAddressString textValue', textValue);
            return textValue;
        },
        getCountryValue() {
            const alias = this.content.field.alias;
            const countryKey = "address_" + alias + "_country" + "_checked";

            const countryVal = countryKey ? this.content.addr[countryKey] : '';
            return countryVal ? countryVal : '';
        },
        save() {
            const country = this.copyContentAddr["address_" + this.content.field.alias + "_" + "country_checked"];
            if (country != '643') {
                this.invalidHint = false;

                let preData = {
                    'country_type_info': '1',
                    'country': this.copyContentAddr["address_" + this.content.field.alias + "_" + "country_checked"],
                    'locality': this.findAddressInArray(this.copyContentAddr["address_" + this.content.field.alias + "_" + "locality"], this.copyContentAddr["address_" + this.content.field.alias + "_" + "locality_checked"], true),
                    'street':  this.findAddressInArray(this.copyContentAddr["address_" + this.content.field.alias + "_" + "street"], this.copyContentAddr["address_" + this.content.field.alias + "_" + "street_checked"], true),
                    'postcode':this.findAddressInArray(this.copyContentAddr["address_" + this.content.field.alias + "_" + "postcode"], this.copyContentAddr["address_" + this.content.field.alias + "_" + "postcode_checked"], true),
                    // 'region': this.copyContentAddr["address_" + this.content.field.alias + "_" + "region_checked"],
                    // 'region_type_info': '1',
                }

                this.axios.post('checkAddress', {data: preData}).then(res => {
                    if (res.data.body.result != '0') {
                        this.invalidHint = res.data.body.result_text;
                        return;
                    }
                    let keys = ['locality', 'street', 'postcode'];
                    keys.forEach(key => {
                        this.copyContentAddr["address_" + this.content.field.alias + "_" + key].push({
                            'name': preData[key],
                            'code': this.copyContentAddr["address_" + this.content.field.alias + "_" + key + "_checked"]
                        })
                    })
                    
                    this.copyContentAddr["address_" + this.content.field.alias + "_" + "house_checked"] = res.data.body.advanced.code;
                    this.returnValue();
                })

            } else {
                this.returnValue();
            }
            
        },
        returnValue() {
            this.content.addr = JSON.parse(JSON.stringify(this.copyContentAddr));
            console.log('save after copy new data', this.content.addr);
            this.content.updateState(
                {
                    id: this.content.field.alias,
                    value: this.content.addr["address_" + this.content.field.alias + "_" + "house_checked"],
                    new_addr: this.content.addr,
                }, 
                this.getCountryValue() == '643' ? 
                    this.content.addr["address_" + this.content.field.alias + "_" + "extra"][0]['value'] : 
                    this.getAddressString()
            );
            // console.log('after updateState', this.content.addr);
            this.close();
        },
        getAddress(val, key, index) {
            // console.log('getAddress arguments', val, key, index);
            // console.log('getAddress, copyContentAddr', this.copyContentAddr);
            if (val.repeat) {
                return;
            }7
            let params = {};
            params[key] = val.value;
            // console.log(val);
            this.copyContentAddr["address_" + this.content.field.alias + "_" + key + "_checked"] = val.value; 
            if (key == 'housing' || key == 'building') {
                return;
            }
            this.axios.post('getlistAddress', {data: params}).then(res => {
                let tables = res.data.body.tables;
                for (let i = 0; i < tables.length; i++) {
                    let name = tables[i].name;
                    if (tables[i].name == 'additionals') {
                        name = 'additional';
                    } else if (tables[i].name == 'additionals_slave') {
                        name = 'additional_slave';
                    } else if (tables[i].name == 'areas') {
                        name = 'area';
                    } else if (tables[i].name == 'cities') {
                        name = 'city';
                    } else if (tables[i].name == 'countries') {
                        name = 'country';
                    } else if (tables[i].name == 'districts') {
                        name = 'district';
                    } else if (tables[i].name == 'streets') {
                        name = 'street'
                    } else if (tables[i].name == 'regions') {
                        name = 'region';
                    } else if (tables[i].name == 'localities') {
                        name = 'locality';
                    } else if (tables[i].name == 'intercitys') {
                        name = 'intercity';
                    } 

                    if (i > index ) {
                        this.copyContentAddr["address_" + this.content.field.alias + "_" + name + "_checked"] = ''; 
                        if (tables[i].colvalues.length > 0) {
                            this.copyContentAddr["address_" + this.content.field.alias +"_" + name] = tables[i].colvalues;
                        }
                    }

                }

                if (key == 'house') {
                    // console.log('house');
                        this.copyContentAddr["address_" + this.content.field.alias + "_" + "postcode"] = [];
                        this.copyContentAddr["address_" + this.content.field.alias + "_" + "postcode_checked"] = this.copyContentAddr["address_" + this.content.field.alias + "_" + "house_checked"];
                        this.copyContentAddr["address_" + this.content.field.alias + "_" + "house"].forEach(e => {
                            this.copyContentAddr["address_" + this.content.field.alias + "_" + "postcode"].push({code: e.code, name: e.postcode});
                        });
                        this.disable = false;
                }

                    
                // console.log('getAddress new object', this.copyContentAddr);
            })
        }
    },
}
</script>