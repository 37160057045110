<template>
    <div>
        <div class='d-flex justify-content-between neue'>
            <h4>{{ $t('components.service.modal.Emission.1') }}</h4>
            <i class='big item-i x_red pointer' v-on:click='this.close'></i>
        </div>
        <div class='container-fluid mt-1 p-0 g-3'>
            <Select 
                :label="$t('components.service.modal.Emission.2')" 
                id='custom_curr'
                :require="true"
                :dataValue='this.data.custom_curr'
                :keywords.sync='this.currs'
                @updateParentState="this.updateState"
            />
            <div class="d-flex align-items-md-center col-12 mt-3 neue-roman">
                <div class="col-md-3 col-12">{{ this.$t('components.service.modal.Emission.8') }}</div>
                <div class="col-md-9 col-12 fs_08">
                    <span class="neue-bold">{{ this.data.current_emission }}</span> 
                    {{ this.data.name }}
                </div>
            </div>
            <div class="position-relative curr-wrapper-element">
                <Input
                    type='text'
                    :dataValue='this.data.emission_amount'
                    :invalid='this.summWarn'
                    :invalidHint='this.summWarn'
                    :label="$t('components.service.modal.Emission.5')"
                    id='emission_amount'
                    @updateParentState="this.updateState"
                />
                <div class="position-absolute curr-element">
                    {{ this.data.name }}
                </div>
            </div>
            <div v-if="this.data.bind_fiat_curr && this.data.cover_percent">
                <h4 class='p-0 mt-4 color-menu'>{{ this.$t('components.service.modal.Emission.10') }}</h4>
                <div class="d-flex col-12">
                    <div class="col-md-3 col-12 neue-roman">{{ this.$t('components.service.modal.Emission.13') }}</div>
                    <div class="col-md-9 col-12 flex-column flex-md-row d-flex justify-content-between align-items-md-center fs_08">
                        <div>
                            <div v-if="this.data.emission_amount">
                                {{ this.data.emission_amount }}
                                {{ this.data.name }} × 
                                {{ this.data.bind_fiat_count }} ×
                                {{ this.data.cover_percent }} % = 
                                {{ this.calculateCoverage() }}
                                {{ this.data.bind_fiat_curr }}
                            </div>
                        </div>
                        <div>
                            {{ this.$t('components.service.modal.Emission.14') }}
                            {{ this.data.bind_custom_count }}
                            {{ this.data.name }}
                            &nbsp;=&nbsp;
                            {{ this.data.bind_fiat_count }}
                            {{ this.data.bind_fiat_curr }}
                        </div>
                    </div>
                </div>
                <Select
                    :label="$t('components.service.modal.Emission.3')"
                    id='cover_from_keyt'
                    :keyt="true"
                    :dataValue='this.data.cover_from_keyt'
                    :keywords.sync='this.keywords'
                    @updateParentState="this.updateState"
                    :hint="'Подсказка'"
                />
            </div>
            <h4 class='p-0 mt-4 color-menu'>{{ this.$t('components.service.modal.Emission.11') }}</h4>
            <Comiss :sum="this.data.emission_amount" 
                ref="comiss"
                @updateParentState='this.setSummWarn' 
                @onSuccess="this.readyCheck"
                :params = "this.setParams()" 
                :cmd_name="'emission_custom_curr'" 
                :precision="'2'"
                :curr="this.data.bind_fiat_curr" 
            />
            <Select
                :label="$t('components.service.modal.Emission.4')"
                id='commission_from_keyt'
                :keyt="true"
                :dataValue='this.data.commission_from_keyt'
                :keywords.sync='this.keywords'
                @updateParentState="this.updateState"
            />
            <div class="row container-fluid g-3 align-items-center justify-content-center input mt-2">
                <div class="col-sm-2">
                    <Button 
                        :name="$t('components.service.modal.Emission.7')" 
                        v-on:click="this.emissionDo" 
                        type='btn-warning btn' 
                        :disabled='!this.ready'
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Button from '@/components/service/Button';
import Input from '@/components/service/Input';
import Comiss from '@/components/service/Comiss';
import Select from '@/components/service/Select';

export default {
    components: {
        Button,
        Input,
        Comiss,
        Select
    },
    props: {
        close: [Function],
    }, 
    computed: {
        t () {
            let k = this.$store.getters.get_keyts_keywords;
            if (k.length > 0) {
                this.getKeyts(k);
            }
            return k;
        }
    },
    data () {
        return {
            curr: this.$store.state.modal.content.curr,
            currs: this.$store.state.modal.content.currs,
            keywords: [],
            k_name: this.$store.state.config["KEYT_ALTERNAME_TOKEN"],
            data: {
                comment: '',
                commission_from_keyt: '',
                cover_from_keyt: '',
                custom_curr: this.$store.state.modal.content.curr.curr_code,
                emission_amount: '',
                current_emission: this.$store.state.modal.content.curr.current_emission,
                name: this.$store.state.modal.content.curr.name,
                bind_custom_count: this.$store.state.modal.content.curr.bind_custom_count,
                bind_fiat_count: this.$store.state.modal.content.curr.bind_fiat_count,
                bind_fiat_curr: this.$store.state.modal.content.curr.bind_fiat_curr,
                cover_percent: this.$store.state.modal.content.curr.cover_percent,
            },
            summWarn: false,
            ready: false,
        }
    },
    watch: {
        t: {
            handler: function(newData, oldData) {
                // console.log(newData)
                this.getKeyts(newData);
            },
            deep: true,
        }
    },
    mounted() {
        console.log('curr data full', this.$store.state.modal.content.curr);
    },
    methods: {
        calculateCoverage() {
            return this.data.emission_amount * this.data.bind_fiat_count * this.data.cover_percent / 100;
        },
        getKeyts(k) {
            this.keywords = k;
        },
        setParams() {
            let params = Object.assign({}, this.data);

            delete params['bind_custom_count'];
            delete params['bind_fiat_curr'];
            delete params['bind_fiat_count'];
            delete params['current_emission'];
            delete params['name'];
            delete params['cover_percent'];
            
            return params;
        },
        readyCheck(arg) {
            this.ready = arg;
        },
        setSummWarn(arg) {
            this.summWarn = arg
        },
        updateState(state) {
            this.data[state.id] = state.value;
        },
        emissionDo() {
            this.$store.commit('MODAL', {
                action: true,
                type: 'pay_pass',
                content: {callback: () => {
                    this.axios.post('EmissionCustomCurr', {data: this.setParams()});
                    // this.axios.post('payMomental', {data: this.checkParams()}).then( res => {
                        // if (res.data.body.result == '0') {
                            // console.log(this.grp)
                            // res.data.body['type'] = 'pay';
                            // this.init();
                            // console.log(this.grp)
                            // this.getOperator(this.grp);
                            // this.$store.commit('MODAL_ACTION', true);
                            // this.$store.commit('MODAL_TYPE', 'pay_status');
                            // this.$store.commit('MODAL_CONTENT', res.data.body);
                        // }
                    // })
                }},
            });
        }
    },
}
</script>

<style lang="scss" scoped>
    .answer_content :deep(a) {
            color: $color-href !important;
    }

    .curr-wrapper-element .curr-element {
        top: 6px;
        right: 10px;
        color: #A6A6A6;
        max-width: 120px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;

        @media (max-width: 768px) {
            max-width: 40px;
        }
    }
    
</style>