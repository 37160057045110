<template>
    <div class='wid position-relative'>
        <vue-tel-input class='form-control input-container' v-on:validate="this.blur" :inputOptions="{'placeholder': this.placeholder}"  :class='{"invalid": this.invalid, "success": this.success}' :value="this.phone" v-on:input='onInput' mode="international"></vue-tel-input>
        <div v-if='this.invalidHint && this.invalid' class="invalid-tooltip">
            {{this.invalidHint}}
        </div>
    </div>
</template>
<script>
import { VueTelInput } from 'vue3-tel-input'
import 'vue3-tel-input/dist/vue3-tel-input.css'

export default {
    components: {
        VueTelInput,
    },
    props: {
        id: [String, Boolean],
        invalid: [Boolean],
        success: [Boolean],
        invalidHint: [String, Boolean],
        dataValue: [String, Boolean], 
        placeholder: [String],
    },
    data() {
        return {
            phone: this.dataValue ? this.dataValue : null,
        }
    },
    methods: {
        onInput(phone, phoneObject, input) {
            if (phoneObject?.number) {
                this.phone = phoneObject.formatted;
                this.$emit('updateParentState', {
                    id: this.id,
                    value: phoneObject.number,
                })
            }
        },
        blur(val) {
            console.log(val)
            if (val.valid) {
                this.$emit('blurInput', {
                    id: this.id,
                    value: val.number
                })
            }
        },
    },
    
};
</script>

<style lang="scss">
.wid {
    width: 100%;
}
.vue-tel-input {
    border-radius: 0.375rem !important;
    border: 1px solid #ced4da !important;
    padding: 0;
    .vti__input {
        padding-top: 0.375rem;
        padding-bottom: 0.375rem;
        border-radius: 0 0.375rem 0.375rem 0 !important;
    }
    .vti__dropdown {
        background-color: $color-lightgrey;
        border-radius: 0.375rem 0 0 0.375rem !important;
    }
}
.vue-tel-input:focus {
    box-shadow: none;
}
.invalid-feedback {
    display: block;
}
.invalid {
    border-color: #dc3545 !important;
    padding-right: calc(1.5em + 0.75rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
    box-shadow: none;
}
.success {
    padding-right: calc(1.5em + 0.75rem);
    background-image: url('~@/assets/icons/check.svg');
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
    border-color: $color-system-green !important;
    box-shadow: none;
}
.invalid-tooltip {
    display: block;
}
input::placeholder {
    color: $color-grey !important;
}
input {
    font-family: "HelveticaNeue roman";
}

</style>