<template>
    <div ref="modal" tabindex="0" class='vue-modal' :class="{'pt-10': this.$store.state.modal.type == 'payHref' || this.$store.state.modal.type == 'widgetData' }">
        <div class='vue-modal-window neue mx-auto content p-4 col-xl-6 col-lg-8 col-10'>
            <Answer v-if="this.$store.state.modal.type == 'answer'" :close="this.close"/>
            <PayStatus v-if="this.$store.state.modal.type == 'pay_status'" :close="this.close"/>
            <PayPass v-if="this.$store.state.modal.type == 'pay_pass'" :close="this.close"/> 
            <Repeat v-if="this.$store.state.modal.type == 'repeat'" :close="this.close"/>
            <Confirm v-if="this.$store.state.modal.type == 'confirm'" :close="this.close"/>
            <HistoryParams v-if="this.$store.state.modal.type == 'history'" :close="this.close"/>
            <MembersModal v-if="this.$store.state.modal.type == 'members'" :close="this.close"/>
            <KeytRename v-if="this.$store.state.modal.type == 'k_rename'" :close="this.close"/>
            <Address v-if="this.$store.state.modal.type == 'address'" :close="this.close"/>
            <SupportList v-if="this.$store.state.modal.type == 'support'" :close="this.close"/>
            <TemplateRename v-if="this.$store.state.modal.type == 'rename'" :close="this.close"/>
            <WidgetData v-if="this.$store.state.modal.type == 'widgetData'" :close="this.close"/>
            <PayHref v-if="this.$store.state.modal.type == 'payHref'" :close="this.close"/>
            <ReEshop v-if="this.$store.state.modal.type == 'changeEshop'" :close="this.close"/>
            <Questionnaire v-if="this.$store.state.modal.type == 'questionnaire'" :close="this.close"/>
            <Emission v-if="this.$store.state.modal.type == 'emission'" :close="this.close"/>
            <EditP2POffer v-if="this.$store.state.modal.type == 'editP2P'" :close="this.close"/>
            <RequestP2POffer v-if="this.$store.state.modal.type == 'requestP2P'" :close="this.close"/>
            <CheckCurrP2P v-if="this.$store.state.modal.type == 'checkCurrP2P'" :close="this.close"/>
            <RequestP2PArbitrage v-if="this.$store.state.modal.type == 'requestArbitrageP2P'" :close="this.close"/>
            <EditExternalP2PCurrencies v-if="this.$store.state.modal.type == 'editExternalP2PCurrencies'" :close="this.close" />
            <ATMIncassStatuses v-if="this.$store.state.modal.type == 'ATMIncassStatuses'" :close="this.close" />
            <CollectorsList v-if="this.$store.state.modal.type == 'CollectorsList'" :close="this.close" />
            <InfoCollector v-if="this.$store.state.modal.type == 'InfoCollector'" :close="this.close" />
            <ChoiseKeyt v-if="this.$store.state.modal.type == 'ChoiseKeyt'" :close="this.close" />
            <ChoiseColumns v-if="this.$store.state.modal.type == 'ChoiseColumns'" :close="this.close" />
            <Warning v-if="this.$store.state.modal.type == 'Warning'" :close="this.close" />
        </div>
    </div>
</template>

<script>
import Answer from '@/components/service/modals/Answer';
import Confirm from '@/components/service/modals/Confirm';
import PayPass from '@/components/service/modals/PayPass';
import Repeat from '@/components/service/modals/Repeat';
import KeytRename from '@/components/service/modals/KeytRename';
import HistoryParams from '@/components/service/modals/HistoryParams';
import MembersModal from '@/components/service/modals/MembersModal';
import PayStatus from '@/components/service/modals/PayStatus';
import Address from '@/components/service/modals/Address';
import SupportList from '@/components/service/modals/SupportList';
import TemplateRename from '@/components/service/modals/TemplateRename';
import WidgetData from '@/components/service/modals/WidgetData';
import PayHref from '@/components/service/modals/PayHref';
import ReEshop from '@/components/service/modals/ReEshop';
import Questionnaire from '@/components/service/modals/Questionnaire';
import Emission from '@/components/service/modals/Emission';
import EditP2POffer from '@/components/service/modals/p2p/EditP2POffer';
import RequestP2POffer from '@/components/service/p2p/RequestP2POffer';
import ParamsP2PSwap from '@/components/service/p2p/ParamsP2PSwap';
import CheckCurrP2P from './modals/p2p/CheckCurrP2P.vue';
import RequestP2PArbitrage from './modals/p2p/RequestP2PArbitrage.vue';
import EditExternalP2PCurrencies from './modals/p2p/EditExternalP2PCurrencies.vue';
import ATMIncassStatuses from './modals/ATMIncassStatuses.vue';
import CollectorsList from './modals/CollectorsList.vue';
import InfoCollector from './modals/InfoCollector.vue';
import ChoiseKeyt from './modals/ChoiseKeyt.vue';
import ChoiseColumns from './modals/ChoiseColumns.vue';
import Warning from './modals/Warning.vue';

export default {
    components: {
        Answer,
        PayPass,
        Repeat,
        HistoryParams,
        KeytRename,
        Confirm,
        MembersModal,
        PayStatus,
        Address,
        SupportList,
        TemplateRename,
        WidgetData,
        PayHref,
        ReEshop,
        Questionnaire,
        Emission,
        EditP2POffer,
        RequestP2POffer,
        ParamsP2PSwap,
        CheckCurrP2P,
        RequestP2PArbitrage,
        EditExternalP2PCurrencies,
        ATMIncassStatuses,
        CollectorsList,
        InfoCollector,
        ChoiseKeyt,
        ChoiseColumns,
        Warning
    },
    mounted() {
        this.$refs.modal.focus();
        document.querySelector('body').style.overflow = 'hidden';
        this.$store.commit('DARK_WRAPPER', true);
    },
    unmounted() {
        document.querySelector('body').style.overflow = 'auto';
    },
    methods: {
        close() {
            this.$store.commit('MODAL', {
                                        action: false,
                                        type: false,
                                        content: false,
                                        }
                                );
            this.$store.commit('DARK_WRAPPER', false);
        }
    },
}
</script>

<style lang="scss">
    .vue-modal {
        position: fixed;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        // background: rgba(0, 0, 0, 0.3); // Make sure this color has an opacity of less than 1
        z-index: 4000;
        padding-top: 20vh;
        outline: none;
        overflow: auto;
        h4 {
            font-size: 1.3rem;
        }
    }
    .pt-10 {
        padding-top: 10vh !important;
    }
</style>