<template>
    <div :class="this.class_menu">
        <div v-if="this.$store.state.userData.id" class='container-xl p-1 d-flex flex-column-reverse flex-sm-row g-3 align-items-start justify-content-between'>
            <div class='col-12 col-sm-6 no_mr g-3'>
                <div v-if='Object.keys(this.keyts).length > 0' class="d-flex flex-wrap dep_2 no_mr g-3 align-items-center justify-content-start">
                    <span class='mr-2 neue fs_09' v-for="(row, index) in this.keyts" :key='index'> 
                        <span class='dep_k4' v-html='this.sumMask(String(row))'></span>
                        <span class='lgrey dep_k3 light'> {{index}}</span>
                    </span>
                    <span v-if="Object.keys(this.keyts).length > 3" class='' v-on:click='this.reActive'>
                        <i v-if="!this.active" class='item-i chevron-down'></i>
                        <i v-if="this.active" class='item-i chevron-up'></i>
                    </span>
                </div>
            </div>
            <div v-if='this.idents.length > 0' class='col-12 col-sm-6 neue fs_09 dep_2 d-flex flex-wrap g-3 align-items-baseline justify-content-start justify-content-sm-end'>
                <span  v-for="row in this.idents" :key='row.code' class='d-flex g-3 align-items-center justify-content-between pointer cli_1' :data-clipboard-target="'#_' + row.code">
                    <span> {{ $t('components.service.topmenu.1') }} {{row.ident}}</span> 
                    <i class='item-i copy'></i>
                    <input type='hidden' :id='"_" + row.code' :value="row.ident"/>
                </span>
            </div>
            <div v-else class='col-12 col-sm-6 neue fs_09 dep_2 d-flex flex-wrap g-3 align-items-baseline justify-content-start justify-content-sm-end'>
                <span  class='d-flex g-3 align-items-center justify-content-between pointer cli_1' :data-clipboard-target="'#_' + this.$store.state.userData.id">
                    <span>ID {{this.$store.state.userData.id}}</span> 
                    <i class='item-i copy'></i>
                     <input type='hidden' :id='"_" + this.$store.state.userData.id' :value="this.$store.state.userData.id"/>
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import ClipboardJS from 'clipboard';

export default {
    data() {
        return {
            keyts: this.getKeyts(3),
            idents: this.$store.state.userData.ident,
            active: false,
            class_menu: ''
        }
    },
    mounted() {
        this.class_menu = process.env.VUE_APP_INDIVIDUAL == 'Crypto' ? "keyts-sum_gradient" : "keyts-sum"
        new ClipboardJS('.cli_1');
        // console.log(this.idents)
    },
    methods: {
        getKeyts(c = 0) {
            let arr = Object.entries(this.$store.getters.get_keyts_sum);
            let obj = {};
            if (arr.length < c || c == 0) {
                c = arr.length;
            }
            for (let i = 0; i < c; i++) {
                if (arr[i][1] > 0) {
                    obj[arr[i][0]] = arr[i][1];
                } 
            }
            if (Object.keys(obj).length == 0) {
                for (let i = 0; i < c; i++) {
                    obj[arr[i][0]] = arr[i][1];
                }
            }
            return obj;
        },
        reActive() {
            this.active = !this.active;
            if (this.active) {
                this.keyts = this.getKeyts();
            } else {
                this.keyts = this.getKeyts(3);
            }
        },
        sumMask(sum) {
            let s = sum.replace(/(\d)(?=(\d\d\d)+\b)/ig, '$1  ');
            let arr = s.split('.');
            if (arr[1]) {
                return `<span class='pl-0'>${arr[0]}</span><span class='light'>.${arr[1]}</span>`
            } else {
                return `<span class='pl-0'>${arr[0]}</span>`
            }
        },
    },
}
</script>

<style lang="scss">
.keyts-sum {
    width: 100%;
    background-color: $color-menu-active;
}

.keyts-sum_gradient {
    width: 100%;
    color: #fff;
    border-top: 1px solid #4a7aff;
    background-image: linear-gradient(to right, $color-gradient-start 0%, $color-gradient-end 100%);
}

.dep_2 {
    padding-right: 0.4rem;
    padding-left: 0.4rem;
    // margin-top: 0.4rem;
    min-height: 34px;
    .dep_k3 {
        padding-left: 0 !important;
    }
    .dep_k4 {
        margin-right: 0.4rem;
        padding: 0 !important;
    }
}
@media (max-width: 991px) {
 
}
@media (max-width: 390px) {
    .ident {
        font-size: 0.7rem;
    }
}
</style>