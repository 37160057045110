<template>
    <div>
        <div class=''>
            <h4>{{this.status}}</h4>
        </div>
        <div class='row container-fluid mt-1 p-0 answer_content' v-html="this.content">
        </div>
        <div class='d-flex mx-auto justify-content-center pt-3'>
            <Button name='ОК' type='btn-warning' v-on:click='this.close'/>
        </div>
    </div>
</template>

<script>
import Button from '@/components/service/Button'; 

export default {
    components: {
        Button
    },
    props: {
        close: [Function],
    }, 
    data () {
        return {
            status: this.$store.state.modal.content.result === '0' ? this.$t('components.service.modal.Answer.1') : this.$t('components.service.modal.Answer.2'), 
            content: this.$store.state.modal.content.result_text,
        }
    },
    methods: {
        
    },
}
</script>

<style lang="scss" scoped>
    .answer_content :deep(a) {
            color: $color-href !important;
    }
    
</style>
