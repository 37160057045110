<template>
    <div>
        <div class='d-flex justify-content-between neue mb-2'>
            <h4 v-if="this.title" class="m-0">
                {{ this.title }}
            </h4>
            <h4 v-else-if="!this.currKey" class="m-0">
                {{ this.currDirection == 'curr_give' ? $t('views.P2P.CheckCurrP2POffers.2') : $t('views.P2P.CheckCurrP2POffers.3') }} 
                {{ $t('views.P2P.CheckCurrP2POffers.4') }}</h4>
            <i class='big item-i x_red pointer c24' v-on:click='this.close'></i>
        </div>
        <div class="mb-3 neue-roman">
            <div class="curr-container">
                <div v-if="!this.currKey"
                    class="d-flex flex-column flex-md-row align-items-md-center" 
                    :class="this.content.selectedCurrency.curr_give.code || 
                        this.content.selectedCurrency.curr_recive.code ? 'mb-3' : ''"
                    >
                        <div v-if="this.content.selectedCurrency.curr_give.code || this.content.selectedCurrency.curr_recive.code" class="mr-1">
                            {{ $t('views.P2P.CheckCurrP2POffers.12') }} 
                        </div>
                        <div class="d-flex align-items-center flex-wrap mr-1" v-if="this.content.selectedCurrency.curr_give.code">
                            <span class="grey curr-grey-text">{{ $t('views.P2P.CheckCurrP2POffers.5') }}</span>
                            {{ this.content.selectedCurrency.curr_give.type == 'internal' ? $t('views.P2P.CheckCurrP2POffers.6') : $t('views.P2P.CheckCurrP2POffers.11') }}
                            {{ this.content.selectedCurrency.curr_give.name }}
                            <i class="item-i cross pointer c24" @click="this.removeCurr('curr_give')"></i>
                        </div>
                        <div class="d-flex align-items-center flex-wrap mr-1" v-if="this.content.selectedCurrency.curr_recive.code">
                            <span class="grey curr-grey-text">{{ $t('views.P2P.CheckCurrP2POffers.7') }}</span>
                            {{ this.content.selectedCurrency.curr_recive.type == 'internal' ? $t('views.P2P.CheckCurrP2POffers.6') : $t('views.P2P.CheckCurrP2POffers.11') }}
                            {{ this.content.selectedCurrency.curr_recive.name }}
                            <i class="item-i cross pointer c24" @click="this.removeCurr('curr_recive')"></i>
                        </div>
                </div>
                <div class="input-search-wrapper form-control mb-3">
                    <input class="input-search neue-roman" type="search" @input="this.inputSearchHandler" tabindex="-1" placeholder="Найти..."/>
                </div>
                <div v-if="!this.currKey" class="btn-group col-12 col-md-6 mb-3" role="group" aria-label="Basic outlined example">
                    <Button
                        :name="this.$store.state.resize.w > 768 ? $t('views.P2P.CheckCurrP2POffers.8') : $t('views.P2P.CheckCurrP2POffers.13')" 
                        class='col-12 col-sm-4' 
                        v-on:click="() => {
                            this.of_stage = 1;
                        }"
                        :type='this.of_stage == 1 ? "btn-success" : "btn-outline-success"'
                    />
                    <Button
                        :name="this.$store.state.resize.w > 768 ? $t('views.P2P.CheckCurrP2POffers.10') : $t('views.P2P.CheckCurrP2POffers.14')" class='col-12 col-sm-4' 
                        v-on:click="this.of_stage = 2"
                        :type='this.of_stage == 2 ? "btn-success" : "btn-outline-success"'
                    />
                </div>
                <div class="d-flex justify-content-between curr-wrapper">
                    <div 
                        class="internal-curr col-12" 
                        :class="!this.currKey && this.isTypeCurrencyDisabled(this.content.selectedCurrency.curr_give.type, this.content.selectedCurrency.curr_recive.type, 'internal') ? 'disabled-curr-list' : ''"
                        v-if="this.of_stage == 1 && this.currKey != 'only_external'"
                    >
                        <div v-if="this.filterCurrListInternal.length > 0" class="d-flex flex-wrap col-12">
                            <div
                                v-for="curr in this.filterCurrListInternal"
                                class="d-flex flex-column col-12 col-md-3 p-3 curr-item"
                                :class="this.addActiveClass(this.currKey, 'curr_give', curr.code)" 
                                :key="curr.code"
                                @click="this.choiseCurr(curr.code, curr.full_name, 'internal', this.currDirection)"
                            >
                                <span>{{ curr.full_name }}</span>
                                <span class="fs_08 grey">{{ curr.small_name }}</span>
                            </div>
                        </div>
                        <div v-else class="d-flex col-12">
                            <span class="grey">{{ $t('views.P2P.CheckCurrP2POffers.9') }}</span>
                        </div>
                    </div>
                    <div 
                        class="external-curr col-12" 
                        :class="!this.currKey && this.isTypeCurrencyDisabled(this.content.selectedCurrency.curr_give.type, this.content.selectedCurrency.curr_recive.type, 'external') ? 'disabled-curr-list' : ''"
                        v-if="this.of_stage == 2 || this.currKey == 'only_external'"
                    >
                        <div v-if="this.filterCurrListExternal.length > 0" class="d-flex flex-wrap col-12">
                            <div
                                v-for="currExt in this.filterCurrListExternal"
                                class="d-flex flex-column col-12 col-md-3 p-3 curr-item"
                                :class="this.addActiveClass(this.currKey, 'curr_recive', currExt.code)"
                                :key="currExt.code"
                                @click="this.choiseCurr(currExt.code, currExt.name, 'external', this.currDirection)"
                            >
                                <span>{{ currExt.name }}</span>
                                <span class="fs_08 grey">{{ currExt.curr_text ? currExt.curr_text : currExt.unit }}</span>
                            </div>
                        </div>
                        <div v-else>
                            <span class="grey">{{ $t('views.P2P.CheckCurrP2POffers.9') }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Button from '@/components/service/Button';
    export default {
        components: {
            Button
        },
        props: {
            close: [Function],
        },
        data () {
            return {
                content: this.$store.state.modal.content,
                currKey: this.$store.state.modal.content.currKey,
                title: this.$store.state.modal.content.title,
                fieldId: this.$store.state.modal.content.fieldId,
                currDirection: this.$store.state.modal.content.currencyData.currDirection,
                selectedCurrency: this.$store.state.modal.content.selectedCurrency,
                filterCurrListInternal: [],
                filterCurrListExternal: [],
                internalKeys: ['full_name', 'small_name'],
                externalKeys: ['name', 'curr_text', 'unit'],
                of_stage: '1',
            }
        },
        methods: {
            checkCurrKeys (currency, searchValue, ...keys) {
                return keys.some(key => 
                    currency[key].toLowerCase()
                    .includes(searchValue.toLowerCase()));
            },
            searchByKeys(array, searchValue, ...keys) {
                const arrayFilter = array.filter(currency => 
                    this.checkCurrKeys(currency, searchValue, ...keys)
                );

                return arrayFilter;
            },
            inputSearchHandler(event) {
                const searchValue = event.target.value;

                if (this.currKey == 'only_internal' || !this.currKey) {
                    const searchInternalResult = this.searchByKeys(this.content.currencyData.currListInternal, searchValue, ...this.internalKeys);
                    this.filterCurrListInternal = [...searchInternalResult];
                }

                if (this.currKey == 'only_external' || !this.currKey) {
                    const searchExternalResult = this.searchByKeys(this.content.currencyData.currListExternal, searchValue, ...this.externalKeys);
                    this.filterCurrListExternal = [...searchExternalResult];
                }
            },
            isTypeCurrencyDisabled(giveCurrType, reciveCurrType, blockCurrType) {
                return giveCurrType == blockCurrType || reciveCurrType == blockCurrType;
            },
            addActiveClass(currKey, currDirection, currCode) {
                if (!currKey && this.selectedCurrency[currDirection].code == currCode) {
                    return 'curr-item-selected';
                }

                if (currKey && 
                    (this.selectedCurrency.curr.code == currCode && this.selectedCurrency.curr.typeCurr == this.fieldId || 
                        this.selectedCurrency.external_type.code == currCode && this.selectedCurrency.external_type.typeCurr == this.fieldId || 
                        this.selectedCurrency.external_type_curr.code == currCode && this.selectedCurrency.external_type_curr.typeCurr == this.fieldId)
                ) {
                    return 'curr-item-selected';
                }
            },
            choiseCurr(currCode, currName, currType, currDirection) {
                if (!this.currKey && (this.content.selectedCurrency.curr_give.type == currType ||
                    this.content.selectedCurrency.curr_recive.type == currType)
                ) {
                    return;
                }

                // currType: internal/external, currDirection: fieldId (curr_give, curr_recieve, etc)
                this.content.updateCurr(currCode, currName, currType, currDirection);

                this.close();
            },
            removeCurr(currDirection) {
                this.content.clearCurr(currDirection);
                if (currDirection == 'curr_give') {
                    this.content.selectedCurrency.curr_give = {};
                }

                if (currDirection == 'curr_recive') {
                    this.content.selectedCurrency.curr_recive = {};
                }
            }
        },
        mounted() {
            console.log('mounted', this.currKey);
            // если передан ключ - работа только с одним списком, если ключ не передан - с двумя
            if (this.currKey == 'only_internal' || !this.currKey) {
                this.filterCurrListInternal = [...this.content.currencyData.currListInternal];
            }
            
            if (this.currKey == 'only_external' || !this.currKey) {
                this.filterCurrListExternal = [...this.content.currencyData.currListExternal];
            }
        }
    }
</script>

<style lang="scss">
    .curr-container {
        max-height: 60vh;

        @media (max-width: 767px) {
            max-height: 60vh;
        }
    }
    .curr-wrapper {
        max-height: 45vh;
        overflow-y: auto;

        &::-webkit-scrollbar {
            width: 6px;
            border-radius: 20px;
        }

        &::-webkit-scrollbar-track {
            background: white;
            border-radius: 20px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: $color-menu;
            border-radius: 20px;
            border: 1px solid white;
        }

        @media (max-width: 767px) {
            max-height: 32vh;
        }
    }

    .input-search-wrapper {
        display: flex;
        padding-left: 35px;
        width: 100%;
        background-image: url('~@/assets/icons/search.svg');
        background-repeat: no-repeat;
        background-size: 20px 20px;
        background-position: 8px 7px;
    }

    .input-search {
        width: calc(100% - 20px);
        border: none;
    }

    .input-search:focus,
    .input-search:focus-visible {
        border: none;
        outline: none;
    }

    .curr-grey-text {
        margin-right: 4px;
    }

    .curr-item {
        border-radius: 12px;
        cursor: pointer;
    }

    .curr-item:hover,
    .curr-item:focus,
    .curr-item:active,
    .curr-item-selected {
        background: $color-lightgrey;
    }

    .disabled-curr-list {
        filter: saturate(10%);
        opacity: 0.8;
    }

    .disabled-curr-list .curr-item {
        cursor: initial;
    }

    .disabled-curr-list .curr-item:hover,
    .disabled-curr-list .curr-item:focus,
    .disabled-curr-list .curr-item:active {
        background: transparent;
    }

    .disabled-curr-list .curr-item-selected,
    .disabled-curr-list .curr-item-selected:hover,
    .disabled-curr-list .curr-item-selected:focus,
    .disabled-curr-list .curr-item-selected:active {
        background: $color-lightgrey;
    }
</style>