<template>
  <div class="d-flex flex-row align-items-center justify-content-center gap-3 flex-wrap p-2">
    <Button
      v-for="(lang, idx) in langs"
      :class="{
        'item-active': lang.shortName == this.lang.shortName,
        'item': lang.shortName != this.lang.shortName,
      }"
      :key="idx"
      :name="lang.shortName.toUpperCase()"
      v-on:click.prevent="setLocale(lang)"
      type="btn-sm"
    />
  </div>
</template>

<script>
import Button from "@/components/service/Button";
import Dropdown from "@/components/service/Dropdown";
// import Dropdown from 'primevue/dropdown';

export default {
  name: "LanguageSwitcher",
  components: {
    Button,
    Dropdown,
  },
  props: {
    type: [String],
  },
  data() {
    return {
      isVisible: false,
      focusedIndex: 0,
      selectedLang: false,
      lang: {},
      langs: [
        {
          shortName: "en",
          longName: "English",
          localName: "English",
        },
        {
          shortName: "ru",
          longName: "Russian",
          localName: "Русский",
        },
        {
          shortName: "de",
          longName: "Deutsch",
          localName: "Deutsch",
        },
        {
          shortName: "fr",
          longName: "Français",
          localName: "Français",
        },
        {
          shortName: "cs",
          longName: "Český",
          localName: "Český",
        },
        {
          shortName: "kk",
          longName: "Қазақша",
          localName: "Қазақша",
        },
        {
          shortName: "pl",
          longName: "Polski",
          localName: "Polski",
        },
        {
          shortName: "zh",
          longName: "俄语",
          localName: "俄语",
        },
      ],
      dataDropMenu: [],
    };
  },
  created() {
    // console.log(this.$store.state.config.LOCALES)
    let l = this.$store.state.config.LOCALES;
    if (!l) {
      l = [];
    }
    if (l.length > 0) {
      this.langs = l;
    }
    if (this.langs.length > 3) {
      this.setDropdownList();
    }
    this.getLang();
  },
  methods: {
    getLang() {
      this.langs.forEach((e) => {
        // shortName
        if (e.shortName == this.$i18n.locale) {
          this.lang = e;
        }
      });
    },
    setDropdownList() {
      let list = [];
      this.langs.forEach((e) => {
        list.push({ name: e.shortName.toUpperCase(), handler: () => this.setLocale(e) });
      });
      this.dataDropMenu = list;
    },
    setLocale(lang) {
      this.$i18n.locale = lang.shortName;
      localStorage.setItem("lang", lang.shortName);
      this.lang = lang;
      this.$store.dispatch("reRender");
    },
  },
};
</script>

<style lang="scss" scoped>
.item {
  cursor: pointer;
  color: $menu-base;
  border: 1px solid $menu-border;
  border-radius: 8px;
  padding: 5px;
  width: 35px;
  height: 35px;

  &:hover {
    color: $menu-active;
    background-color: $menu-bg-active;
    border: 1px solid $menu-active;
  }
}

.item-active {
  @extend .item;
  color: $menu-active;
  background-color: $menu-bg-active;
  border: 1px solid $menu-active;
}
</style>
