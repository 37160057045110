<template>
    <div>
        <div class='d-flex justify-content-between neue mb-2'>
            <h4 class="color-menu">{{ `Сделка № ${this.swap.code}`}}</h4>
            <i class='item-i pointer contain c24 back' v-on:click='this.goBack()'></i>
        </div>

        <div class="mt-3 d-flex flex-column flex-md-row align-items-start justify-content-between">

        <div class="neue-roman col-12 col-md-4 mr-1">
            <p>{{ this.swap.status_text }}</p>
            <div class="d-flex flex-row align-items-center justify-content-between">
                <div class="">
                    <p>{{ $t('views.P2P.SingleSwap.1') }}</p>
                    <div>
                        <span>
                            {{ this.user_pos == 'internal' ? this.swap.internal_amount : this.swap.external_amount }}
                        </span>
                        &nbsp;
                        <span class="grey fs_08">
                            {{ this.user_pos == 'internal' ? this.swap.internal_curr_text : this.swap.external_type_text }}
                        </span>
                    </div>
                </div>
                <div class="mr-2">
                    <p>{{ $t('views.P2P.SingleSwap.2') }}</p>
                    <div>
                        <span>
                            {{ this.user_pos == 'internal' ? this.swap.external_amount : this.swap.internal_amount }}
                        </span>
                        &nbsp;
                        <span class="grey fs_08">
                            {{ this.user_pos == 'internal' ? this.swap.external_type_text : this.swap.internal_curr_text }}
                        </span>
                    </div>
                </div>
            </div>

            <div v-if="this.cancel">
                <Input type='textarea' :dataValue='this.cancel_comment' :label="$t('views.P2P.SingleSwap.3')" id='cancel_comment' @updateParentState="this.updateState"/>
                <Select :label="$t('views.P2P.SingleSwap.4')" :require="true"  id='cancel_reason' :dataValue='this.cancel_reason' :keywords.sync='this.cancel_reasons' @updateParentState="this.updateState" />
            
                <div class="col-12 col-md-8 mt-4 m-auto d-flex align-items-center justify-content-center">
                    <button class="bg-transparent mr-2 border-0" v-on:click="this.goToLastMenu">
                        <i class="item-i pointer contain c24 back"></i>
                    </button>
                    <Button :name="$t('views.P2P.SingleSwap.5')" type='btn-warning' v-on:click="this.cancelSwap"/>
                </div>
            </div>


            <div v-if="this.user_status == 'maker'">
                <div class="col-12 col-md-8 mt-4 m-auto d-flex flex-column align-items-center justify-content-between">
                    <Button
                        v-if="Number(this.swap.status) >= 10  && Number(this.swap.status) < 20"
                        :name="$t('views.P2P.SingleSwap.6')"
                        type='btn-warning'
                        v-on:click="this.agreeSwap"
                    />
                    <Button v-if="Number(this.swap.status) < 100" :name="$t('views.P2P.SingleSwap.7')" class="mt-2" type='btn-outline-warning' v-on:click="this.cancel = true"/>
                </div>
            </div>

            <div v-else-if="this.user_status == 'taker' && Number(this.swap.status) < 100">
                <div v-if="!this.cancel" class="col-12 col-md-8 mt-4 m-auto d-flex flex-column align-items-center justify-content-between">
                    <Button :name="$t('views.P2P.SingleSwap.8')"  type='btn-outline-warning' v-on:click="this.prolongateSwap"/>
                    <Button :name="$t('views.P2P.SingleSwap.9')" class="mt-2" type='btn-outline-warning' v-on:click="this.cancel = true"/>
                </div>
            </div>

            <div v-else>
                <p>{{ $t('views.P2P.SingleSwap.10') }}</p>
            </div>

            <div v-if="this.user_pos == 'internal' && this.swap.status == this.statuses['wait_trasfer']">
                <div v-if="this.swap.status == '20' && !this.cancel" class="col-12 col-md-9 mt-2 m-auto d-flex flex-column align-items-center justify-content-between">
                    <Button
                        :name="$t('views.P2P.SingleSwap.13')"
                        v-on:click="this.extnoticeSwap" 
                        type='btn-warning'
                    />
                </div>
            </div>
            <div v-if="this.user_pos == 'external' && this.swap.status == '30'" class="col-12 col-md-9 mt-2 m-auto d-flex flex-column align-items-center justify-content-between">
                <Button
                    :name="$t('views.P2P.SingleSwap.14')"
                    type='btn-warning'
                    v-on:click="this.confirmOperation"
                />
            </div>

            <div v-if="this.swap.status >= 100" class="col-12 col-md-11 mt-2 d-flex flex-column justify-content-between">
                <h4 class="mb-2 p-0 color-menu">{{ $t('views.P2P.SingleSwap.15') }}</h4>
                <star-rating
                    v-model:rating="this.rating"
                    :show-rating="false"
                    :star-size="30"
                    active-color="#FF7F11"
                    :rounded-corners="true" 
                    @setRating="setRating"
                ></star-rating>
                <label class="col-form-label" for="comment">{{ $t('views.P2P.SingleSwap.3') }}</label>
                <textarea class="form-control mb-3" id="comment" v-model="rating_comment"></textarea>
                <Button
                    :name="$t('views.P2P.SingleSwap.16')"
                    type='btn-outline-warning'
                    v-on:click="this.sendRating"
                />
            </div>

        </div>

        <P2PChat :topic="this.swap" @updateSwapStatus="this.updateSwapStatus" class="col-12 col-md-8"/>

        </div>
        
    </div>
</template>

<script>
import Button from '@/components/service/Button'; 
import Select from '@/components/service/Select';
import Input from '@/components/service/Input';
import Switch from '@/components/service/Switch';
import InputSimple from '@/components/service/InputSimple';
import SwitchSimple from '@/components/service/SwitchSimple';
import Popper from '@/components/service/Popper';
import P2PChat from '@/components/service/p2p/P2PChat';
import StarRating from 'vue-star-rating';

export default {
    components: {
        Button,
        Select,
        Input,
        Switch,
        InputSimple,
        SwitchSimple,
        Popper,
        P2PChat,
        StarRating,
    },
    props: {
        content: [Object], 
    }, 
    data () {
        return { 
            cancel: false,
            cancel_reasons: [],
            cancel_reason: '',
            cancel_comment: '',
            swap: this.content,
            user_status: this.$store.state.userData.id == this.content.maker_uid ? 'maker' : 'taker',
            user_pos: this.$store.state.userData.id == this.content.internal_recipient_uid ? 'internal' : 'external',
            statuses: {
                'wait_confirm': '10', //Подана заявка, ожидается подтверждение мейкера
                'wait_trasfer': '20',  //Мейкер подтвердил сделку, создана транзакция (в обработке), ожидается внешний перевод
                'send_transfer': '30', //Отправитель внешнего перевода сообщил о его отправке
                'confirm_transfer': '40', //Получатель внешнего перевода подтвердил его получение (примечание: вообще-то подтверждение сразу вызывает завершение транзакции и перевод в статус 100, но если подтверждение тр. вдруг не отрабатывает, то подтверждение получения все равно должно быть зафиксировано)
                'arbitr_request': '50', //Одна из сторон запросила арбитраж
                'arbitr_progress': '60', //Идет арбитраж
                'swap_success': '100', //Сделка успешно завершена (финальный статус)
                'swap_cancel': '130' //Сделка отменена (финальный статус)
            },
            rating: '5',
            rating_comment: ''
        }
    },
    mounted() {
        console.log('swap swap swap swap swpasppawppasp');
        console.log(this.swap);
        console.log('swap swap swap swap swpasppawppasp ');
        this.axios.post('GetKeywords', {group: '151'}).then(res => {
                this.cancel_reasons = res.data.body;
            })
    },
    methods: {
        updateState(state) {
            this[state.id] = state.value;
        },
        agreeSwap() {
            this.$store.commit('MODAL', {
                action: true,
                type: 'pay_pass',
                content: {callback: () => {
                    this.axios.post('AgreeP2PSwap', {data: {swap: this.swap.code}}).then(res => {
                        console.log('agreeSwap', this.swap.status);
                    })
                }   
                },
            });
        },
        cancelSwap() {
            this.axios.post('CancelP2PSwap', {data: {swap: this.swap.code, reason: this.cancel_reason}}).then(res => {
                
            })
        },
        goToLastMenu() {
            this.cancel = false;
        },
        prolongateSwap() {
            this.axios.post('ProlongateP2PSwap', {data: {swap: this.swap.code, addition: '200'}}).then(res => {
                
            })
        },
        requestArbitrage() {
            this.axios.post('RequestP2PArbitrage', {data: {swap: this.swap.code}});
        },
        updateSwapStatus() {
            this.axios.post('GetparamsP2PSwap', {data: {swap: this.swap.code}}).then(res => {
                if (res.data.body.result !== '0') {
                    this.error_text = res.data.body.result_text;     
                    return;
                }
                this.swap = Object.assign(this.swap, res.data.body.advanced);
                console.log('swap swap swap swap swpasppawppasp ');
                console.log(this.swap);
                console.log('swap swap swap swap swpasppawppasp ');
            });
        },
        extnoticeSwap() {
            this.axios.post('ExtnoticeP2PSwap', {data: {swap: this.swap.code}});
        },
        goBack() {
            this.$emit('GoBack');
        },
        confirmOperation() {
            this.axios.post('CompleteP2PSwap', {data: {swap: this.swap.code}});
        },
        sendRating() {
            console.log('swap status', this.swap.status);
            console.log('user status', this.user_status);
            this.rating = Math.floor(this.rating);
            console.log('sendRating', this.rating);

            this.axios.post('RateP2PSwap', {data: 
                {swap: this.swap.code, rate: this.rating, comment: this.rating_comment}
            });
        }
    }
}
</script>