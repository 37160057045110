<template>
    <div>
        <div class=''>
            <h4>{{this.content.text}}</h4>
        </div>
        <div class=''>
            <Input type='text' :label="$t('components.service.modal.Template.1')"
             autofocus 
             :autocomplete="'off'" 
             :dataValue="this.new_name"
             id='name'
             @updateParentState="this.updateState" hint=''
             @onEnter="this.submit"/>
        </div>
        <div class='d-flex mx-auto justify-content-center pt-3'>
            <Button :name="$t('components.service.modal.PayPass.2')" class='mr-1' type='btn-outline-warning' v-on:click='this.close'/>
            <Button :name="$t('components.service.modal.PayPass.3')" type='btn-warning' v-on:click='this.submit'/>
        </div>
    </div>
</template>

<script>
import Button from '@/components/service/Button';
import Input from '@/components/service/Input';

export default {
    components: {
        Button,
        Input
    },
    props: {
        close: [Function],
    },
    data () {
        return {
            content: this.$store.state.modal.content,
            new_name: this.$store.state.modal.content.name,
        }
    },
    methods: {
        updateState(state) {
            this.new_name = state.value;
        },
        submit() {
            this.$store.commit('MODAL', {
                        action: false,
                        type: false,
                        content: false
                    })
            this.$store.commit('DARK_WRAPPER', false);
            this.content.callback(this.new_name);
        }
    },
}
</script>