<template>
    <div class="row container-fluid mt-1 p-0 g-3 align-items-center input">
        <div class="col-sm-3 p-0">
            <label :for="this.id" class="col-form-label input-label">{{this.label}}<span class='require' v-if="this.require"> *</span></label>
        </div>
        <div class="col-sm-9 input-wrapper p-0">
            <div v-if="this.arr" class="input-filecontainer wid"> 
                <div v-if="this.files.length > 0" class="f1 d-flex flex-wrap align-items-end justify-content-start">
                    <div v-for="(file, index) in this.files" :key='index' class="f2 mr-2">
                        <div class="d-flex flex-wrap justify-content-start" :class="{'flex-column': file.image, 'flex-row align-items-center': !file.image }">
                            <div v-if="file.image">
                                <img :class="{'border-r': this.border}" :src='file.image'>
                            </div>
                            <div class="pr-1" v-else>
                                <i class='item-i contain c24' :class='"file_download"'></i>
                            </div>
 
                            <div v-if="file.name">
                                <span>{{file.name}}</span>
                            </div>

                            <div v-if="file && !this.disabled">
                                <label v-on:click="this.deleteFile(index)">{{ $t('components.service.File.11') }}</label>
                            </div>
                        </div>
                        <div v-if="!this.disabled">
                            <label :for='"index_" + index  + "_" + this.id' :class="{'dis_file': this.disabled}">{{ $t('components.service.File.1') }}</label>
                        </div>
                        <input class='input-filecontainer-input'  :id="'index_' + index  + '_' + this.id" type='file' :disabled='this.disabled'  v-on:input="this.getFile(index)">
                    </div>
                    <div v-if="!this.single" class="f2 mt-3">
                        <div class="d-flex flex-wrap justify-content-start" >
                        </div>
                        <div>
                            <label :for='"index_" + this.files.length + "_" + this.id' :class="{'dis_file': this.disabled}">{{ $t('components.service.File.1') }}</label>
                        </div>
                        <input class='input-filecontainer-input' :id="'index_' + this.files.length  + '_' + this.id" type='file' :disabled='this.disabled'  v-on:input="this.getFile(this.files.length)">
                    </div>
                </div>
                <div v-else>
                    <div v-if="this.disabled" class="d-flex flex-wrap justify-content-start" :class="{'flex-column': file.image, 'flex-row align-items-center': !file.image }">
                        <div class="pr-1">
                            <i class='item-i contain c24' :class='"file_download"'></i>
                        </div>
                    </div>
                    <div v-else>
                        <label :for="'index_' + this.files.length   + '_' + this.id" :class="{'dis_file': this.disabled}">{{ $t('components.service.File.1') }}</label>
                    </div>
                    <input class='input-filecontainer-input' :id="'index_' + this.files.length  + '_' + this.id" :disabled='this.disabled'  type='file' v-on:input="this.getFile(this.files.length)">
                </div>
            </div>
            <div v-else class="input-filecontainer wid">
                <div v-if="this.file" class="d-flex flex-wrap justify-content-start" :class="{'flex-column': this.image, 'flex-row align-items-center': !this.image }">
                    <div v-if="this.image">
                        <img :class="{'border-r': this.border}" :src='this.image'>
                    </div>
                    <div class="pr-1" v-else>
                        <i class='item-i contain c24' :class='"file_download"'></i>
                    </div>
 
                    <div v-if="this.name">
                        <span>{{this.name}}</span>
                    </div>
                </div>
                <div>
                    <label :for='id' :class="{'dis_file': this.disabled}">{{ $t('components.service.File.1') }}</label>
                </div>
                <input class='input-filecontainer-input' :disabled='this.disabled'  :id="id" type='file' v-on:input="this.getFile">
            </div>
            <div class="d-flex flex-row align-items-center justify-content-end">
                <Popper v-if="this.hint" class="bs-tooltip" :content='this.hint'/>
                <div v-else-if="this.unvisible" class="mw34"></div>
                <Popper v-if="this.verification" :custom="'item-i check'" :content="$t('views.InfoReg.3')"/>
                <div v-else-if="this.unvisible || this.vMark" class="mw34"></div>
                <SlotPopper v-if="this.unvisible"  :content="$t('views.InfoReg.4')">
                    <SwitchSimple :id="id" :dataValue='this.unvisibleValue' :disabled="this.is_public == '2' ? true : false" :type="'sm'" class="ml-1" @updateParentState="this.setUnvisibleValue"/>
                </SlotPopper>
            </div>
        </div>
    </div> 
</template>

<script>
import Lib from '@/includes/lib.js';
import SwitchSimple from '@/components/service/SwitchSimple';
import Popper from '@/components/service/Popper';
import SlotPopper from '@/components/service/SlotPopper';

export default {
    components: {
        Popper,
        SlotPopper,
        SwitchSimple
    },
    data() {
        return {
            file: this.dataValue && !this.arr ? this.getDataValue(this.dataValue, this.fileName ? this.fileName : 'n') : '',
            unvisibleValue: this.unvisibleDataValue ? this.unvisibleDataValue : '',
            files: [],
            image: '',
            type: '',
            name: this.fileName ? this.fileName : false,
            knownFormats: [
                'image/jpeg', 'image/jpg', 'image/png', 'application/pdf', "application/vnd.ms-excel", 
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "text/csv", 'image/svg+xml',
                "text/xml", 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                "application/vnd.openxmlformats-officedocument.wordprocessingml.template", "application/vnd.ms-word.document.macroEnabled.12",
            ]
        }
    },
    props: {
        id: [String, Boolean],
        label: [String],
        arr: {
            type: [Boolean],
            default: false,
        },
        needBin: {
            type: [Boolean],
            default: false,
        },
        returnFileObject: [Boolean],
        single: [Boolean],
        hint: [String],
        dataValue: [String, Boolean, Object],
        fileName: [String, Boolean],
        require: [Boolean],
        verification: [String, Boolean],
        formats: [Array, Boolean],
        border: [String, Boolean],
        unvisible: [Boolean],
        disabled: [String, Boolean], 
        unvisibleDataValue: [String, Boolean], 
        is_public: [Boolean, String],
        vMark: [Boolean],
        maxSize: [Number, Boolean],

    },
    created() {
        if (this.file) {
            this.convertFile();
        }
        if (this.arr && this.dataValue) {
            this.files = this.dataValue;
            this.getDataValue(this.dataValue);
            // console.log('this.dataValue', this.dataValue)
        }
    },  
    mounted() {
    },
    methods: {
        getDataValue(val, name = 'undefined_name') {
            if (this.arr) {
                // console.log(this.files);
                for(let i = 0; i < this.files.length; i++) {
                    if (this.files[i].file_link) {
                        this.files[i].image = this.files[i].body;
                        this.files[i].name_base64 = this.files[i].name;
                        try {
                            this.files[i].name = atob(this.files[i].name); 
                        } catch(err) {
                            this.files[i].name = this.files[i].name; 
                        }
                    } else if (!this.files[i].image) {
                        this.getBase64Arr(this.objectToFile(this.files[i]), i);
                    }
                }
            } else {
                if (typeof val == 'object') {
                    return this.objectToFile(val);
                } else if (typeof val == 'string') {
                    return this.dataURLtoFile(val, name);
                } else {
                    return '';
                } 
            }
        },
        setUnvisibleValue(arg) {
            this.$emit('updateParentState', {
                id: arg.id,
                unvisible: true,
                value: arg.value
            })
        },
        getFile(id) {
            if (this.arr) {
                let file = document.querySelector(`#index_${id}_${this.id}`).files[0];
                if (this.maxSize && (file.size / (1024 * 1024) > this.maxSize )) {
                    alert(this.$t('components.service.File.4') + ' ' + this.maxSize + ' MB');
                    file.value = '';
                    return;
                }
                // console.log(file)
                if (this.checkFormat(file)) {
                    this.convertFileInArray(file, id);
                }
            } else {
                this.file = document.querySelector(`#${this.id}`).files[0];
                if (this.maxSize && (file.size / (1024 * 1024) > this.maxSize )) {
                    alert(this.$t('components.service.File.4') + ' ' + this.maxSize + ' MB');
                    file.value = '';
                    return;
                }
                this.type = '';
                this.image = false;
                if (this.checkFormat()) {
                    this.convertFile();
                } else {
                    this.file = '';
                }
            }
        },
        checkFormat(file = this.file) {
            if (this.formats) {
                let ok = false;
                let warn = this.$t('components.service.File.2');
                this.formats.forEach(e => {
                    if (e == file.type) {
                        ok = true;
                    }
                    warn = warn + ' ' + e;
                })
                if (!ok) {
                    alert(warn);
                    return false
                }
                return true;


            } else {
                return true;
            }
        },
        // Потом нужно убрать как будут массивы
        convertFile() {
            if (this.file.type == 'image/jpeg' || this.file.type == 'image/jpg' || this.file.type == 'image/png' || this.file.type == 'image/svg+xml') {
                this.type = this.file.type;
                this.getBase64()
            } else if (this.file.type == 'application/pdf') {
                this.type = this.file.type;
                this.name = this.file.name;
                this.getBase64()
            }else {
                alert(`${this.$t('components.service.File.3')} ${this.file.type}`) 
            }
        },
        convertFileInArray(file, id) {
            if (Lib.inArray(file.type, this.knownFormats)) {
                this.getBase64Arr(file, id);
            } else {
                alert(`${this.$t('components.service.File.3')} ${file.type}`) 
            }
        },
        getBase64Arr(file, i) {
            var reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                let res = {};
                res.name = file.name;
                res.type = file.type;
                res.size = file.size;
                if (this.needBin) {
                    res.full_result = reader.result;
                }
                if (this.returnFileObject) {
                    res.fileObject = document.querySelector(`#index_${i}`).files
                }
                res.body = reader.result.split(',')[1];
                if (Lib.inArray(file.type, ['image/jpeg', 'image/jpg', 'image/png', 'image/svg+xml'])) {
                    res.image =  reader.result;
                } else {
                    res.image =  '';
                }
                // this.setValue();
                if (this.single) {
                    this.files = [res];
                } else {
                    this.files[i] = res;
                }
                this.setArrValue({id: this.id, files: this.files});
            };
            reader.onerror = function (error) {
                alert(error);
                return false;
                
            };
        },
        deleteFile(ind) {
            this.files.splice(ind, 1);
            this.setArrValue({id: this.id, files: this.files});
        },
        getBase64() {
            var reader = new FileReader();
            reader.readAsDataURL(this.file);
            reader.onload = () => {
                this.file = reader.result;
                if (this.type == 'application/pdf') {
                    this.image = ''
                } else {
                    this.image = reader.result;
                }
                this.setValue();
            };
            reader.onerror = function (error) {
                this.file = false;
                this.image = false; 
                this.setValue(); 
            };
        },
        dataURLtoFile(dataurl, filename) {
            // console.log(dataurl);
            var arr = dataurl.split(',');
            var mime = arr[0].match(/:(.*?);/)[1];
            var bstr = atob(arr[1]);
            var n = bstr.length;
            u8arr = new Uint8Array(n);
     
            while(n--){
                u8arr[n] = bstr.charCodeAt(n);
            }
 
            return new File([u8arr], filename, {type:mime});
        },
        objectToFile(obj) {
            let mime = obj.mime,
            bstr = atob(obj.body),
            n = bstr.length,
            u8arr = new Uint8Array(n);
     
            while(n--){
                u8arr[n] = bstr.charCodeAt(n);
            }
 
            return new File([u8arr], obj.name, {type:mime});
        },
        setValue() {
            this.$emit('updateParentState', {
                id: this.id,
                value: this.file,
            })
        },
        setArrValue(arg) {
            this.$emit('updateParentState', {
                id: arg.id,
                value: arg.files,
            })
        }
    },

}
</script>

<style lang="scss" scoped>
.input {
    font-family: 'HelveticaNeue roman';
    &-wrapper {
        position: relative;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: space-between;
    }
    &-filecontainer {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: center;
        label {
            color: $color-href;
            cursor: pointer;
        }
        img {
            max-width: 10rem;
        }
        &-input {
            display: none;
        }

    }
}
.border-r {
    border-radius: 0.5rem !important;
}
.dis_file {
    color: $color-grey !important;
    cursor: default !important;
}
</style>