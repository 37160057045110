<template>
    <div>
        <div class=''>
            <h4>{{ $t('components.service.modal.Confirm.1') }}</h4>
        </div>
        <div class=''>
            <h5 class='p-0'>{{this.content.text}}</h5>
        </div>
        <div class='d-flex mx-auto justify-content-center pt-3'>
            <Button :name="this.content.btnCancel ? this.content.btnCancel : $t('components.service.modal.Confirm.2')" class='mr-1' type='btn-outline-warning' 
            v-on:click='() => {
                if (this.$store.state.modal.content.onCancel) {
                    this.$store.state.modal.content.onCancel();
                }
                this.close();
            }'/>
            <Button :name="this.content.btnConfirm ? this.content.btnConfirm : $t('components.service.modal.Confirm.3')" type='btn-warning' v-on:click='this.submit'/>
        </div>
    </div>
</template>

<script>
import Button from '@/components/service/Button';
import Input from '@/components/service/Input';

export default {
    components: {
        Button,
        Input
    },
    props: {
        close: [Function],
    },
    data () {
        return {
            content: this.$store.state.modal.content,
        }
    },
    methods: {
        submit() {
            let callback = this.content.callback;
            this.$store.commit('MODAL', {
                        action: false,
                        type: false,
                        content: false
                    })
            this.$store.commit('DARK_WRAPPER', false);
            callback();
        }
    },
}
</script>