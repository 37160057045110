<template>
    <div>
        <div class='d-flex justify-content-between neue mb-2'>
            <h4>{{ this.$t('views.ATMIncassStatuses.1') }}</h4>
            <i class='big item-i x_red pointer' v-on:click='this.close'></i>
        </div>
        <InputsRadioGroups
            name='status_order' 
            id='status_order'
            :values="this.radioNames"
            :dataValue="this.status"
            :defaultValue="this.oldStatus"
            @updateParentState="this.setValue"
        />
        <div class="col-12 col-md-3 mt-4 m-auto d-flex flex-column flex-md-row align-items-center justify-content-between">
            <Button
                :name="this.$t('views.ATM.11')"
                class="col-12 col-sm-6"
                :class='{"mr-1" : this.$store.state.resize.w >= 768, "mb-2" : this.$store.state.resize.w < 768}'
                type='btn-outline-warning'
                @click="this.close"
                :style='"min-width: 108px;"'
            />
            <Button
                :name="this.$t('views.ATM.12')"
                class="col-12 col-sm-6"
                type='btn-warning'
                @click="setStatus()"
                :style='"min-width: 108px;"'
            />
        </div>
    </div>
</template>

<script>
    import Button from '@/components/service/Button';
    import InputsRadioGroups from '@/components/service/InputsRadioGroups';

    export default {
        components: {
            Button,
            InputsRadioGroups
        },
        props: {
            close: [Function],
        },
        data() {
            return {
                content: this.$store.state.modal.content,
                oldStatus: this.$store.state.modal.content.orderStatus,
                radioNames: [
                    {
                        value: 10,
                        text: this.$t('views.ATMIncassStatuses.2'),
                        disabled: true,
                    },
                    {
                        value: 15,
                        text: this.$t('views.ATMIncassStatuses.3'),
                    },
                    {
                        value: 20,
                        text: this.$t('views.ATMIncassStatuses.4'),
                    },
                    {
                        value: 30,
                        text: this.$t('views.ATMIncassStatuses.5'),
                    },
                    {
                        value: 35,
                        text: this.$t('views.ATMIncassStatuses.6'),
                    },
                    {
                        value: 40,
                        text: this.$t('views.ATMIncassStatuses.7'),
                        disabled: true,
                    },
                    {
                        value: 45,
                        text: this.$t('views.ATMIncassStatuses.8'),
                        disabled: true,
                    },
                    {
                        value: 50,
                        text: this.$t('views.ATMIncassStatuses.9'),
                        disabled: true,
                    },
                    {
                        value: 60,
                        text: this.$t('views.ATMIncassStatuses.10'),
                        disabled: true,
                    },
                    
                ],
                status: 10,
            }
        },
        methods: {
            setValue(arg) {
                this.status = arg.value;
            },
            setStatus() {
                this.axios.post('SetparamsIncassAtmOrderStatus', {data: {
                    group_status: this.status, 
                    incass_atm: Number(this.content.numberPoint)
                }}).then(res => {
                    if (res.data.body.result == '0') {
                        console.log('status update');
                        this.content.updateOrders();
                        this.close();
                    }
                });
            }
        },
    }
</script>