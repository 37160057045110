<template>
    <div class="d-flex" :class='{"flex-row justify-content-sm-between justify-content-md-start" : this.isHorizontal, "flex-column": !this.isHorizontal}'>
        <div 
            v-for="(item, index) in this.values" 
            :key="index" 
            class="form-check"
            :class='{"form-check-radio": !this.isHorizontal, "col-sm-3" : this.isHorizontal}'
        >
            <input 
                class="form-check-input input-radio" 
                :id="item.value" 
                type="radio" 
                :name="this.name" 
                :value="item.value" 
                :v-model="item.value"
                :checked="item.value == defaultValue"
                :disabled="item.disabled == true"
                @input="() => this.setValue(item.value)"
            >
            <label class="form-check-label" :for="item.value">
                {{ item.text }}
            </label>
        </div>
    </div>
</template>

<script>

export default {
    data() {
        return {
            value: this.dataValue ? this.dataValue : this.defaultValue,
        }
    },
    props: {
        name: [String, Boolean],
        values: [Array],
        id: [String, Boolean],
        defaultValue: [Number, Boolean, String],
        dataValue: {
            default: false,
            type: [Number, Boolean, String],
        },
        isHorizontal: [Boolean]
    },
    methods: {
        setValue(arg) {
            // console.log('setValue', arg);
            this.value = arg;
            this.$emit('updateParentState', {
                id: this.id,
                value: this.value,
            });
        }
    },
}

</script>

<style lang="scss">
    .input-radio.form-check-input:checked {
        background-color: $color-menu;
        border-color: $color-menu;
    }

    .form-check-radio {
        margin-bottom: 0.5rem;
    }

    .form-check-radio:last-child {
        margin-bottom: 0;
    }
</style>