export default {
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қазақша"])},
  "views": {
    "Login": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кіру"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Логин:"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Құпиясөз:"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Құпиясөзді ұмыттыңыз ба?"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Алғаш рет мұндасыз ба?"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аккаунт жасаңыз"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Құпиясөзді қалпына келтіру"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Телефон немесе e-mail:"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қалпына келтіру"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Құпиясөзді қалпына келтіру"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тегі:"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аты:"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Әкесінің аты:"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Туған күні:"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кіру"])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Артқа"])},
      "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жалғастыру"])},
      "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Іздеу"])},
      "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пайдаланушы табылды"])}
    },
    "History": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кезеңі"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кезеңі"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Экспорт"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сүзгілер"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қалпына келтіру"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жаңарту"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сома"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Транзакция"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Параметр"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Түрі"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Транзакция"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Күні"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Операция"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Корреспондент"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сома"])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
      "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Параметрлер/Түсініктеме"])},
      "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Операциялар табылмады"])},
      "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Операция сомасы"])},
      "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шотқа түсетін түсімдер:"])},
      "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" дана, жалпы сомасы"])},
      "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шығындар:"])},
      "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Операция түрлері"])},
      "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Төлемдер, қызметтерді төлеу"])},
      "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ішкі аударымдар"])},
      "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шотты төлеу"])},
      "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Өз шоттары бойынша аударымдар"])},
      "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Криптоваучер жасау"])},
      "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваучерді белсендіру"])},
      "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Басқа"])},
      "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кіріс/шығыс"])},
      "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кіріс"])},
      "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шығындар"])},
      "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["= тең"])},
      "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["≠ тең емес"])},
      "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["> артық"])},
      "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["≥ артық немесе тең"])},
      "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["< кем"])},
      "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["≤ кем немесе тең"])},
      "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
      "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сәтті аяқталды"])},
      "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Өңделуде"])},
      "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қате"])},
      "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бүгін"])},
      "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кеше"])},
      "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Апта"])},
      "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["айы"])},
      "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жыл"])},
      "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Операция тарихы"])},
      "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шотты толықтыру"])},
      "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шот таңдалмаған"])},
      "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["қор биржасында айырбасы"])},
      "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адресті толықтыру"])},
      "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Өз әмияныңыздағы аударымдар"])},
      "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тілші"])},
      "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Операция"])}
    },
    "Home": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сандық паспорт"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Толығырақ"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пайдаланушы ID:"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тіркелген күні:"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Соңғы кіру:"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Менің активтерім"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шоттар"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Барлығын көру"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Толықтыру"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шығару"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шоттар жоқ"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Екінші құпия сөз"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Транзакцияларды растау үшін сізге екінші құпия сөзді пайдалану қажет."])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Үлгілер"])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Барлығы"])},
      "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сома"])},
      "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Танымал төлемдер"])},
      "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жаңалықтар"])},
      "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жабу"])},
      "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Келесі..."])},
      "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активтер жоқ"])},
      "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жасау"])},
      "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жасау"])}
    },
    "InfoReg": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тіркеу мәліметтері"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сақтау"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Расталған"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жалпы қолжетімді"])}
    },
    "Pay": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Үлгілер"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Іздеу"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Топта"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Төлем топтары"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Төлемдер"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Төлем сомасы"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ақша аудару шоты"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сома"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Түсініктеме"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ақша аудару схемасы"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Үлгі атауы:"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сақтау"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Үлгіні сақтау"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аудару"])}
    },
    "Payin": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Толтырылатын шот"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Төлем әдісі"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Үлгі атауы:"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сақтау"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Үлгіні сақтау"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жалғастыру"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Үлгілер"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шотты толықтыру"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аударым деректері"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аударымды тексеру"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аударым туралы ақпарат"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нұсқаулық"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адресті толықтыру"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Толтырылатын адрес"])},
      "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Экспорт"])},
      "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тапсырмалар тізіміне"])},
      "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аударым адресі"])}
    },
    "Return": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Операцияны қайталау"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Операция тарихы"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шоттар тізімі"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Белгісіз қате"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Токендер тізімі"])}
    },
    "Transfer": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ақша шот:"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сома:"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Түсініктеме:"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мәміле қорғауы"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код:"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Генерациялау"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қорғау мерзімі:"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["күндер"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Үлгі атауы:"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сақтау"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Үлгі ретінде сақтау"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аудару"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Үлгілер"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Басқа пайдаланушыға аудару"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қорғау коды - бұл мәмілені аяқтау үшін алушы енгізуі қажет код. Қорғалған аударымды алушы \"Операциялар тарихы\" бөлімінде бірден көреді, бірақ қорғаныс кодын транзакция картасына енгізгеннен кейін ғана ақшаны пайдалана алады. Төлем мерзімі аяқталған жағдайда қаражат жіберушіге қайтарылады."])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аударым адресі"])}
    },
    "TransferSelf": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қайдан"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қайда"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сома"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аудару"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Өз шоттары бойынша аударым"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Әмиян ішіндегі аударымдар"])}
    },
    "Unexpected": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бірдеңе дұрыс емес болды"])}
    },
    "Keyts": {
      "List": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шот"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қалдық"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қосымша ақпарат"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Әрекеттер"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Белсенді"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кіру"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шығу"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жаңа адрес жасау"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жаңа шот жасау"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Таңдалғандарды жою"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Менің активтерім"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шоттар тізімі"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шот атауы"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шоттарды шынымен жойғыңыз келе ме?"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шотты шынымен жойғыңыз келе ме"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активтер табылмады"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шоттар табылмады"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес атауын өзгерту"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрестерді шынымен жойғыңыз келе ме?"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес шынымен жойғыңыз келе ме"])}
      },
      "New": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес түрі"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шот түрі"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Актив:"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Атауы:"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес жасау"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шот жасау"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жаңа адрес жасау"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жаңа шот жасау"])}
      }
    },
    "Safety": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қауіпсіздік"])},
      "Antiphishing": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Антифишингті баптау кабинетпен жұмыс істеу кезінде қосымша қорғаныс механизмі болып табылады."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сіз енгізген сәлемдесу фразасы кабинетінің кез келген бетінің жоғарғы жағында көрсетіледі."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дұрыс фраза сізге дұрыс сайтта екеніңізді көрсетеді, қате фраза - бұл сигнал: ешқандай операция жасамай, жүйенің қауіпсіздік қызметімен жедел байланысу керек."])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Антифишингті қосу:"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Антифишинг жолы:"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сақтау"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Антифишинг"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Өзгерістер келесі кіруде күшіне енеді"])}
      },
      "IP": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP-адрес бойынша қолжетімділікті баптау сізге (немесе зиянкестің) рұқсат етілген тізімде көрсетілмеген адреспен жүйемен жұмыс істеуге мүмкіндік бермейді."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кабинетке IP-адрес бойынша қолжетімділікті шектеу"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес қосу"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес: "])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қосу"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес блогы: "])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["дейін:"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Өз IP: "])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес тізімі"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["№"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрестен"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адреске"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қолжетімділік"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Әрекеттер"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жою"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес жоқ"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сақтау"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тыйым салу"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рұқсат беру"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP-адрес енгізіңіз"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP-адрес қате енгізілген"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ешбір IP-адрес немесе адрес блогы көрсетілмеген."])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Авторизация үшін ешбір IP-адрес рұқсат етілмеген. Сіз өз шотыңызға кіре алмайсыз."])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP арқылы қолжетімділік"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["бастап:"])}
      },
      "Magic": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сиқырлы сөз - бұл қосымша қауіпсіздік баптауы."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Егер сиқырлы сөз қосылған болса, әрбір авторизация кезінде жүйе сізден сиқырлы сөздің қандай да бір символдарын (олардың реттік нөмірі бойынша) енгізуді сұрайды."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сиқырлы сөз:"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сиқырлы сөзді сұрау:"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кабинетке кіру кезінде:"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мерчант арқылы төлем жасау кезінде:"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Картаны қолдану кезінде:"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сақтау"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сиқырлы сөз"])}
      },
      "Onekey": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бір реттік кілттер жүйенің қауіпсіздігін айтарлықтай арттырады."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бағдарламаға кіру кезінде сізден тек логин мен құпиясөзді ғана емес, сонымен қатар кезекті бір реттік кілтті де енгізу сұралады. Активизация кезекпен жүзеге асырылады. Соңғы кілт жаңа кілттер сериясы жасалғанға дейін әрекет етеді."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бір реттік кілттерді қолдану"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кілттер тізімі"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Генерациялау"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Экспорт"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["№"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кілт"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бір реттік кілттер"])}
      },
      "Password": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ескі құпиясөз:"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жаңа құпиясөз:"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Құпиясөзді растау:"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сақтау"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\"Жаңа құпиясөз\" және \"Құпиясөзді растау\" сәйкес емес."])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Құпиясөзді өзгерту"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4-тен 63 символға дейінгі жол. Латын алфавитінің әріптері, цифрлар және таңбалар рұқсат етілген"])}
      },
      "PayPassword": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ескі құпиясөз:"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жаңа құпиясөз:"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Құпиясөзді растау:"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жаңа құпиясөз:"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Құпиясөзді растау:"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сақтау"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сақтау"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\"Жаңа құпиясөз\" және \"Құпиясөзді растау\" сәйкес емес."])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Құпиясөзде кириллица болмауы керек"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Екінші құпия сөзді жасаңыз"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Екінші құпия сөзді өзгертіңіз"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Басты бетке"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4-тен 65 символға дейінгі жол. Латын алфавитінің әріптері, цифрлар және таңбалар рұқсат етілген"])}
      },
      "SecretQuestion": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Егер сіз кабинетке кіру құпиясөзін ұмытсаңыз, оны құпия сұраққа жауап беру арқылы қалпына келтіре аласыз."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Осы бөлімде сіз құпия сұрақ/жауапты өзгерте аласыз."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Құпия сұрақ"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сіздің сұрағыңыз:"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жауап:"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сақтау"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Құпия сұрақ"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Өз сұрағы"])}
      }
    },
    "Stock": {
      "CurrTransfer": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Беремін"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["қалдық "])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аламын"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сауда жұбы:"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шоттар:"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Айырбастау түрі:"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нарық бойынша аударым - тапсырысты орындау сәтіндегі ең тиімді курс бойынша айырбастау жүзеге асырылады. Назар аударыңыз, қабылданатын сома айырбастау сәтіндегі тапсырыстарға байланысты өзгеруі және есептелген сомадан ерекшеленуі мүмкін."])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Белгіленген бағамен аударым сіз көрсеткен сома және курс бойынша жүзеге асырылады. Тапсырыс беріледі. Егер көрсетілген курс нарықтық курстан айтарлықтай ерекшеленсе, тапсырысты орындау көп уақытты алуы мүмкін."])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Курс (болжам):"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Курс"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Курс анықталмаған, "])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["курсты өз бетіңізше орнату ұсынылады"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сома:"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Барлығын қолдану"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комиссия:"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комиссия:"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жалпы:"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нарық бойынша"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(ең жақсы ұсыныс бойынша жылдам айырбастау)"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Белгіленген курс "])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(белгіленген курс бойынша тапсырыс беру)"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тиісті шоттар жоқ"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Курс"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24 сағаттағы өзгеріс"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24 сағаттағы тербелістер"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24 сағаттағы сауда көлемі"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сома нөлге тең болмауы керек!"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Айырбастау"])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сводка деректерін көру үшін, айырбас жұбын таңдаңыз"])},
        "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бірдей активтерді айырбастауға болмайды"])},
        "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шектеулер"])}
      },
      "StockMarket": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Биржа"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нарық. Жиынтық"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ұқсас ұсыныстар"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Керісінше ұсыныстар"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Курс"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Берілді"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Алынды"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Менің тапсырыстарым"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Соңғы мәмілелер"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Курс"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Берілді"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Күні мен уақыты"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Күні"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Беремін"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аламын"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Курс"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Операциялар"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["комиссия"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бас тарту"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қайта қайталау"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ұсыныстар жоқ"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мәмілелер жоқ"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тапсырыстар жоқ"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Толық орындалды"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Өңдеу басталған жоқ"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Өңдеу басталды"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ішінара орындалды"])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Толық бас тартылды"])},
        "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ішінара орындалды және бас тартылды"])},
        "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Алынды"])},
        "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Барлық тапсырыстар"])}
      },
      "OrderList": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Барлық тапсырыстар"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қалпына келтіру"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сүзгілер"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жаңарту"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Күні"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Беремін"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аламын"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Курс"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Операциялар"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["комиссия"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бас тарту"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қайта қайталау"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тапсырыстар жоқ"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Берілетін соманың минимумы"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Берілетін соманың максимумы"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Алатын соманың минимумы"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Алатын соманың максимумы"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Транзакция жасауға әрекеттер саны"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Белсенділік"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Берілетін валюта"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Алатын валюта"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шотты шығару"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шотты енгізу"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Субдилерлер тізімі"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шот таңдалмаған"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кезеңі"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кезеңі"])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бүгін"])},
        "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кеше"])},
        "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Апта"])},
        "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["айы"])},
        "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жыл"])},
        "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Күн түрі таңдалмаған"])},
        "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жасау"])},
        "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аяқтау"])},
        "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Финиш"])},
        "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қандай бөлігі бекітілген, ал қайсысы өзгермелі"])},
        "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Берілетін сома бекітілген"])},
        "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Алатын сома бекітілген"])},
        "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тапсырыс түрі"])},
        "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пайдаланушы ең жақсы кері ұсыныстар бойынша жылдам айырбастауды қалайды"])},
        "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қатаң белгіленген курс бойынша айырбастау"])},
        "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
        "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Өңдеу басталған жоқ"])},
        "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Өңдеу басталды"])},
        "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ішінара орындалды"])},
        "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Толық орындалды"])},
        "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Толық бас тартылды"])},
        "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ішінара орындалды және бас тартылды"])},
        "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Берілетін валюта"])},
        "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Берілетін актив"])},
        "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Алынатын актив"])},
        "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дерек түрі"])}
      },
      "Offers": {
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Соманы беру"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сома"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Соманы алу"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сүзгілер"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жаңарту"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["мәмілелер"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мәміле"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ұсыныстар жоқ"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["P2P ұсыныстар"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сатушының ID"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ұсыныс коды"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Актив"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сипаттама"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сатушы расталған"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тек расталған пайдаланушылар үшін"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Өзіңізбен мәміле жасау мүмкін емес"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активті таңдау"])}
      }
    },
    "VA": {
      "ListVa": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["№  "])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Логотип"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Атауы"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ТИКЕР"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Санат"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Миссия"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Әрекеттер"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Крипто активтерінің тізімі"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активтер жоқ"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Токен шығару"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Акцепт"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нарық"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дәлдік"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бастапқы"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Екінші"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Басқа активпен сәйкестендірілген"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Криптографиялық актив туралы толық ақпарат"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Криптографиялық актив туралы деректерді өңдеу"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қосымша мәселе"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Криптографиялық активті жою"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қабылдау"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Белсенділік"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жоқ"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Токендерді криптоматтан сатып алуға болады"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Токендерді криптоматта сатуға болады"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Санат"])},
        "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Токендерді көрсету"])},
        "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Белгілі бір пайдаланушы үшін"])},
        "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пайдаланушының UID"])}
      },
      "NewVa": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Атауы:"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Атауы - бұл крипто-активтің толық атауы, оны барлығы көреді: эмитенттің өзі, сатып алушылар, сатушылар, өңдеу және т.б. 40 символдан аспайтын жол"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ТИКЕР"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тикер - бұл крипто-активтің қысқаша атауы, 3-тен 10 символға дейінгі қысқартылған аббревиатура"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Санат:"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Криптографиялық активіңізге ең қолайлы санатты таңдаңыз"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қолдау көрсетілетін пішімдері: png, jpg, jpeg, svg. \nФайл өлшемі 1 МБ аспайды, 500*500 пиксель"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бұл крипто-активті неліктен эмитент шығарғанын сипаттаңыз, ол қай салада жұмыс істейді немесе жұмыс істейді, оның миссиясы қандай"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Логотипі:"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мақсат / миссия / тағайындалу:"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дәлдік:"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Эмиссия:"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бірліктің құны:"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сома үшін ондық таңбалардың саны. Мысалы, АҚШ доллары немесе Еуро сияқты көптеген фиат валюталарының дәлдігі 2."])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шығарылған крипто-актив бірліктерінің саны. Эмиссия сомасы крипто-актив жасау операциясы аяқталғаннан кейін сіздің шотыңызда бірден қолжетімді болады."])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бір бірліктің белгіленген құны:"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қамтамасыз ету:"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шот:"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Міндеттемелерді орындайтын тұлға:"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Криптографиялық активті шығару туралы шешім:"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Блокчейнге операцияларды экспорттау:"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Блокчейн түрі:"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Криптоактивпен операцияларды қосу:"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шектеулер/Санкциялар:"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жоғарыда аталған тізімдегі қай елдердің азаматтарына немесе салық резиденттеріне крипто-активті айналымда пайдалануға тыйым салынғанын көрсетіңіз:"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Басқа шектеулер мен шарттар:"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Хостинг мекенжайы:"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ақ кітаптың мекенжайы:"])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Электрондық пошта:"])},
        "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telegram:"])},
        "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twitter:"])},
        "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facebook:"])},
        "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Басқа байланыстар:"])},
        "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Алдыңғыға"])},
        "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сақтау"])},
        "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Келесі"])},
        "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Криптографиялық активті жасау"])},
        "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Атауы мен мақсаты"])},
        "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қаржылық сипаттамалар"])},
        "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Эмитент"])},
        "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Блокчейнге экспорт"])},
        "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шектеулер мен шектеулер"])},
        "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Байланыстар"])},
        "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Анықталмаған"])},
        "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фиаттық валюталар мен басқа активтерге бекітілген бекіту"])},
        "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Эмитент (крипто-активті шығаратын тұлға)"])},
        "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оферташы (крипто-активті таратушы тұлға"])},
        "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жіберуші"])},
        "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Алушы"])},
        "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мен (демеушілікті қосу)"])},
        "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Криптоактивті орналастыру"])},
        "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Крипто активі блокчейн технологиясын пайдалана отырып электронды түрде тасымалданатын және сақталуы мүмкін құнның немесе құқықтың сандық көрінісін білдіреді. Крипто активтерге фиат валюталары, бағалы қағаздар және басқа да қаржы құралдары мен жеке мемлекеттік реттеуге жататын активтер ЕМЕС."])},
        "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Криптографиялық активті өңдеу"])},
        "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Әкімшілік"])},
        "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қабылдау"])},
        "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қабылдаудан бас тарту себебі"])},
        "57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ұсыныс берушінің шоты"])},
        "58": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Міндетті валютадағы ұсыныс берушінің шоты"])},
        "59": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ұсынушы – бастапқы нарықта сатып алу/сату мәмілелерінде актив эмитентінің өкілі. \nЕсептік жазба пассивті. \nАктив шығару шоты болуы мүмкін"])},
        "60": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Есептік жазба пассивті, валютамен байланысты. \nАлдыңғы тіркелгі сияқты бір пайдаланушыға тиесілі болуы керек"])},
        "61": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пайдаланушыларға банкоматтардан актив сатып алуға рұқсат беріңіз"])},
        "63": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сатудан кейінгі сатып алу нысаны"])},
        "64": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Екінші нарықтық операциялар шлюздер арқылы жүзеге асырылады"])},
        "65": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кейінгі сату формасы"])},
        "66": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Екінші нарықтық операциялар шлюздер арқылы жүзеге асырылады"])},
        "67": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Биржадағы активпен транзакцияларға рұқсат беріңіз"])},
        "68": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["P2P арқылы актив транзакцияларына рұқсат беріңіз"])},
        "69": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Егер сіз жеке кәсіпкер немесе заңды тұлғаның өкілі болсаңыз, цифрлық активті шығару туралы шешім файлын PDF форматында жүктеп алуыңыз қажет. \nШешім виртуалды активтің барлық негізгі сипаттамаларын қамтуы және жеке кәсіпкердің немесе осы заңды тұлғаның электрондық қолтаңбасымен қол қойылуы тиіс. \nЦифрлық активті шығару туралы шешімнің мысалын біздің веб-сайт беттерінде \\\"Құжаттар\\\" бөлімінде табуға болады."])},
        "70": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Виртуалды активті орналастыру"])},
        "71": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Есептік жазбаны ауыстырыңыз"])},
        "72": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Эмитент шоттарынан таңдаңыз"])},
        "73": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Есептен шығаруға жататын сома:"])},
        "74": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Есептік жазба жоқ"])},
        "75": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аккаунты құру"])}
      },
      "CodeVa": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Виртуалды активтер"])}
      }
    },
    "Notify": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Хабарландыруларды баптау"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қосу"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Операциялар туралы хабарландыру"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Авторизация (жеке кабинетке кіру, мобильді қосымша және т.б.)"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\"Картаны пайдалана отырып қаржылық операцияларды орындау\" оқиғасы кезінде хабарландыру"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шығындар (кез келген төлемдер, аударымдар, қызметтерді төлеу және т.б.)"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ақша қаражаттарының түсуі"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ішкі пошта арқылы хат алу"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Төлеуге шот түсуі"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қауіпсіздік баптауларын өзгерту"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Операцияларды хабарламадан алынған кодпен растау"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Авторизацияны хабарламадан алынған кодпен растау"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\"Картаны пайдалана отырып қаржылық операцияларды орындау\" кезіндегі растау"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қауіпсіздік баптауларын хабарламадан алынған кодпен өзгерту"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шығындарды хабарламадан алынған кодпен растау"])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Өзгерістер сақталмады, сақтау керек пе?"])},
      "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сақтау"])},
      "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сақтамау"])},
      "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сақтау"])}
    },
    "Masspayment": {
      "List": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Түскен күні"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Өңдеу күні"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Төлемдер саны"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["соның ішінде сәтті"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["соның ішінде қате"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жаппай төлемдер"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Транзакция"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нәтиже"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қызмет көрсетуші"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сома"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комиссия"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Түсініктеме"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1-параметр"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Төлемдер табылмады"])}
      },
      "New": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бір уақытта бірнеше төлем жасай аласыз. Ол үшін төлемдер сипаттамасы бар файлды алдын ала жасау керек \"XLS\", \"XLSX\", \"CSV\" немесе \"XML\" форматында."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Файлды талдау"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Корреспондент"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сома"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Түсініктеме"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Параметр"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Файл туралы ақпарат"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Барлық төлемдер: "])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["жалпы сомасы: "])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Төлем файлы"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шотты шығару"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Төлемдерді жүргізу"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жалғастыру"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Төлем файлын жүктеу"])},
        "57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жаппай төлемдер мен аударымдар"])}
      },
      "Info": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жаппай төлемдер қызметі басқа пайдаланушыларға жылдам ақша аударуға және қызмет көрсетушілерге төлем операцияларын орындауға мүмкіндік береді."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бөлімде"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Төлем файлын жүктеу"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excel форматындағы (XLS, XLSX, CSV) немесе XML файлды жүктей аласыз."])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тапсырмалар тізімі"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["бұрын жасалған жаппай төлем сұраулардың статустарын көруге мүмкіндік береді. "])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Файлды Excel кестелік редакторында жасауға болады. "])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Файл үлгісін жүктеу"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Маңызды ескертулер:"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Файл форматы - CSV, XLS немесе XLSX."])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Файл тақырыпсыз болуы керек."])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ұзын сандар экспоненталық форматқа (мысалы, \"5,46546E+15\") айналмауы және жетекші нөлдер кесілмеуі үшін, санның алдына бір тырнақша (') таңбасын қоюға болады немесе деректерді енгізу алдында ұяшыққа мәтіндік форматты орнатуға болады."])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қызмет көрсетушілерге төлемдер үшін баған форматтары"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Файлдағы бағандар тізімі:"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оператор коды"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Мұнда көрсетілген операторлар тізімінен көрсетіледі"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["мұнда"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". (Барлық ұялы байланыс операторлары үшін оператор коды ретінде 7000 көрсетуге болады - бұл жағдайда оператор (МТС, Билайн, Теле2 және т.б.) телефон нөмірі бойынша автоматты түрде анықталады.)"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сома"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Сома аударылуы тиіс активте көрсетіледі. Егер контрагентте мұндай актив болмаса, транзакцияға жүйені түрлендіру коэффициенті автоматты түрде қолданылады."])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Төлемге түсініктеме"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Міндетті емес, бос қалдыруға болады; ұзындығы 255 символдан аспайды."])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1-параметр"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Оператор талап ететін параметрдің мәні енгізіледі, мысалы, телефон нөмірі. Көптеген операторлар бір төлем параметрімен шектеледі (ұялы байланыс операторлары, интернет, коммерциялық ТВ және т.б.), бірақ егер параметрлер саны біреуден көп болса, олардың барлығын бірінші бағаннан кейінгі бағандарға енгізу қажет."])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2-параметр"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N-параметр"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оператордың төлем параметрлерін көруге болады, бір операторға төлем жасау арқылы:"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пайдаланушыларға жаппай аударымдар үшін баған форматтары"])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оператор коды"])},
        "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Мұнда 0 көрсетуге немесе мүлде көрсетпеуге болады."])},
        "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сома"])},
        "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Сома ол аударылған активте көрсетіледі. Егер контрагентте мұндай актив болмаса, жүйенің конверсия коэффициенті транзакцияға автоматты түрде қолданылады."])},
        "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Төлемге түсініктеме"])},
        "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Міндетті емес, бос қалдыруға болады; ұзындығы 255 символдан аспайды."])},
        "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Алушының шоты"])},
        "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Мұнда пайдаланушының шот нөмірі енгізіледі, ол үшін аударым жасалады (20 сан)."])},
        "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кредит агенті арқылы жаппай несие беру үшін баған форматтары"])},
        "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Бұл жол төлемдердің несие екенін көрсетеді."])},
        "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сома"])},
        "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Сома кредиттік өнімнің валютасында көрсетіледі."])},
        "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Несиеге түсініктеме"])},
        "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Міндетті емес, бос қалдыруға болады; ұзындығы 255 символдан аспайды."])},
        "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қарыз алушының ID"])},
        "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Назар аударыңыз, қарыз алушының Mfo-BorrowPerson сандық паспорты болуы керек."])},
        "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кредиттік өнімнің коды"])},
        "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Мұны несие беру өнімдерінің тізімінде көруге болады."])},
        "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мерзім"])},
        "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Тек мерзімдердің саны (нақты қандай мерзімдер қолданылатыны кредиттік өнімде көрсетілген, мысалы, бұл күндер, апта, айлар және т.б. болуы мүмкін)"])},
        "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оператор коды (форма нөмірі)"])},
        "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Бұл және келесі өрістерді толтыру міндетті емес, егер олар толтырылмаса, қарыз алушы кейінірек өз жеке кабинетінде несиені алу транзакциясын өз бетінше жүзеге асыра алады."])},
        "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қате болған жағдайда бас тарту"])},
        "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Қате болған жағдайда (1) берілген несиені бас тарту немесе кейінгі өз бетінше алу үшін қалдыру (0)."])},
        "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1-параметр"])},
        "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Оператор талап ететін параметрдің мәні енгізіледі, мысалы, телефон нөмірі. Көптеген операторлар бір төлем параметрімен шектеледі (ұялы байланыс операторлары, интернет, коммерциялық ТВ және т.б.), бірақ егер параметрлер саны біреуден көп болса, олардың барлығын бірінші бағаннан кейінгі бағандарға енгізу қажет."])},
        "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2-параметр"])},
        "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N-параметр"])}
      }
    },
    "Support": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус "])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тақырып"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Соңғы хабарлама"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Санат"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Әрекеттер"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сұраулар жоқ"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Санат"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Басымдық"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тақырып"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Хабарлама мәтіні"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Файлдар"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бір немесе бірнеше файлды жүктей аласыз. Файлдың максималды өлшемі - 10 Мб."])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жіберу"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Төмен"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қалыпты"])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жоғары"])},
      "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Техникалық қолдау"])},
      "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Белсенді сұраулар"])},
      "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мұрағат"])},
      "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жаңа сұрау"])},
      "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оператор өңдеп жатыр"])},
      "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сұрау жасалды"])},
      "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жұмыс жүргізілуде, оператордың жауабын күтіңіз"])},
      "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сұрау жабылды"])},
      "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сұрау жабылды"])},
      "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жаңа хабарламалар жоқ"])},
      "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оператордан жаңа хабарлама"])},
      "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жаңа хабарламалар жоқ"])},
      "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мұрағатқа жіберу"])},
      "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Барлық сұраулар"])}
    },
    "Templates": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Үлгілер"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Толықтыру"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аудару"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шығару"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Үлгі атауы"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оператор"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Өрістер"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Операциялар"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Үлгілер жоқ"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Алушы"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["№ үлгіні шынымен жойғыңыз келе ме"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Үлгіні қайта атау "])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қайта атау"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жою"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пайдалану"])}
    },
    "CorrInfo": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қарсы тарап туралы ақпарат"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID / Шот / E-mail / Телефон"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID / Крипто-адрес / E-mail / Телефон"])}
    },
    "Merchant": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жалпы мәліметтер"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Төлем туралы мәліметтер"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бақылау қолтаңбасы"])},
      "List": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Менің дүкендерім"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дүкендер табылмады"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Виджет кодын жасау"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Төлем сілтемесін жасау"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Өңдеу"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дүкенді жою"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дүкенді шынымен жойғыңыз келе ме"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дүкенді тіркеу"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Атауы"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Төлем режимі"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Белсенділік"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қабылдау"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Әрекеттер"])}
      },
      "New": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дүкенді тіркеу"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жүйеде тіркелген электрондық дүкен клиенттерден төлемдер қабылдауға мүмкіндік береді."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дүкен ретінде электрондық дүкендер, айырбастау пунктері және өз мекенжайларына төлемдер қабылдағысы келетін кез келген интернет-ресурстар тіркеледі."])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Атауы немесе сауда белгісі"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дүкеннің URL-адресі"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дүкеннің қысқаша сипаттамасы"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қызмет түрі"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дүкен белсенді"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Құпия кілт"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автовыплатуға рұқсат беру"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нәтижелерді скриптке жіберу"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скрипт мекенжайы"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сәтті төлемнен кейін қайтару"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сәтсіз төлемнен кейін"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сақтау"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Генерациялау"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кемінде 30 таңба."])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бұл төлем жүйесі (пайдаланушыны мерчантқа жіберу кезінде) және дүкен (төлем нәтижелерін алу кезінде) берілетін деректердің тұтастығы мен дұрыстығын тексеру үшін қолданылады"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Келесі"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Артқа"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дүкендер тізіміне"])}
      },
      "Invoices": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Инвойстар тізімі"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Инвойстар табылмады"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Күні"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Түсініктеме"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сома"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дүкен"])}
      }
    },
    "P2P": {
      "New": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ішкі актив"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шот"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сыртқы актив"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Топ"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Атауы"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Атауы RU"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Синонимдер"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Елдер"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Егер толтырылса, хабарландыру көрсетілген елдердің пайдаланушыларына ғана қолжетімді болады"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активке сәйкестендіру"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Өлшем бірліктері"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қысқаша"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дәлдік"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["қосымша ақпарат"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Деректемелер"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аударым бағыты"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Минимум"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Максимум"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шектеу"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Күту уақыты (мин)"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жарнама белсенді"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тек сілтеме бойынша қолжетімді"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тек расталған пайдаланушыларға қолжетімді"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Алдыңғыға"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Орналастыру"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Келесі"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бағыт"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шектеулер"])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Хабарландыру жасау"])},
        "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Өзінің сыртқы активі"])},
        "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Байламау"])},
        "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Иә"])},
        "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Курсты қысқарту"])},
        "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кері қайтару"])},
        "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мұндай шот жоқ"])},
        "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шот жасау"])},
        "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Курсты көрсетіңіз"])},
        "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Курс дұрыс"])},
        "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Курсты қысқарту"])},
        "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кері қайтару"])},
        "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Минимум максимумнан үлкен"])},
        "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аударымды қайда және қалай алғыңыз келеді. Мысалы: Банктің қосымшасында +499990001111 телефон нөмірі бойынша аудару (алушы Александр Н.)"])},
        "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Беремін"])},
        "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қабылдау үшін"])},
        "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активтер"])},
        "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сұралатын реквизиттер"])},
        "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аударымды жасау үшін тейкер қандай реквизиттерді жіберуі керек"])},
        "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Актив таңдаңыз"])},
        "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Актив таңдаңыз"])},
        "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не алғыңыз келеді: ішкі актив түрінде қаражат, мысалы, BTC немесе сыртқы актив түрінде, мысалы, TOKEN GOLD?"])},
        "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бұл мәміленің ерекшеліктері туралы түсініктеме жазыңыз, мысалы: \\\"Сыртқы актив тек жеке кездесу кезінде беріледі\\\""])},
        "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сыртқы активке жататын құндылықтар түрі, мысалы, \\\"Қолма-қол ақша\\\""])},
        "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Платформада көрсетілетін активтің атауы"])},
        "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Актив сондай-ақ тізімдегі сөздермен аталуы мүмкін"])},
        "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қолданыстағы активпен сәйкестендіру"])},
        "57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мысалы: литрлер, даналар, сағаттар, қораптар"])},
        "58": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мысалы: л, дана, сағ, қорап"])},
        "59": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Актив мәндерінде үтірден кейін қанша сан болуы мүмкін?"])},
        "60": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сіз әлі жоқ активті жасауды таңдадыңыз, өтінеміз, оның сипаттамаларын толтырыңыз"])},
        "61": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тараптарға шарттарды орындау үшін берілетін уақыт"])},
        "62": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ішкі актив деп \\\"New Reality\\\" жүйесінде бар активті айтады. Сыртқы актив - бұл \\\"New Reality\\\" жүйесінде жоқ актив."])},
        "63": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сәйкестік берілген жоқ"])}
      },
      "SingleSwap": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Беремін"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аламын"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Түсініктеме"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Себебі"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мәміледен бас тарту"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мәмілеге қатысуды растау"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мәміледен бас тарту"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Күту уақытын ұзарту"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мәміледен бас тарту"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мәміледегі пайдаланушының статусы анықталмаған. Бірдеңе дұрыс емес"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Арбитраж сұрау"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Арбитражға келісу"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аударым жасалғаны туралы хабарлау"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аударымды алғанын растау"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мәмілені бағалау"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бағалау жіберу"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мәміле № "])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Корреспондент"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Таймаут"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["бүгін"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ертең"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Арбитражды тоқтату"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Арбитражды тоқтату"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сіз арбитражға жүгіндіңіз"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сізге қарсы арбитраж талап етілді"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Арбитраж жүріп жатыр (Сіз талапкерсіз)"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Арбитраж жүріп жатыр (Сіз жауапкерсіз)"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Таймер аяқталғаннан кейін арбитражға келісім автоматты түрде беріледі және сіз бұдан былай төрелік сұрақтарға жауаптарыңызды қоса алмайсыз."])}
      },
      "Offers": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Беремін"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сома"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аламын"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сүзгілер"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жаңарту"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сатушы"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Беремін"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аламын"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шектеулер"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Әрекеттер"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["мәмілелер"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мәміле"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мәмілелер жоқ"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ұсыныстар"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пайда"])}
      },
      "ActiveSwaps": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мәміле коды"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Беремін"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аламын"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бағыты"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Курс"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Таймаут"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Әрекеттер"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Белсенді мәмілелер жоқ"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Менің мәмілелерім"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Белсенді"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ішкі актив"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Белсенді емес"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Корреспондент"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код"])}
      },
      "Swaps": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Белсенділік"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активтің сипаттамасы"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Күні"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Хабарландырулар жоқ"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Менің хабарландыруларым"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Менің хабарландыруларым"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жаңа хабарландыру"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Белсенділік"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Белсенді"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Белсенді емес"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Барлығы"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бағыты"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сыртқы актив"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ішкі актив"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сипаттама"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Биржада айырбастаймын"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Биржада қабылдаймын"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лимиттер"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жарияланған күні"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Есепшоттағы қалдық"])}
      },
      "RequestP2POffer": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мәміле сұрауы"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ұсыныс"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сатушы"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Таймаут"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тараптардың әрекет ету уақыты"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Курс"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бересіз"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аласыз"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мәміле сұрау"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шот жасау"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шот таңдалмаған"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сома"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сома диапазонға жатпайды:"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["мин"])}
      },
      "CheckCurrP2POffers": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Таңдау"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Берілетін"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Алынатын"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["актив"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["беремін"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ішкі"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["аламын"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ішкі активтер"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активтер табылмады"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сыртқы активтер"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["сыртқы"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Таңдалған:"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ішкі"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сыртқы"])}
      },
      "P2PChat": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["кеше"])}
      },
      "RequestP2PArbitrage": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Арбитражды сұрау"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Болдырмау"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жіберу"])}
      },
      "ExternalCurr": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сыртқы активтер"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Атауы"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активке байланған"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Өлшем бірліктері"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Топ"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Құру күні"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автор"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Әрекеттер"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Период басталған күннен"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Период аяқталған күнге дейін"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фильтрлер"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қалпына келтіру"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жаңарту"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активтер жоқ"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бүгін"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кеше"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Апта"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ай"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жыл"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активке байланған"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Топ"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автор"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Белсенділік"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Елдер"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Белсенді"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Белсенді емес"])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сыртқы криптографиялық активті жою"])}
      }
    },
    "p2p": {
      "Edit": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Хабарландыру"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сипаттама"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Деректемелер"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Минимум"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Максимум"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сома шектеуі"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Күту уақыты (мин)"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Хабарландыру белсенді"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тек сілтеме бойынша қолжетімді"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тек расталған пайдаланушылар үшін қолжетімді"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бас тарту"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сақтау"])}
      },
      "ExternalCurrencies": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сыртқы активті өңдеу"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Атауы"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бас тарту"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сақтау"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Топ"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Синонимдер"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Елдер"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активке байлау"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Өлшем бірліктері"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қысқаша"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дәлдік"])}
      }
    },
    "offers": {
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бастау"])}
    },
    "MultiSelect": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қалпына келтіру"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Барлығын таңдау"])}
    },
    "ATM": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["банкоматтар"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кестені толық экранға кеңейту үшін басыңыз"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қалпына келтіру"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жаңарту"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Белсенділік"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Субдилерлер"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Белсенді"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Белсенді емес"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Банкомат жасаңыз"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Криптоматты өңдеу"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бас тарту"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сақтау"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Негізгі ақпарат"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Негізгі"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жұмыс уақыты"])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Атауы"])},
      "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Логин"])},
      "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Құпия сөз"])},
      "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мекенжай"])},
      "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Орнату орнының сипаттамасы"])},
      "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нормаланған"])},
      "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нормаланбаған"])},
      "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жұмыс режимі"])},
      "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дүйсенбі"])},
      "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сейсенбі"])},
      "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сәрсенбі"])},
      "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бейсенбі"])},
      "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жұма"])},
      "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сенбі"])},
      "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жексенбі"])},
      "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Түскі үзіліс"])},
      "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Құпия сөзді қайталау"])},
      "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бар тіркелгілерді пайдаланыңыз"])},
      "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жаңа тіркелгілерді жасаңыз"])},
      "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Негізгі мәліметтер"])},
      "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Есептік жазба"])},
      "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жұмыс уақыты (анықтамалық ақпарат)"])},
      "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Белсенділік"])},
      "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Әрекеттер"])},
      "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ешқандай крипто машиналары табылмады"])},
      "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["банкомат нөмірі"])},
      "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ендік"])},
      "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["бойлық"])},
      "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Анықтама телефоны"])},
      "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қосалқы дилердің идентификаторы"])},
      "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Есептік жазбалар"])},
      "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тексеру"])},
      "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Валюта"])},
      "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Транзиттік шот"])},
      "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Криптоматтың құрылуы"])},
      "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Криптоматты өңдеу"])},
      "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Құпия сөз"])},
      "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Иә"])},
      "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тыйым салу"])},
      "57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рұқсат етіңіз"])},
      "58": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Коллекционерлердің тізімі"])},
      "59": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Коллекторды қосыңыз"])},
      "60": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Инкассация балансына өту мүмкін емес - деректер жоқ"])},
      "61": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Коллекционерлердің тізімі"])},
      "62": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Коллекторды қосыңыз"])},
      "63": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рұқсат етілген"])},
      "65": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қабылдау"])},
      "67": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мекенжай"])},
      "68": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Банкоматты өңдеу"])},
      "69": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ағымдағы инкассация қалдықтарын қараңыз"])},
      "70": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жүктелмеген инкассация қалдықтарын қараңыз"])},
      "71": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пин коды"])},
      "73": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Егер субдилерді көрсетпесеңіз, банкомат сізге байланыстырылады"])},
      "74": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ендік пен бойлық географиялық координаттар болып табылады, мысалы, банкомат мекенжайын енгізу арқылы Google Maps қолданбасынан көшіруге болады."])},
      "75": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Орнату орны"])},
      "76": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кестені көрсету үшін кемінде бір баған таңдалуы керек"])},
      "77": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сақтау"])},
      "78": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жасау"])},
      "79": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Банкомат құрылғыларына өтіңіз"])}
    },
    "Echeck": {
      "List": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Электрондық чектер"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чек жасаңыз"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Белсенділік"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Құрылды"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Есептен шығару шот"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["сомасы"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сан"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сериялар"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пікір"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Әрекеттер"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Белсенді"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Белсенді емес"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тексерулер жоқ"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Іске қосу"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тексеру"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жабық"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Электрондық түбіртек табылмады"])}
      },
      "New": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Электрондық түбіртек құру"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Есептен шығару шот"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Әрбір чектің номиналы"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пікір"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тексерулер саны"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Артқа"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жасау"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Серия:"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Саны:"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код:"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["чектер құрылды"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Экспорттау"])}
      },
      "Activate": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Электрондық чекті белсендіру"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Депозиттік шот"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сан"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сериялар"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Белсендіру коды"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тексеру"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Іске қосу"])}
      }
    },
    "Error": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қателік орын алды!"])}
    },
    "ATMIncass": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Банкомат жинау"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жинақ қосу"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Әрекеттер"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сүзгілер"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қалпына келтіру"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жаңарту"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мұрағат жинақтарын көрсетпеңіз"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бас тартылған жинақтарды көрсетпеңіз"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Күй"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жинақтар жоқ"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Коллекцияға өтінім жіберілді"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жинақты өңдеу"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жинақ күйін өзгерту"])}
    },
    "ATMIncassItem": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жинақ қосу"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Негізгі ақпарат"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Негіздер"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Диспенсер"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нүкте нөмірі"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бір өтінімдегі карталар саны"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шотты қабылдаушы"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Карталар"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Инкассаторлар"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Коллекционерлерге арналған ескерту"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жинаққа түсініктеме"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жүктеу ретсіз"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вексель қабылдаушы кассетаны ауыстырыңыз"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кассета туралы түсініктеме"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Карточкаларға түсініктеме беру"])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Барлық карталарды қабылдамау картасы үлестіргіш кассетасынан алып тастаңыз"])},
      "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Түтіктен барлық карталарды алып тастаңыз"])},
      "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нүкте нөмірі - толтырылуы тиіс міндетті өріс"])},
      "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кассета идентификаторы 1"])},
      "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кассета идентификаторы 2"])},
      "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кассета идентификаторы 3"])},
      "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кассета идентификаторы 4"])},
      "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кассета рейтингі 1"])},
      "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кассетадағы шоттардың саны 1"])},
      "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кассетадағы сома 1"])},
      "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кассета рейтингі 2"])},
      "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кассетадағы шоттардың саны 2"])},
      "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1-таспаға түсініктеме"])},
      "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кассета идентификаторы"])},
      "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кассета рейтингі"])},
      "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шоттардың саны"])},
      "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["сомасы"])},
      "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кассета туралы түсініктеме"])},
      "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сақтау"])},
      "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жинақты өңдеу"])}
    },
    "ATMIncassBalance": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кассета нөмірі"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кассета идентификаторы"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кассета жинағының нөмірі"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кассета рейтингі"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жүктеп салынды"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["сомасы"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сервер"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Саны"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["сомасы"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["банкомат"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["сомасы"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шын мәнінде"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Саны"])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["сомасы"])},
      "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кассета туралы түсініктеме"])},
      "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Көрсетілетін деректер жоқ"])},
      "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Құрылғы жинағы"])},
      "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["жарамды"])},
      "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["в"])},
      "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Авторы"])},
      "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["қалдықтары тасымалданады"])},
      "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сервер"])},
      "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шоттардың саны"])},
      "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["сомасы"])},
      "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["банкомат"])},
      "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шоттардың саны"])},
      "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["сомасы"])},
      "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пікір"])},
      "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Диспенсер"])},
      "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қабылдамау/қайтару"])},
      "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вексельді қабылдаушы"])},
      "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Карталардан бас тарту"])},
      "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Карталар"])},
      "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ток"])},
      "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["түсірілген"])},
      "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Банкоматтардың инкассациясының қалдықтары"])},
      "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Құрылғы жинағы"])},
      "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["жарамды"])},
      "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["банкоматқа орнатылған кассета"])},
      "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ұсталды"])},
      "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["қалдықтары тасымалданады"])},
      "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["қалдықтары тасымалданады"])},
      "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["бос кассета:"])},
      "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["адгезия:"])},
      "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қосымша ақпарат:"])},
      "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["бүгінгі күнге дейін"])}
    },
    "ATMIncassStatuses": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Күйді таңдаңыз"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Инкассоға өтінім берілді"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Коллекция қолданбасы тоқтатылды"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жинақ орындауға мақұлданды"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Инкассацияны кассир жүзеге асырады (ақша кассетаға салынады)"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Инкассаторларға кассеталар берілді (жолдағы ақша)"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қолма-қол ақшаны инкассациялау банкоматта орнатылған"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сәтсіздік нәтижесінде басқа жинақпен шамадан тыс жүктелді"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Коллекция банкоматтағы басқа коллекциямен ауыстырылды (жолда қалады)"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Коллекция қалдықтары орналастырылады (жинақ мұрағатқа жіберіледі)"])}
    },
    "CollectorsList": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сан"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аты"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Әрекеттер"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Коллектор туралы ақпаратты өңдеу"])}
    },
    "InfoCollector": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["саны"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аты"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Болдырмау"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сақтау"])}
    },
    "ChoiseKeyt": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ұсыныс берушінің шотын таңдау"])}
    },
    "ATMauth": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пішін өрістері №."])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Міндетті идентификатор жоқ"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сіз жүйеге сәтті кірдіңіз, банкоматта операцияларды жалғастырыңыз"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Банкомат операцияларын жалғастыру"])}
    },
    "ChoiseColumns": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Көрсетілетін бағандарды таңдаңыз"])}
    },
    "FreeReports": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Валюта"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Есеп құру"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Үлгі"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Парақ"])}
    },
    "ATMDevices": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["банкомат құрылғылары"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Құрылғыны қосыңыз"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Құрылғы түрі"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аты"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Түсініктеме"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Конфигурация"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кассета қосыңыз"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кассета түрі"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аты"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Түсініктеме"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Конфигурация"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Құрылғыны алып тастаңыз"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кассетаны алыңыз"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Құрылғыны жою керек пе?"])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жою"])},
      "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кассетаны жою керек пе?"])}
    },
    "ListRates": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Валюта жұптары"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Валюта жұбын жасаңыз"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Валюта жұбын өңдеу"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Валюта жұбын жою"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Әрекеттер"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шлюз"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жаңарту түрі"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ешбір валюта жұбы табылмады"])}
    },
    "RateItem": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Валюта жұбын жасаңыз"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жаңартуды орнату"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Валюта алынды"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Курсқа пайда қосу, %"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Курсты жаңарту түрі"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Курстың ұзақтығы, секунд"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Курсты жаңарту аралығы, секунд"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жақсы"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Курс қай күнге жарамды?"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Берілген валютаның бүркеншік аты"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Алынған валютаның бүркеншік аты"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шлюз"])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Валютаны таңдау керек, сонымен қатар біреуі ғана: берілген немесе алынған"])}
    },
    "SupportNew": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қолдау сұрауын жасаңыз"])}
    }
  },
  "components": {
    "service": {
      "Registration": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тіркеу"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жеке тұлға"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заңды тұлға"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тегі:"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аты:"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Әкесінің аты:"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Атауы"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қазақ әріптері, сонымен қатар дефис және апостроф рұқсат етіледі."])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Иванов"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қазақ әріптері, сонымен қатар дефис, бос орын және апостроф рұқсат етіледі."])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Иван"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Иванович"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қазақ және латын әріптері, сонымен қатар дефис, тырнақшалар, бос орындар, цифрлар және апостроф рұқсат етіледі."])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ЖШС \"Рога и копыта\""])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Телефон"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Құпиясөз"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Латын әріптері (үлкен және кіші), цифрлар, символдар _ - . ! $ % ^ * = рұқсат етіледі"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Құпиясөзді қайталаңыз"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Құпиясөздер сәйкес емес"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Артқа"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тіркеу"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Келесі"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пайдаланушы деректері"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Құпиясөз"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дұрыс электрондық пошта немесе телефон нөмірін көрсетіңіз. Телефон нөмірі халықаралық форматта көрсетіледі, мысалы: +79001234567."])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сіз сәтті тіркелдіңіз"])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Енді сіз "])},
        "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["жеке кабинетке кіре аласыз"])},
        "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сіздің аккаунтыңызды біздің қызметкер тексеретін болады, оны активизациялау нәтижелері туралы кейінірек хабарлаймыз"])},
        "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сізге тіркеуді растау сілтемесі бар хат жіберілді"])},
        "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сіздің электрондық поштаңызға"])},
        "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бірінші кіруден кейін телефон нөміріңізді растау ұсынылады"])}
      },
      "Members": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Алушы: "])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пайдаланушы табылмады"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Байланыстар"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Толығырақ"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пайдаланушыларды таңдау"])}
      },
      "modal": {
        "Answer": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сәтті"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қате"])}
        },
        "Confirm": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Растау"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бас тарту"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жалғастыру"])}
        },
        "History": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["транзакция "])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жалпы параметрлер"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шоттардағы қалдықтарды өзгерту"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Операцияның соңында:"])},
          "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Операцияның басында:"])},
          "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қосымша параметрлер"])},
          "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Блокчейн операциялары"])},
          "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Callback"])},
          "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Келесі әрекет:"])},
          "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уақыт"])},
          "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жауап"])},
          "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мәміле қорғауы"])},
          "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қорғау мерзімі:"])},
          "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["дейін"])},
          "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қорғау коды:"])},
          "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Растау"])},
          "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Үлгі параметрлері"])},
          "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Үлгі атауы :"])},
          "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сақтау"])},
          "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Операциядан бас тарту"])},
          "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Операцияны қайталау"])},
          "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Үлгіні сақтау"])},
          "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жабу"])},
          "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Транзакция нөмірі"])},
          "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Операция"])},
          "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Корреспондент"])},
          "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Төлем күні"])},
          "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сома"])},
          "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комиссия"])},
          "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Түсініктеме"])},
          "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нәтиже"])},
          "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Төлем формасының нөмірі"])},
          "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кіріс/Шығыс"])},
          "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес қалдықтарын өзгерту"])},
          "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қабылдауды растау"])},
          "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қаражатты жіберушіге қайтару"])},
          "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Күдікті операция"])},
          "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Әрекеттер нұсқалары:"])}
        },
        "Rename": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Атауы: "])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бас тарту"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жалғастыру"])}
        },
        "MembersModal": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пайдаланушы табылмады"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Таңдау"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жабу"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Іздеу"])}
        },
        "PayPass": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Құпиясөз:"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бас тарту"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жалғастыру"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Екінші құпия сөзіңізді енгізіңіз"])}
        },
        "PayStatus": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Операцияны қайталау"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Операция тарихы"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шоттар тізімі"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сәтті"])},
          "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қате"])},
          "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Белгісіз қате"])},
          "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Үлгілер"])},
          "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Менің активтерім"])},
          "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жаңа актив туралы ақпарат"])},
          "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қосымша мәселе"])},
          "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Блокчейнге сілтеме"])}
        },
        "Repeat": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кіру:"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жалғастыру"])}
        },
        "Address": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес таңдау"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тұрғылықты жері"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бас тарту"])},
          "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сақтау"])},
          "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ел"])},
          "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Өңір"])},
          "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Округ"])},
          "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аудан"])},
          "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қала"])},
          "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қала ішіндегі аумақ"])},
          "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тұрғылықты жері"])},
          "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Көше"])},
          "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес элементі"])},
          "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Түсініктеме"])},
          "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Үй"])},
          "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пошталық индекс"])},
          "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мекенжай"])}
        },
        "SupportList": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Техникалық қолдау сұрауы"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["бастап"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чат"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Хабарлама қосу"])},
          "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Файлдар"])},
          "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бір немесе бірнеше файлды жүктей аласыз. Файлдың максималды өлшемі - 10 Мб."])},
          "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Хабарлама мәтіні"])},
          "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жіберу"])}
        },
        "Template": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Атауы"])}
        },
        "WidgetData": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Виджетті жасау"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бас тарту"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шот"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Генерациялау"])},
          "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Төлем"])},
          "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Төлем сомасы"])},
          "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сәтті болған кезде әрекеттер"])},
          "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сәтсіз болған кезде әрекеттер"])}
        },
        "PayHref": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Төлем коды/сілтемесін жасау"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дүкен операциясының нөмірі"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сома"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Түсініктеме"])},
          "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қосымша параметр"])},
          "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сәтті URL"])},
          "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қате URL"])},
          "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Хабарландыру URL"])},
          "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Клиент сәтті төлемнен кейін оралатын URL"])},
          "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Клиент қателік болған кезде оралатын URL"])},
          "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Төлем нәтижелері туралы хабарландыру жіберілетін URL"])},
          "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Соманы бекіту"])},
          "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Көшіру"])},
          "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тексеру"])},
          "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Төлем сілтемесі"])},
          "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Форма"])},
          "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Құжаттама"])},
          "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Төлем шоттың жарамдылық мерзімі, әдепкі бойынша 60 минут"])},
          "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сілтеменің әрекет ету уақыты"])},
          "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сілтеме"])},
          "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Форма"])},
          "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Виджет"])}
        },
        "ReEshop": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дүкенді өңдеу"])}
        },
        "Questionnaire": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сақтау"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Егер сіз мұны көріп тұрсаңыз, форма алынбаған (бірақ сіз мұны көрмеуіңіз керек)"])}
        },
        "Emission": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қосымша эмиссия"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Крипто активі"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Есептік жазба – қамту көзі"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комиссия дебеттік шоты"])},
          "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қосымша мәселе"])},
          "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Түсініктеме"])},
          "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жалғастыру"])},
          "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ағымдағы шығарылым мөлшері"])},
          "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ТЕКЕН"])},
          "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қаптау"])},
          "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қамтуды есептеу"])},
          "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Байланыстыру:"])}
        },
        "Warning": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Болдырмау"])}
        }
      },
      "Comiss": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шегерімге"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комиссия"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ақпарат"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жарнама"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Соманы есептеу мүмкін емес"])}
      },
      "File": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Файл таңдау"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қолдау көрсетілетін форматтар:"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Белгісіз формат"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Файлдың тым үлкен көлемі, максималды өлшемі: "])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жүктеу үшін файлдарды таңдаңыз немесе осында сүйреңіз"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Таңдау"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жүктеу"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бас тарту"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Файлдар жүктелді"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["файлдар"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жою"])}
      },
      "PayStatusTemplate": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сома:"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нәтиже:"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Транзакция:"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статусты жаңарту"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пайдалы сілтемелер"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Блокчейн:"])}
      },
      "SafetyRes": {
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Басқа қауіпсіздік баптаулары"])}
      },
      "modlas": {
        "Repeat": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Растау қажет"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Авторизацияны бір реттік кілтпен растау қажет"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Авторизацияны сиқырлы сөзбен растау қажет"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тіркеуді SMS хабарламасынан алынған кодпен растау қажет"])}
        }
      },
      "GetInputs": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жол"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["дейін"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["символдар"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рұқсат етілмеген мән"])}
      },
      "TableList": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Іздеу"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Көрсетілетін бағандарды таңдау үшін басыңыз"])}
      },
      "topmenu": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crypto ID:"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тіл"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қайырлы таң"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қайырлы күн"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қайырлы кеш"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қайырлы түн"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шығу"])}
      },
      "pagination": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Көрсетілген:"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["барлығы"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бетте"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Көрсету"])}
      }
    },
    "Payin": {
      "Table": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Төлемді растау"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қайтару комиссиясын есептеу"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Төлемді қайтару"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Транзакция"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Блокчейн хеші"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AML тәуекелі"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сома"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комиссия"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Есепке алу"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Түзетілген шарттармен келіссеңіз, мынаны растаңыз:"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Егер бұл шарттар сізге сәйкес келмесе, қайтару шығындарын шегеріп, ақшаны жіберушінің шотына қайтаруға болады:"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Транзакция"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["сомасына"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AML тәуекелі бар"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["сондықтан оны комиссиясы қайта есептелді"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["осылайша, есепке алынатын сома"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жаңадан келгендер табылмады"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Төлеу күні"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Каражатты қайтару"])}
      }
    }
  },
  "iews": {
    "Echeck": {
      "New": {
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Артқа"])}
      }
    }
  }
}