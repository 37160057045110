<template>
    <div>
        <div class='d-flex justify-content-between neue mb-2'>
            <h4>{{ this.content.currency.name ?  this.content.currency.name : `${this.$t('views.p2p.ExternalCurrencies.1')} ${this.content.currency.code}`}}</h4>
            <i class='big item-i x_red pointer' v-on:click='this.close'></i>
        </div>
        <div class="mb-3 neue-roman">
            <div class="col-12">
                <Input
                    :dataValue='this.data.external_name'
                    require
                    :label="$t('views.p2p.ExternalCurrencies.2')"
                    id='external_name'
                    @updateParentState="this.setValue"
                />
            </div>
            <div class="col-12">
                <Select
                    :label="$t('views.p2p.ExternalCurrencies.5')"
                    require
                    :dataValue='this.data.external_grp'
                    id='external_grp'
                    :keywords.sync='this.groups_list'
                    @updateParentState="this.setValue"
                    :hotUpdate='true'
                />
            </div>
            <div class="col-12">
                <Input
                    :dataValue='this.data.external_synonyms'
                    :label="$t('views.p2p.ExternalCurrencies.6')"
                    id='external_synonyms'
                    @updateParentState="this.setValue"
                />
            </div>
            <div class="col-12">
                <SelectMulti
                    :label="$t('views.p2p.ExternalCurrencies.7')"
                    :dataValue='this.data.external_countries'
                    id='external_countries'
                    :keywords.sync='this.countries_list'
                    @updateParentState="this.setValue"
                    :hotUpdate='true'
                    :isSearch="true"
                />
            </div>
            <div class="col-12">
                <Select
                    :label="$t('views.p2p.ExternalCurrencies.8')"
                    :dataValue='this.data.external_type_curr'
                    id='external_type_curr'
                    :keywords.sync='this.currency_internal'
                    @updateParentState="this.setValue"
                    :hotUpdate='true'
                    :isSearch="true"
                />
            </div>
            <div v-if="this.data.external_type_curr == '0'">
                <div class="col-12">
                    <Input
                        :dataValue='this.data.external_unit'
                        :label="$t('views.p2p.ExternalCurrencies.9')"
                        id='external_unit'
                        @updateParentState="this.setValue"
                    />
                </div>
                <div class="col-12">
                    <Input
                        :dataValue='this.data.external_unit_abbreviation'
                        :label="$t('views.p2p.ExternalCurrencies.10')"
                        id='external_unit_abbreviation'
                        @updateParentState="this.setValue"
                    />
                </div>
                <div class="col-12">
                    <Input
                        :dataValue='this.data.external_unit_precision'
                        :label="$t('views.p2p.ExternalCurrencies.11')"
                        id='external_unit_precision'
                        @updateParentState="this.setValue"
                    />
                </div>
            </div>
        </div>
        <div class="col-12 col-md-3 mt-4 m-auto d-flex flex-column flex-md-row align-items-center justify-content-between">
            <Button
                :name="$t('views.p2p.ExternalCurrencies.3')"
                class="col-12 col-sm-6"
                :class="this.$store.state.resize.w > 768 ? 'mr-1' : 'mb-2'"
                type='btn-outline-warning'
                v-on:click="this.close"
            />
            <Button :name="$t('views.p2p.ExternalCurrencies.4')" class="col-12 col-sm-6" type='btn-warning'/>
        </div>
    </div>
</template>

<script>
import Button from '@/components/service/Button';
import Select from '@/components/service/Select';
import Input from '@/components/service/Input';
import SelectMulti from '@/components/service/SelectMulti';

export default {
    components: {
        Button,
        Select,
        Input,
        SelectMulti
    },
    props: {
        close: [Function],
    }, 
    data () {
        return { 
            content: this.$store.state.modal.content,
            data: {
                external_name: this.$store.state.modal.content.currency.name,
                external_grp: this.$store.state.modal.content.currency.grp,
                external_synonyms: this.$store.state.modal.content.currency.synonyms,
                external_countries: this.$store.state.modal.content.currency.country,
                external_type_curr: this.$store.state.modal.content.currency.curr,
                external_unit: this.$store.state.modal.content.currency.unit,
                external_unit_abbreviation: this.$store.state.modal.content.currency.unit_abbreviation,
                external_unit_precision: this.$store.state.modal.content.currency.unit_precision
            },
            groups_list: this.$store.state.modal.content.groupsList,
            countries_list: this.$store.state.modal.content.countriesList,
            currency_internal: this.$store.state.modal.content.currencyInternal,
        }
    },
    mounted() {
        console.log('mounteed', this.currency_internal);
        console.log('mounted this.data.', this.data);
    },
    methods: {
        setValue(arg) {
            this.data[arg.id] = arg.value;
        }
    }
}
</script>